import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/tab/tab.js"
import "@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js"
import "@shoelace-style/shoelace/dist/components/tab-group/tab-group.js"
import css from "../../main.module.css"

export type TabAttrs = {
	panel: string
	active: boolean
	closeable?: boolean
	disabled?: boolean
	slot?: string
} & bacta.Attributes

export type TabPanelAttrs = {
	name: string
	active: boolean
}

export type TabGroupAttrs = {
	placement?: "top" | "bottom" | "start" | "end"
	activation?: "auto" | "manual"
	noScrollControls?: boolean
	class?: string
	"onsl-tab-show"?(event: InputEvent): void
}

export const Tab: bacta.ClosureComponent<TabAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m(
				"sl-tab." + css.tabtight,
				{
					...attrs,
					style: {
						[attrs.width ? "width" : ""]: attrs.width,
					},
				},
				children
			)
		},
	}
}

export const TabPanel: bacta.ClosureComponent<TabPanelAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-tab-panel", attrs, children)
		},
	}
}

export const TabGroup: bacta.ClosureComponent<TabGroupAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-tab-group." + css[attrs.class || ""], attrs, children)
		},
	}
}
