import m, * as bacta from "bacta"
import "leaflet/dist/leaflet.css"
import css from "../../main.module.css"
import * as L from "leaflet"
import { State } from "../../store"
// import * as Store from "jaforbes-s/store.js";
export type Map = ReturnType<typeof L.map>
export type MapAttrs = {
	id: string
	slot?: string
	map?: bacta.Store<State["map"]>
	options?: L.MapOptions
	style?: any
}

// https://icons8.com/icons/set/tools
export const toolsMarker = L.icon({
	iconUrl: "assets/tools-gold.png",
	// shadowUrl: 'dot-shadow.png',

	iconSize: [28, 28], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const carMarker = L.icon({
	iconUrl: "assets/car-gold.png",
	// shadowUrl: 'dot-shadow.png',

	iconSize: [28, 28], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const boxMarker = L.icon({
	iconUrl: "assets/box-orange-filled.png",
	// shadowUrl: 'dot-shadow.png',

	iconSize: [28, 28], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const craneMarker = L.icon({
	iconUrl: "assets/crane-orange-filled.png",
	// shadowUrl: 'dot-shadow.png',

	iconSize: [28, 28], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const LocationMarker = L.icon({
	iconUrl: "assets/dot-orange-location-dot.png",
	// shadowUrl: 'dot-shadow.png',

	iconSize: [28, 28], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const dotMarker = L.icon({
	iconUrl: "assets/dot-orange-no-outline.png",
	// shadowUrl: 'leaf-shadow.png',

	iconSize: [18, 18], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [9, 9], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const outlineddotMarker = L.icon({
	iconUrl: "assets/dot-orange-with-outline.png",
	// shadowUrl: 'leaf-shadow.png',

	iconSize: [18, 18], // size of the icon
	shadowSize: [0, 0], // size of the shadow
	iconAnchor: [9, 9], // point of the icon which will correspond to marker's location
	shadowAnchor: [0, 0], // the same for the shadow
	popupAnchor: [-3, -3], // point from which the popup should open relative to the iconAnchor
})

export const HarthMap: bacta.ClosureComponent<MapAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m(
				".app." + css.map,
				{
					...attrs,
					oncreate: () => {
						const map = L.map(attrs.id)
						// .setZoom(16)

						attrs?.map?.update(() => map)

						L.tileLayer(
							// //STAMEN
							// 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png', {
							// 	attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
							// 	subdomains: 'abcd',
							// 	minZoom: 0,
							// 	maxZoom: 20,
							// }

							// //STAMENLITE
							// "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png",
							// {
							// 	attribution:
							// 		'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
							// 	subdomains: "abcd",
							// 	minZoom: 0,
							// 	maxZoom: 20,
							// }

							//STADIA
							"https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
							{
								maxZoom: 20,
								attribution:
									'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
							}

							// //OPENSTREETMAP

							// 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
							// , {
							// 	maxZoom: 19,
							// 	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
							// }

							// //ESRI

							// 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
							// , {
							// 	maxZoom: 19,
							// 	attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
							// }
						).addTo(map)
					},
				},
				children
			)
		},
	}
}
