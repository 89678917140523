/* eslint-disable no-mixed-spaces-and-tabs */
/* globals console, setTimeout */
import m, * as bacta from "bacta"
import * as R from "ramda"
import Uppy from "./uppy"
import * as uuid from "uuid"
import moment from "moment"
import legacyCSS from "../../main.module.css"
const css = bacta.css
import { sql } from "../../utils/signed-query"

function diffFields(a: any, b: any) {
	return !R.equals(a, b)
}

async function request(url: string, para: any): Promise<any> {
	return m.request<any[]>(url, para).then(
		(res: any) => {
			// console.log(res)
			return res
		},
		(e: any) => {
			console.log(e.response)
			return e.response
		}
	)
}

async function updateSupplierItemCover(
	getFields: any,
	store: any,
	localUpdateOnly?: boolean,
	file_url?: string
) {
	const [{ supplier_items_image }] = localUpdateOnly
		? await sql`
		select * from app.supplier_items where supplier_items_id = ${
			getFields().supplier_items_id
		}
		`
		: file_url
		? await sql`
			update app.supplier_items
				set supplier_items_image = ${file_url}
			where supplier_items_id = ${getFields().supplier_items_id}
			returning *
		`
		: await sql`
		update app.supplier_items
			set supplier_items_image = (
				select file_url from app.files where supplier_items_id = ${
					getFields().organization_details_id
				} 
				and app.files.file_deleted is null 
				order by app.files.file_created DESC
				limit 1 
			)	
		where supplier_items_id = ${getFields().supplier_items_id}
		returning *
	`

	store
		.prop("item")
		.prop("supplier_items_image")
		.update(() => supplier_items_image)

	store.prop("items").unnest().where`
		(i) => i.supplier_items_id == ${getFields().supplier_items_id}
	`
		.prop("supplier_items_image")
		.update(() => supplier_items_image)
}
async function updateSupplierCover(
	getFields: any,
	store: any,
	localUpdateOnly?: boolean,
	file_url?: string
) {
	const [{ organization_details_logo_url }] = localUpdateOnly
		? await sql`
			select * from app.organization_details where organization_details_id = ${
				getFields().organization_details_id
			}
		`
		: file_url
		? await sql`
			update app.organization_details
				set organization_details_logo_url = ${file_url}
			where organization_details_id = ${getFields().organization_details_id}
			returning *
		`
		: await sql`
			update app.organization_details
				set organization_details_logo_url = (
					select file_url from app.files where organization_details_id = ${
						getFields().organization_details_id
					}
					and app.files.file_deleted is null
					order by app.files.file_created DESC 
					limit 1 
				)		
			where organization_details_id = ${getFields().organization_details_id}
			returning *
		`

	store.prop("suppliers").unnest().where`
			(i) => i.organization_details_id == ${getFields().organization_details_id}
		`
		.prop("organization_details_logo_url")
		.update(() => organization_details_logo_url)
}

const data = {
	api: {
		files: {
			// async SIGN(filename: any, filetype: any, associations: any) {
			// 	return await request("/api/odin/signfiles", {
			// 		method: "POST",
			// 		headers: {
			// 			"content-type": "application/json",
			// 		},
			// 		body: {
			// 			...associations,
			// 			filename,
			// 			filetype,
			// 		},
			// 	})
			// },
			async getTransLoaditSignature(body: any) {
				return await request("/api/sql/transloadit/signature", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body,
				})
			},
			async LIST(body: any, getFields: any, store: any) {
				const {
					organization_id,
					file_id,
					file_error,
					file_processed,
					order_id,
					supplier_items_id,
					supplier_id,
					organization_details_id,
				} = body
				const files = await sql`
					select
				
						file_id
						, file_url
						, file_thumbnail_url
						, file_thumb_url
						, file_optimized_url						
						, file_upname
						, file_ext
				
						, bucket
						, key
						, thumb_key
						, thumbnail_key
						, optimized_key
				
						, file_processed
						, file_created
						, name
						
						, file_error is not null as file_error
				
						, F.supplier_id
						, F.supplier_items_id
						, F.order_id
						, F.organization_details_id
				
				
					from app.files F
					left join app.users using(user_id)
					left join app.suppliers S on S.supplier_id = F.supplier_id
					left join app.supplier_items SI on SI.supplier_items_id = F.supplier_items_id
					left join app.orders O on O.order_id = F.order_id
					left join app.organization_details OD on OD.organization_details_id = F.organization_details_id					
					where
				
						F.organization_id = ${organization_id}
						and F.organization_id is not null
						and coalesce(
							S.supplier_id
							, SI.supplier_items_id
							, O.order_id
							, OD.organization_details_id
						) is not null
				
						and file_deleted is null
				
						and file_url is not null
						and bucket is not null
						and key is not null
				
						and (${file_id}::uuid is null
							or file_id = ${file_id}::uuid)
				
						and (${file_error}::boolean is null
							or file_error is not null = ${file_error}::boolean)
				
						and (${file_processed}::boolean is null
							or file_processed = ${file_processed}::boolean)
				
				
						-- optional filters
						and (
							${order_id}::uuid is null
							or F.order_id = ${order_id}
						)

						and (
							${organization_details_id}::uuid is null
							or F.organization_details_id = ${organization_details_id}
						)						
				
						and (
							${supplier_items_id}::uuid is null
							or F.supplier_items_id = ${supplier_items_id}
						)
				
						and (
							${supplier_id}::uuid is null
							or F.supplier_id = ${supplier_id}
						)
				
						limit 1000
					`

				if (getFields().supplier_items_id) {
					await updateSupplierItemCover(getFields, store, true)
				}

				if (getFields().organization_details_id) {
					await updateSupplierCover(getFields, store, true)
				}
				return files
			},

			async DELETE(files: any, getFields: any, file_url: string, store: any) {
				const ids = files.map((f: any) => f.file_id)
				const req = await sql`
					update app.files
					set file_deleted = now()
					where file_id = ANY( ${ids}::uuid[] )
					returning file_id
				`

				if (getFields().supplier_items_id) {
					await sql`
						update app.supplier_items
							set supplier_items_image = null								
						where supplier_items_id = ${getFields().supplier_items_id}
						and supplier_items_image = ${file_url}										
					`
					await updateSupplierItemCover(getFields, store)
				}

				if (getFields().organization_details_id) {
					await sql`
						update app.organization_details
							set organization_details_logo_url = null												
						where organization_details_id = ${getFields().organization_details_id}
						and organization_details_logo_url = ${file_url} 				
					`

					await updateSupplierCover(getFields, store)
				}

				return req
				// return await request("/api/sql/deletefiles", {
				// 	method: "POST",
				// 	headers: {
				// 		"content-type": "application/json",
				// 	},
				// 	body: { files: files.map((f: any) => f.file_id) },
				// })
			},

			async UNDELETE(files: any, getFields: any, store: any) {
				const ids = files.map((f: any) => f.file_id)
				const req = await sql`
					update app.files
					set file_deleted = null
					where file_id = ANY( ${ids}::uuid[] )
					returning file_id
				`

				if (getFields().supplier_items_id) {
					await updateSupplierItemCover(getFields, store)
				}

				if (getFields().organization_details_id) {
					await updateSupplierCover(getFields, store)
				}

				return req
				// return await request("/api/sql/undeletefiles", {
				// 	method: "POST",
				// 	headers: {
				// 		"content-type": "application/json",
				// 	},
				// 	body: { files: files.map((f: any) => f.file_id) },
				// })
			},
		},
	},
	features: () => ({
		uppyWebcam: true,
		uppyGoogleDrive: false,
		uppyDropbox: false,
		uppy: true,
	}),
}
const hideScrollbars = css` & {
		-ms-overflow-style: none;
		scrollbar-width: none;
		& ::-webkit-scrollbar" {
			display: none;
		}
	}
  `
const image_exts = ["jpg", "jpeg", "png", "gif"]

/**
 * files = List of Harth files
 * data = Harth data object, for API access
 * embedded = Do not create the white area
 * listOnly = Do not show the uploader
 * readOnly = No Delete Action
 */
function MiniGallery() {
	return {
		view({
			attrs: {
				files,
				data,
				listOnly,
				readOnly,
				embedded,
				download,
				query,
				getFields,
				store,
			},
		}: any) {
			return m(
				".mini-gallery",
				// hideScrollbars,
				embedded ? ".embedded" : "",
				css`
					& {
						display: flex;
						flex-wrap: wrap;
						--width: 5.7em;

						background-color: #fafafa;
						padding: 0.5em;
						border-radius: 0.5em;
						border: 1px solid #eaeaea;
						gap: 0.3em;
						opacity: 1;
						cursor: default;

						max-height: 18.1em;
						overflow-x: auto;
					}
				`,
				css`
					&.embedded {
						background-color: transparent;
						padding: 0em;
						border: none;
					}
				`,
				css`
					&.embedded .file {
						margin: 0em;
						border: none;
					}
				`,
				// .$animate("ease-in-out 1s 1s forwards", {
				// 	from: "o 0",
				// 	to: "o 1",
				// })
				css`
					& .file {
						width: var(--width);
						height: var(--width);
						min-height: var(--width);
						background-size: cover;
						transition: 0.2s;
						border: solid 1px #eee;
						background-color: #eee;
						border-radius: 0.5em;
						opacity: 1;
						position: relative;

						margin: ${listOnly ? "0.5em" : "0em"};
					}
				`,
				css`
					& * {
						cursor: pointer;
					}
				`,
				// .$animate("ease-in-out 1s 2s forwards", {
				// 	from: "o 0%",
				// 	to: "o 100%",
				// })
				css`
					&:hover {
						opacity: 0.9;
					}
				`,
				css`
					&:hover {
						opacity: 0.9;
					}
				`,
				css`
					@media (hover: hover) {
						& {
							transform: scale(0.95);
						}
					}
				`,
				css`
					& .actions {
						animation-duration: 0.2s;
						animation-name: slidein;
					}
				`,
				css`
					& .action.disabled:hover {
						text-decoration: none;
					}
				`,
				css`
					&&& .file:hover .ext {
						opacity: 0;
						animation: none;
					}
				`,
				m(
					".app." + legacyCSS.flexBoxes,
					(download && files.length && false
						? [
								{
									zipRequest: true,
									file_ext: download.reference.zip_error
										? "Error"
										: download.reference.is_zip_processing
										? "Processing"
										: Date.now() -
												new Date(download.reference.latest_zip_date).getTime() <
										  60 * 60 * 1000
										? "Completed"
										: "",
									file_date: moment(
										download.reference.latest_zip_date
									).fromNow(),
									file_url: download.reference.latest_zip_url,

									file_thumbnail_url: "../assets/icons8-download.gif",
									file_thumb_url: "../assets/icons8-download.gif",
									mithrilKeye: "zip-indicator",
									file_id: null as null | string,
									file_deleted_at: null as null | number | undefined,
									_loading: null as null | boolean | number,
								},
						  ]
						: []
					)
						.concat(files)
						.map((x) => {
							return m(
								".",
								css`
									& {
										display: grid;
										grid-template-rows: auto;
									}
								`,
								m(
									"a.file" + "." + x.file_id,
									{
										style: {
											backgroundImage: !image_exts.includes(x.file_ext)
												? `url("${x.file_thumbnail_url}")`
												: `url("${x.file_thumb_url}")`,
											width: "5em",
											height: "5em",
										},
										href: x.file_url,
										target: "_blank",
										rel: "noopener noreferrer",
										title: "Created " + x.file_date,
										disabled:
											x.file_deleted_at != null ||
											!x.zipRequest ||
											(x.zipRequest && x.file_url),
									},
									m(
										".overlay",
										css`
											& {
												min-width: 100%;
												min-height: 100%;
												background-color: black;
												display: block;
												position: absolute;
												border-radius: 0.5em;
												top: 0px;
												pointer-events: none;
												opacity: ${x.file_deleted_at != null ? 0.8 : 0};
												transition: 2s;
											}
										`
									),
									x.zipRequest &&
										["Processing", "Completed", "Error"].includes(x.file_ext)
										? m(
												".ext",
												css`
													& {
														position: relative;
														top: -4px;
														color: white;
														background-color: ${x.file_ext == "Error"
															? "red"
															: "black"};
														border-radius: 0.5em;
														padding: 0.2em 0.5em;
														opacity: 1;
														animation: bdcmli51 ease-in-out 1s forwards;
														text-align: center;
														font-size: 13px;
														height: 2em;
														align-content: center;
														display: grid;
													}
												`,
												// .$animate("ease-in-out 1s forwards", {
												// 	from: "o 0",
												// 	to: "o 1",
												// }),
												x.file_ext
										  )
										: (!image_exts.includes(x.file_ext) || x.file_deleted_at) &&
												!x.zipRequest &&
												m(
													".ext",
													css`
														& {
															position: relative;
															left: -4px;
															top: -4px;
															color: white;
															background-color: black;
															border-radius: 0.5em;
															padding: 0.2em 0.5em;
															opacity: 1;
														}
													`,
													// .$animate("ease-in-out 1s forwards", {
													// 	from: "o 0",
													// 	to: "o 1",
													// })
													x.file_deleted_at ? "Deleted" : x.file_ext
												)
								),

								m(
									".actions",
									css` & {
										--position: absolute;
										--top: 0px;
										background-color: rgba(0,0,0,0.8)
										grid-template-rows: ${x.file_deleted_at || readOnly ? `1fr` : `1fr 1fr`};
										gap: 0.5em;
										height: 100%;
										width: 100%;
										align-content: space-between;
										justify-content: center;
										--align-items: end;
										display: grid;
										gap: 0.25em;
										border-radius: 0.5em;
										opacity: 1;
										transition: 0.2s;
										pointer-events: none;
									}
									`,

									css`
										& .action {
											color: black;
											opacity: 1;
											justify-content: center;
											align-items: center;
											display: grid;
											transition: 0.2s;
										}
									`,
									css`
										& .action:hover {
											color: black
											text-decoration: 										
											color: ${
												(x.zipRequest &&
													download.reference.is_zip_processing ==
														"Processing") ||
												(x.zipRequest &&
													download.reference.zip_error == "Retry?") ||
												(x.zipRequest && x.file_url == "New Zip") ||
												x.zipRequest
													? "underline"
													: "none"
											};
											opacity: 1;
										}
									`,

									x.file_deleted_at != null ||
										((!x.zipRequest || (x.zipRequest && x.file_url)) &&
											m(
												"a.action",
												{
													href: x.file_url,
													target: "_blank",
													rel: "noopener noreferrer",
													title: "Created " + x.file_date,
												},
												css`
													& {
														color: white;
														text-decoration: none;
														pointer-events: all;

														& visited {
															color: white;
														}
													}
												`,
												x.zipRequest && x.file_url
													? Date.now() -
															new Date(
																download.reference.latest_zip_date
															).getTime() <
													  30_000
														? "Latest"
														: "Previous"
													: "View"
											)),

									x.file_deleted_at == null &&
										!x.zipRequest &&
										m(
											"a.action",
											{
												// href: x.file_url,
												target: "_blank",
												rel: "noopener noreferrer",
												title: "Set " + x.file_url,
												onclick: async () =>
													getFields().supplier_items_id
														? updateSupplierItemCover(
																getFields,
																store,
																false,
																x.file_url
														  )
														: getFields().organization_details_id
														? updateSupplierCover(
																getFields,
																store,
																false,
																x.file_url
														  )
														: null,
											},
											css`
												& {
													color: white;
													text-decoration: none;
													pointer-events: all;

													& visited {
														color: white;
													}
												}
											`,
											"Set as Cover"
										),

									x.file_deleted_at != null ||
										(!readOnly &&
											(!x.zipRequest || x.file_id != "Processing") &&
											m(
												".action",
												{
													onclick:
														download && download.reference.is_zip_processing
															? null
															: x.zipRequest
															? async () => {
																	download.reference.is_zip_processing = true
																	m.redraw()
																	await download.request()
																	m.redraw()
															  }
															: () => {
																	x._loading = Date.now()
																	return data.api.files
																		.DELETE([x], getFields, x.file_url, store)
																		.then(
																			() => (x.file_deleted_at = Date.now())
																		)
																		.finally(() => (x._loading = false))
																		.finally(
																			() =>
																				query.update(() => query.get()) &&
																				m.redraw()
																		)
															  },
													disabled: x._loading,
												},
												css`
													& {
														pointer-events: all;
													}
												`,
												css`
													& {
														${download && download.reference.is_zip_processing
															? ".disabled"
															: ""}
													}
												`,
												x.zipRequest && download.reference.is_zip_processing
													? "Processing"
													: x.zipRequest && download.reference.zip_error
													? "Retry?"
													: x.zipRequest && x.file_url
													? "New Zip"
													: x.zipRequest
													? "Create Zip"
													: "Delete"
											)),

									x.file_deleted_at != null &&
										!readOnly &&
										m(
											".action",
											{
												onclick() {
													x._loading = Date.now()
													data.api.files
														.UNDELETE([x], getFields, store)
														.then(() => delete x.file_deleted_at)
														.finally(() => (x._loading = false))
														.finally(
															() =>
																query.update(() => query.get()) && m.redraw()
														)
												},
												disabled: x._loading,
											},
											css`
												& {
													pointer-events: all;
												}
											`,
											"Restore"
										)
								)
							)
						})
				)
			)
		},
	}
}

function Main({ attrs }: any) {
	let prevFields = attrs.getFields()
	let loading = true
	let files: string | any[] = []
	let uppy: {
		// eslint-disable-next-line @typescript-eslint/ban-types
		setState: (arg0: { files: {} | {} }) => any
		on: (arg0: string, arg1: () => void) => void
	}
	const query = attrs.query
	const syncFiles = (fks: any) => {
		return data.api.files
			.LIST(fks, attrs.getFields, attrs.store)
			.then(
				(xs: string | any[]) => (files = xs) && query.update(() => xs),
				console.error
			)
			.finally(() => (loading = false))
			.then(() => m.redraw())
	}

	syncFiles(prevFields)

	function view({ attrs }: any) {
		const {
			getFields,
			listOnly,
			width = 300,
			height = 250,
			download,
			store,
		} = attrs

		const nextFields = getFields()

		const fieldsChanged = diffFields(prevFields, nextFields)

		if (!loading && fieldsChanged) {
			// re-fetch list, reset uppy, clear deleted files
			files = []
			syncFiles(nextFields).then(() => uppy && uppy.setState({ files: {} }))
		}
		prevFields = nextFields
		return m(
			".harth-uppy",
			{
				style: {
					"justify-items": "center",
					display: "grid",
					gap: "1em",
				},
			},
			listOnly ||
				Uppy.simple({
					v: attrs.v,
					width,
					height,
					getFields() {
						return Object.assign({ file_id: uuid.v4() }, prevFields)
					},
					instance: (_uppy: {
						// eslint-disable-next-line @typescript-eslint/ban-types
						setState: (arg0: { files: {} }) => any
						on: (arg0: string, arg1: () => void) => void
					}) => {
						uppy = _uppy
						uppy.on("complete", () => {
							// give the harth API time to receive the notification
							const NOTIFY_TIMEOUT = 3000

							setTimeout(() => {
								syncFiles(prevFields).then(() => uppy.setState({ files: {} }))
							}, NOTIFY_TIMEOUT)
						})
					},
					data,
				}),

			() =>
				bacta.Stream.merge([query.get]).map(([]) => {
					return m(MiniGallery, {
						files,
						loading,
						data,
						listOnly,
						embedded: attrs.embedded,
						readOnly: attrs.readOnly,
						download,
						query,
						getFields,
						store,
					})
				})
		)
	}

	return { view }
}

export default Main
