import m, * as bacta from "bacta"
import { Input, InputAttrs } from "../sl/input"

type NumberAttrs = {
	type: "number"
	query?: bacta.Store<number>
}

type TextAttrs = {
	type: "text" | "time"
	query?: bacta.Store<string>
}

type DateAttrs = {
	type: "date"
	query?: bacta.Store<Date | null>
}

type BaseAttrs = {
	sl?: Partial<InputAttrs>
	label?: string
	value?: string
	postop?: Function
}
export type MainAttrs = BaseAttrs & (NumberAttrs | TextAttrs | DateAttrs)

export const HarthInput: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs }, children }) => {
		return m(
			Input,
			{
				type: attrs.type,
				["onsl-input"](e) {
					let v: any

					if (attrs.type === "date") {
						v = (e.target as HTMLInputElement)?.valueAsDate
					} else if (attrs.type === "number") {
						v = (e.target as HTMLInputElement)?.valueAsNumber
					} else {
						v = (e.target as HTMLInputElement)?.value
					}

					if (attrs.query) {
						attrs.query.update(() => v)
					}
					if (attrs.postop) {
						attrs.postop(v)
					}
				},
				[attrs.type === "text" || attrs.type === "time"
					? "value"
					: attrs.type === "date"
					? "valueAsDate"
					: "valueAsNumber"]: attrs.query?.get() || attrs.value,
				...sl,
			},
			children
		)
	},
})
