/* eslint-disable no-mixed-spaces-and-tabs */
import m, * as bacta from "bacta"
import * as L from "leaflet"
import legacyCSS from "./main.module.css"
import { HarthSelect } from "./components/harth/select"
import { HarthInput } from "./components/harth/input"
import { HarthTextArea } from "./components/harth/textarea"
import { HarthButton } from "./components/harth/button"
import { HarthIcon, HarthIconButton } from "./components/harth/icon"
import { HarthSpinner } from "./components/harth/spinner"
import { HarthStripe } from "./components/harth/stripe"
import { HarthMenu } from "./components/harth/menu"
import { HarthDetails } from "./components/harth/details"
import HarthUppy from "./components/harth/harth-uppy"
import {
	getLocation,
	initialize,
	searchItems,
	sherpa,
	sql,
	here,
	setMetaData,
	stripeCharge,
} from "./utils/signed-query"
import { HarthTabGroup } from "./components/harth/tab"
import {
	HarthMap,
	dotMarker,
	LocationMarker,
	outlineddotMarker,
	craneMarker,
	toolsMarker,
	Map,
} from "./components/harth/map"
import { HarthCard } from "./components/harth/card"
import { HarthProgressBar } from "./components/harth/progress-bar"
import moment from "moment"
import { State, useStore, FormalAddress } from "./store"
import { money, pretty } from "./utils/regexes"
import {
	deliveryType,
	gpsType,
	itemType,
	orderType,
	supplierType,
} from "./types"
import * as R from "ramda"
import { HarthDialog } from "./components/harth/dialog"
import { HarthDrawer } from "./components/harth/drawer"
import { HarthRange } from "./components/harth/range"
import { HarthAvatar } from "./components/harth/avatar"
import { privacyPolicy } from "./privacy-policy"
import { HarthIc } from "./components/harth/image-comparer"

const css = bacta.css

const dologoyellow = "assets/DropOff-Logo-grey-transparent-tight 1@2x.png"
const dologo = "assets/DropOff-Logo-grey-transparent-tight.png"
const dologopin = "assets/DropOff-Logo-grey-transparent-tight-pin.png"
const dologopinyellow = "assets/DropOff-Logo yellow-transparent-pin.png"
const orangetick = "assets/orange-tick-outlined.png"
const applanding = "assets/iStock-1562395031 1.png"
const websiteHome = "assets/website-landing169CS.png"
const websiteHomePortrait = "assets/website-landing21CS.png"
const availableIcon = "assets/available-icon.svg"
const unavailableIcon = "assets/unavailable-icon.svg"
const serialIcon = "assets/serial-icon.svg"
const bolttruck = "assets/delivery_truck_bolt.svg"
const speedytruck = "assets/delivery_truck_speed.svg"
const timeindex = {
	Now: 5,
	Within2hours: 0,
	Within4hours: 1,
	SameDay: 2,
}
const pp = m.trust(privacyPolicy)

type SearchK = keyof State

function debounce(f: (...args: any[]) => void | Promise<void>, ms = 250) {
	let id: string | number | NodeJS.Timeout | undefined
	return function (...args: any[]) {
		clearTimeout(id)
		id = setTimeout(async function () {
			await f(...args)
		}, ms)
	}
}

function updateCheckout() {
	const { store } = useStore()

	const checkoutitems =
		store.prop("state").get() == "Checkout"
			? store.prop("basket").get()
			: store.prop("items").unnest().where`i => i.quantity > 0`.getAll()

	store.prop("basket").update(() => checkoutitems)

	if (checkoutitems.length == 1) {
		store.prop("state").update(() => "Checkout")
		window.scrollTo(0, 0)
	}
}

document.body.classList.add(legacyCSS.body)

function autocomplete(
	v: any,
	options: Array<string> = [],
	query: bacta.Store<string> | bacta.Store<string[]>,
	isnew: bacta.Store<boolean>,
	help: string,
	boxtype: string,
	width: string,
	required: boolean,
	customInput?: any
) {
	return m(
		".app.",
		css`
			& {
				width: ${width};
			}
		`,

		!isnew.get() && options.length
			? m(HarthSelect, {
					query: query,
					options: options,
					sl: {
						multiple: false,
						placement: "bottom",
						size: "medium",
						hoist: true,
						helpText: help,
						clearable: false,
						class: "borderBottom",
					},
			  })
			: customInput
			? customInput(v)
			: boxtype == "input"
			? m(HarthInput, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						size: "medium",
						helpText: help,
						class: "borderBottom",
						clearable: true,
						required: required,
					},
			  })
			: m(HarthTextArea, {
					query: query as bacta.Store<string>,
					type: "text",
					sl: {
						placeholder: "",
						size: "small",
						helpText: help,
					},
			  }),

		options.length
			? m(
					HarthButton,
					{
						query: isnew,
						sl: {
							size: "medium",
							variant: "default",
							outline: false,
							value: isnew.get() ? false : true,
							class: "whitebackground",
						},
					},
					m(HarthIcon, {
						sl: {
							name: isnew.get() ? "arrow-return-left" : "pencil",
							slot: "prefix",
							style: { "font-size": "24px" },
						},
					})
			  )
			: null
	)
}

function buttonIcon(
	query: any,
	icon: string,
	value: string,
	label: string,
	help: string,
	options: string[]
) {
	const q = query.get()
	return m(
		".app.",
		{
			style: {
				[q == value ? "border" : ""]: "solid 1px #ffc735",
				color: "black",
			},
		},
		m(
			HarthButton,
			{
				query: query,
				sl: {
					size: "large",
					variant: "default",
					outline: false,
					value: value,
					disabled: options.filter((o) => value == o).length == 0,
				},
			},
			m(HarthIcon, {
				sl: {
					[icon.indexOf("/") > -1 ? "src" : "name"]: icon,
					slot: "prefix",
				},
				size: q == value ? "48px" : "36px",
			}),
			m(
				".app.",
				css`
					& {
						color: black;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
					}
				`,
				m(
					".app." + legacyCSS.leftSubHeader,
					css`
						& {
							max-height: 1.5em;
						}
					`,
					label
				),
				m(".app." + legacyCSS.helper, help)
			)
		)
	)
}

function addressMap(v: bacta.Vnode) {
	const { store } = useStore()

	useAddressMap(v)

	return {
		view: () =>
			m(
				".app.map-wrapper",
				css`
					& {
						opacity: ${() =>
							store
								.prop("FormalAddress")
								.prop("position")
								.get.map((x) => (x ? 1 : 0.4))};
						transition: opacity 1s;
						pointer-events: ${() =>
							store
								.prop("FormalAddress")
								.prop("position")
								.get.map((x) => (x ? "inherit" : "none"))};

						border-radius: var(--border-radius);
						overflow: hidden;
						box-shadow: 0px 4px 5px -2px #c1c1c1;
					}
				`,
				m(HarthMap, { id: "address-map", map: store.prop("map") })
			),
	}
}

function newNearAddress(v: bacta.Vnode, clearable = false, helpText = "") {
	const {
		store,
		FormalAddress,
		nearAddressOptions,
		ValidatedAddress,
		addressError,
		l,
	} = useStore()

	const map$ = store.prop("map")
	const address = true

	v.useEffect({ name: "map-update-input" }, function* () {
		const [map, FormalAddress] = (yield [
			map$,
			store.prop("FormalAddress"),
			store.prop("nearAddressOptions"),
			store.prop("ValidatedWebAddress"),
		]) as [Map, FormalAddress]

		if (
			FormalAddress &&
			FormalAddress.position &&
			map.options &&
			store.prop("state").get() == "Checkout"
		) {
			// const icon = LocationMarker
			// const marker = L.marker(
			// 	[FormalAddress.position.lat, FormalAddress.position.lng],
			// 	{
			// 		icon,
			// 	}
			// )
			// marker.addTo(map)
			map.setView(
				[
					Number(FormalAddress.position.lat),
					Number(FormalAddress.position.lng),
				],
				17,
				{
					animate: true,
				}
			)

			map.fitBounds(
				[
					[
						Number(FormalAddress.position.lat),
						Number(FormalAddress.position.lng),
					],
				],
				{
					animate: true,
					padding: [25, 25],
					maxZoom: 17,
				}
			)
		}
	})

	// https://github.com/JAForbes/bacta/issues/4
	v.useEffect({ name: "redraw" }, function* () {
		yield [FormalAddress.get, nearAddressOptions.get, ValidatedAddress.get]
		m.redraw()
	})

	return m(
		".app." + legacyCSS.spreadRows,
		address
			? () =>
					bacta.Stream.merge([
						store.prop("deliveryTo").get,
						addressError.get,
						l.get,
					]).map(([]) =>
						m(HarthInput, {
							query: store.prop("deliveryTo"),
							type: "text",
							sl: {
								size: "medium",
								helpText: helpText,
								class: "borderNone",
								clearable,
								// disabled: l.get(),
								placeholder: "Type in your address here",
							},
						})
					)
			: null,

		address
			? () =>
					m(
						".app.popup-wrapper" + legacyCSS.tightMenuItem,
						css`
							& {
								position: relative;
							}
							& > * {
								position: absolute;
								/* Leaflet :/ */
								z-index: 1000000;
								width: 100%;
							}
						`,

						bacta.Stream.merge([
							store.prop("nearAddressOptions").get as any,
							store.prop("ValidatedAddress").get as any,
							store.prop("AddressError").get as any,
						]).map(([,]) =>
							nearAddressOptions.get().length >= 1 &&
							(store.prop("deliveryTo").get() !=
								store.prop("ValidatedAddress").get() ||
								!store.prop("deliveryTo").get()) &&
							FormalAddress.get().title != store.prop("deliveryTo").get()
								? m(HarthMenu, {
										query: store.prop("FormalAddress") as any,
										options: store
											.prop("nearAddressOptions")
											.get()
											.slice(0, 10),
										nameProp: "title",
										class: "tightMenuItem",
								  })
								: null
						)
					)
			: null,

		addressError.get()
			? m(
					".app." + legacyCSS.centeredTitles,
					css`
						& {
							padding-top: 0.75rem;
							color: red;
						}
					`,
					store.prop("FormalAddress").get()?.resultType &&
						store.prop("FormalAddress").get().resultType != "houseNumber" &&
						addressError.get() != "We can only deliver within 100 Km"
						? m(".app.", "Please specify street number")
						: null,
					m(".app.", addressError.get())
			  )
			: null
	)
}

function deliveryInputs(v: bacta.Vnode) {
	const { store } = useStore()

	const timeframe = store.prop("timeframe")
	const vehicle = store.prop("vehicle")
	const deliveryTo = store.prop("deliveryTo")
	const addressOptions = store.prop("addressOptions")
	const phoneOptions = store.prop("phoneOptions")
	const nameOptions = store.prop("nameOptions")
	const avo = store.prop("vehilceOptions")
	const checkoutindex = store.prop("checkoutindex").get()
	const availableTimeFrames = store.prop("availableTimeFrames").get()
	const atf = availableTimeFrames
		.filter((af) =>
			checkoutindex.every((co: any) => co[af.replaceAll(" ", "")] > 0)
		)
		.map((af) => af.replaceAll(" ", ""))

	return m(
		".app." + legacyCSS.spreadRowsBigGap,

		m(
			".app." + legacyCSS.spreadRows,
			css`
				& ::part(base) {
					border: none;
				}
				& ::part(header) {
					padding: 0;
				}
			`,

			["Now", "Within2hours", "Within4hours", "SameDay"].map((b) =>
				buttonIcon(
					timeframe,

					b == "Now"
						? "lightning-charge"
						: b == "Within2hours"
						? speedytruck
						: b == "Within4hours"
						? bolttruck
						: "truck",

					b,

					b == "Now"
						? "Urgent"
						: b == "Within2hours"
						? "Priority"
						: b == "Within4hours"
						? "Fast"
						: "Standard",

					b == "Now"
						? "within 1 Hour"
						: b == "Within2hours"
						? "within 2 Hours"
						: b == "Within4hours"
						? "within 4 Hours"
						: "Today",

					atf
				)
			)
		)

		// m(
		// 	".app." + legacyCSS.spreadColumnsSpaceAroundAlignStart,
		// 	{ style: { "min-height": "6em" } },
		// 	["Now", "Within2hours", "Within4hours", "SameDay"].map((b) =>
		// 		buttonIcon(
		// 			timeframe,
		// 			"clock-history",
		// 			b,
		// 			b == "Now"
		// 				? "Now"
		// 				: b == "Within2hours"
		// 				? "in 2 Hour"
		// 				: b == "Within4hours"
		// 				? "in 4 Hour"
		// 				: "Today",
		// 			atf
		// 		)
		// 	)
		// )

		// m(
		// 	".app." + legacyCSS.spreadColumnsSpaceAroundAlignStart,
		// 	{ style: { "min-height": "6em" } },
		// 	["1", "2", "4"].map((b) =>
		// 		buttonIcon(
		// 			vehicle,
		// 			b == "2" ? "bicycle" : b == "1" ? "car-front" : "truck",
		// 			b,
		// 			b == "2" ? "Bike" : b == "1" ? "Car" : "Van",
		// 			avo.get()
		// 		)
		// 	)
		// )

		// m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
		// 	autocomplete(
		//     v
		// 		, nameOptions
		// 		, store.prop("order_contact_name")
		// 		, store.prop("newName")
		// 		, "Site Contact"
		// 		, 'input'
		// 		, false
		// 	),

		// 	autocomplete(
		// 		v
		// 		, phoneOptions
		// 		, store.prop("order_phone")
		// 		, store.prop("newPhone")
		// 		, "Phone"
		// 		, 'input'
		// 		, false
		// 	)
		// ]),
		// ])
	)
}

function addressInputs(v: bacta.Vnode) {
	const { store, l, addressError, ValidatedAddress } = useStore()

	const timeframe = store.prop("timeframe")
	const deliveryTo = store.prop("deliveryTo")
	const checkoutindex = store.prop("checkoutindex").get()
	const availableTimeFrames = store.prop("availableTimeFrames").get()
	const atf = availableTimeFrames
		.filter((af) =>
			checkoutindex.every((co: any) => co[af.replaceAll(" ", "")] > 0)
		)
		.map((af) => af.replaceAll(" ", ""))

	return m(
		".app." + legacyCSS.spreadRowsBigGap,

		() =>
			bacta.Stream.merge([
				store.prop("newAddress").get as any,
				store.prop("deliveryTo").get as any,
				store.prop("AutoFillOptions").get as any,
				store.prop("FormalAddress").get as any,
				store.prop("newAddress").get as any,
				addressError.get as any,
				l.get as any,
			]).map(([]) =>
				m(
					".app." + legacyCSS.spreadRowsSmallGap,
					// m(
					// 	".app.",
					// 	css`
					// 		& {
					// 			display: flex;
					// 			justify-content: flex-end;
					// 		}
					// 	`,
					// 	m(
					// 		HarthButton,
					// 		{
					// 			query: store.prop("deliveryTo"),
					// 			postop: () =>
					// 				getLocation(store, store.prop("ValidatedWebAddress")),
					// 			sl: {
					// 				size: "medium",
					// 				variant: "text",
					// 				outline: false,
					// 				value: "",
					// 				class: "whitebackground",
					// 				disabled: store.prop("gettinglocation").get(),
					// 			},
					// 		},
					// 		store.prop("gettinglocation").get()
					// 			? m(HarthSpinner, {})
					// 			: m(HarthIcon, {
					// 					sl: {
					// 						// name: "geo-alt",
					// 						// name: "geo",
					// 						name: "crosshair",
					// 						slot: "suffix",
					// 						style: {
					// 							"font-size": "28px",
					// 							"font-weight": "bold",
					// 						},
					// 					},
					// 			  }),
					// 		`Use Current Location`
					// 	)
					// ),

					autocomplete(
						v,
						[],
						deliveryTo,
						store.prop("newAddress"),
						"Address",
						"input",
						"100%",
						true,
						() => newNearAddress(v, false)
					),

					m(addressMap, { v }),

					() =>
						l.get.map((loading) =>
							m(
								".app.",
								css`
									& {
										display: flex;
										justify-content: center;
									}
								`,
								loading
									? m(
											HarthSpinner,
											css`
												& {
													padding-top: 0.75rem;
												}
											`
									  )
									: !store.prop("deliveryTo").get() || addressError.get()
									? null
									: m("img", {
											src: orangetick,
											style: {
												"object-fit": "scale-down",
												width: "6%",
												height: "6%",
												"align-items": "center",
												"padding-top": "0.75rem",
											},
									  })
							)
						)
				)
			)
	)
}

function AppTray(v: any) {
	const { store, l } = useStore()
	const state = store.prop("state")
	const basket = store.prop("basket")
	const org = store.prop("organization")
	return {
		view: () =>
			m(
				".app.",
				m(HarthTabGroup, {
					query: state,
					color: "#ffc735",
					width: org.get() && org.get().supplier_id ? "30%" : "25%",
					sl: {
						class: "navbarFixed",
						placement: "bottom",
						activation: "manual",
						noScrollControls: true,
					},
					tabs: [
						{
							name: "Home",
							icon: m(
								".app.",
								{
									style: {
										[store.prop("state").get() == "Home" ? "color" : ""]:
											"#ffc735",
										width: "100%",
									},
								},
								m(HarthIcon, {
									sl: {
										name:
											org.get() && org.get().supplier_id
												? "truck"
												: "house-door",
									},
									size: "1.5rem",
								}),
								m(".app.", "Home")
							),
							view:
								org.get() && org.get().supplier_id
									? m(SupplierView, { v })
									: m(HomeView, { v }),
							active:
								(org.get() && org.get().supplier_id) ||
								store.prop("state").get() == "Home"
									? true
									: false,
							disabled: l.get(),
						},
						{
							name: "Search",
							icon: m(
								".app.",
								{
									style: {
										[store.prop("state").get() == "Search" ? "color" : ""]:
											"#ffc735",
										width: "100%",
									},
								},
								m(HarthIcon, {
									sl: {
										name:
											org.get() && org.get().supplier_id ? "boxes" : "search",
									},
									size: "1.5rem",
								}),
								m(".app.", "Products")
							),
							view: m(SearchView, { v }),
							active:
								(org.get() && org.get().supplier_id) ||
								store.prop("state").get() == "Home"
									? false
									: true,
							disabled: l.get(),
						},
						{
							name: "Checkout",
							icon: m(
								".app.",
								{
									style: {
										[store.prop("state").get() == "Checkout" ? "color" : ""]:
											"#ffc735",
										width: "100%",
									},
								},
								m(HarthIcon, {
									sl: { name: "cart2" },
									size: "1.5rem",
								}),
								m(".app.", "Cart")
							),
							view: m(CheckoutView, { v }),
							active: false,
							disabled: basket.get().length == 0 || l.get(),
						},
						{
							name: "Settings",
							icon: m(
								".app.",
								{
									style: {
										[store.prop("state").get() == "Settings" ? "color" : ""]:
											"#ffc735",
										width: "100%",
									},
								},
								m(HarthIcon, {
									sl: { name: "person" || "boxes" },
									size: "1.5rem",
								}),
								m(".app.", "Account")
							),
							view: m(SettingsView, {}),
							active: false,
							disabled: true,
						},
					].filter((t) => {
						const rt =
							!org.get() ||
							!org.get().supplier_id ||
							(org.get() &&
								org.get().supplier_id &&
								(t.name == "Search" || t.name == "Home"))
						return rt
					}),
				})
			),
	}
}

function AppHeader() {
	const { store, l, organization, u } = useStore()
	const drawOpen = store.prop("drawOpen")
	const navmenuOpen = store.prop("navmenuOpen")
	const industryCategories = store.prop("industryCategories").get()
	const withinkm = store.prop("supplier_locations_address")
	const supplierid = organization.get() && organization.get().supplier_id
	const numberValues = [
		"supplier_items_weight",
		"supplier_items_height",
		"supplier_items_wdith",
		"supplier_items_length",
		"supplier_locations_items_cost",
	].map((a) => ({
		minbound: ("min_" + a) as SearchK,
		maxbound: ("max_" + a) as SearchK,
		min: (a + "_min") as SearchK,
		max: (a + "_max") as SearchK,
		type: (a.indexOf("_date") > -1 ? "date" : "number") as "date" | "number",
	}))

	const searchValues: { prop: SearchK; help: string }[] = [
		{ prop: "supplier_name", help: "Store" },
		{ prop: "supplier_items_name", help: "Name" },
		{ prop: "supplier_items_description", help: "Description" },
		{ prop: "supplier_items_category", help: "Category" },
		{ prop: "supplier_items_specifications", help: "Specifications" },
		{ prop: "supplier_items_features", help: "Features" },
		{
			prop: "supplier_locations_address",
			help: "Within " + withinkm.get() + " Km",
		},
	]
	const filterDraw = m(
		".app.",
		m(HarthDrawer, {
			query: drawOpen,
			sl: {
				placement: "start",
				contained: true,
				noHeader: true,
			},
			children: [
				m(".app." + legacyCSS.spreadRowsBigGap, [
					m(
						".app.",
						m(
							HarthButton,
							{
								query: drawOpen,
								sl: {
									size: "small",
									variant: "default",
									outline: false,
									value: false,
									class: "greyButton",
									pill: true,
								},
							},
							[
								m(HarthIcon, {
									sl: {
										name: "arrow-left-short",
										slot: "prefix",
									},
									size: "36px",
								}),
							]
						)
					),

					centeredSpinner(
						l.get(),
						"centeredHero",
						m(
							".app." + legacyCSS.centeredHeader,
							[
								"Found",
								store.prop("items").get().length ||
									R.sum(
										store.prop("itemCategories").get().map(R.prop("count"))
									),
								"Items",
							].join(" ")
						)
					),

					m(".app." + legacyCSS.spreadRowsBigGap, [
						searchValues.map((a) =>
							m(HarthInput, {
								query: store.prop(a.prop),
								type: "text",
								sl: {
									clearable: true,
									size: "medium",
									// [a.label ? 'label' : '']: a.label,
									helpText: a.help,
								},
							})
						),
					]),

					m(HarthSelect, {
						query: store.prop("supplier_items_industry"),
						options: industryCategories,
						sl: {
							multiple: true,
							placeholder: "Used in industries",
							size: "medium",
							class: "label-on-left",
							label: "Industry",
							helpText: "Filter specific to an Industry",
							clearable: true,
						},
					}),

					m(
						".app." + legacyCSS.spreadRows,
						numberValues.map((a) => {
							const dateype = a.type == "date"
							return [
								m(
									".app." + legacyCSS.leftTitles,
									css`
										& {
											padding-bottom: 0.5em;
											padding-top: 0.5em;
										}
									`,
									pretty(a.min, {
										_min: "",
										_max: "",
										locations_: "",
										supplier_: "",
									})
								),

								m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
									dateype
										? m(HarthInput, {
												query: store.prop(a.min),
												type: "date",
												sl: {
													helpText: "Min. " + store.prop(a.min).get(),
													size: "small",
												},
										  })
										: m(HarthRange, {
												query: store.prop(a.min),
												sl: {
													helpText: "Min. " + store.prop(a.min).get(),
													max: store.prop(a.minbound).get(),
													value: store.prop(a.min).get(),
												},
										  }),

									dateype
										? m(HarthInput, {
												query: store.prop(a.max),
												type: "date",
												sl: {
													helpText: "Max. " + store.prop(a.max).get(),
													size: "small",
												},
										  })
										: m(HarthRange, {
												query: store.prop(a.max),
												sl: {
													helpText: "Max. " + store.prop(a.max).get(),
													max: store.prop(a.maxbound).get(),
													value: store.prop(a.max).get(),
												},
										  }),
								]),
							]
						})
					),
				]),
			],
		})
	)
	const userDraw = m(
		".app.",
		m(HarthDrawer, {
			query: navmenuOpen,
			sl: {
				placement: "start",
				contained: true,
				noHeader: true,
			},
			children: [
				m(
					".app." + legacyCSS.spreadRowsBigGap,

					[
						m(
							".app.",
							m(
								HarthButton,
								{
									query: navmenuOpen,
									sl: {
										size: "small",
										variant: "default",
										outline: false,
										value: false,
										class: "greyButton",
										pill: true,
									},
								},
								[
									m(HarthIcon, {
										sl: {
											name: "arrow-left-short",
											slot: "prefix",
										},
										size: "36px",
									}),
								]
							)
						),

						m(
							".app.",
							css`
								& {
									margin-left: 1em;
								}
							`,
							m(
								".app." + legacyCSS.spreadColumnsCenter,
								m(HarthIcon, {
									sl: {
										name: "person",
										slot: "prefix",
										style: { "font-size": "24px" },
									},
								}),

								m(".app." + legacyCSS.leftHeader, "User Information")
							),
							m(
								".app." + legacyCSS.spreadRowsSmallGap,
								css`
									& {
										padding-left: 24px;
									}
								`,
								m(".app." + legacyCSS.title, store.prop("user").get().email),
								m(".app.", store.prop("user").get().name)
							)
						),

						m(
							".app.",
							css`
								& {
									margin-left: 1em;
								}
							`,
							m(
								".app." + legacyCSS.spreadColumnsCenter,
								m(HarthIcon, {
									sl: {
										name: "info-circle",
										slot: "prefix",
										style: { "font-size": "24px" },
									},
								}),
								m(".app." + legacyCSS.leftHeader, "App Information")
							),

							m(
								".app." + legacyCSS.spreadRowsNoGap,
								css`
									& ::part(base) {
										justify-content: flex-start;
										max-height: 2em;
										min-height: 0;
										padding-top: 0;
									}
								`,
								[
									{
										label: "Contact Us",
										icon: "",
										href: "http://localhost:8080/contactus",
										// "https://dropoff.au/contactus",
									},
									// {
									// 	label: "FAQs",
									// 	icon: "",
									// 	href: "https://dropoff.au/faqs",
									// },
									// {
									// 	label: "Terms and Conditions",
									// 	icon: "",
									// 	href: "https://dropoff.au/terms",
									// },
									{
										label: "Privacy Policy",
										icon: "",
										href: "https://dropoff.au/privacy_policy",
									},
								].map((x) =>
									m(
										HarthButton,
										{
											sl: {
												size: "large",
												variant: "default",
												outline: false,
												target: "_blank",
												value: "",
												class: "borderNone",
												href: x.href,
											},
										},
										x.label
									)
								)
							)
						),

						m(
							".app.",
							css`
								& {
									align-items: center;
									justify-items: center;
									display: flex;
									justify-content: center;
									padding-top: 2em;
								}
							`,
							m(
								HarthButton,
								{
									sl: {
										size: "large",
										variant: "default",
										outline: true,
										value: "",
										href: "api/auth/logout",
										class: "blackbackground",
									},
								},
								"Log Out"
							)
						),
					]
				),
			],
		})
	)
	return m(
		".app." + (!supplierid ? legacyCSS.appHeader : legacyCSS.sAppHeader),
		drawOpen.get()
			? filterDraw
			: navmenuOpen.get()
			? userDraw
			: [
					m("img", {
						src: dologopinyellow,
						style: {
							"object-fit": "scale-down",
							width: "82%",
							height: "82%",
						},
					}),

					,
					m(
						".app." + legacyCSS.spreadRowsNoGap,
						m(
							".app." + legacyCSS.lefTtext,
							m(
								".app.",
								css`
									& {
										font-weight: 400;
									}
								`,
								Number(new Date().toLocaleTimeString().split(":")[0]) < 12
									? "Good Morning"
									: "Good Afternoon"
							)
						),
						m(
							".app." + legacyCSS.leftHeader,
							store.prop("user").get().name.split(" ")[0]
						)
					),

					m(
						".app.",
						{
							style: {
								"padding-left": "0.5em",
							},
						},
						m(HarthAvatar, {
							size: "2.6em",
							query: store.prop("navmenuOpen"),
							slButton: {
								sl: {
									class: !supplierid
										? "lightyellowbackground"
										: "greybackground",
									value: true,
								},
							},
							sl: {
								image: store.prop("user").get().picture,
							},
						})
					),
			  ]
	)
}

function SettingsView() {
	const { store } = useStore()
	return {
		view: () =>
			store.prop("state").get() == "Settings"
				? m(".app." + legacyCSS.content, "SETTINGS")
				: null,
	}
}

function blinkingDots(prefix: string, check: boolean) {
	return check
		? m(
				".app." + legacyCSS.spreadColumns,
				prefix,
				m(".app." + legacyCSS.loader__dot, " ."),
				m(".app." + legacyCSS.loader__dot, "."),
				m(".app." + legacyCSS.loader__dot, ".")
		  )
		: [prefix]
}

function prettystatus(o: orderType, t?: string) {
	return t == "supplier"
		? ((o?.status == "order on its way"
				? "Heading to the customer"
				: o?.status == "picking up order"
				? "Driver on the way"
				: o?.status == "finding a driver"
				? "Finding a driver"
				: o?.status == "waiting acceptance"
				? "Waiting for acceptance"
				: o?.status == "cancelled" || o?.status == "delivered"
				? pretty(o?.status)
				: "") as string)
		: ((o?.status == "order on its way" && Number(o.eta.split(":")[0]) > 1
				? "Heading your way"
				: o?.status == "order on its way"
				? "Get ready for the Drop Off!"
				: o?.status == "picking up order"
				? "Picking up your order"
				: o?.status == "finding a driver"
				? "Finding you a driver"
				: o?.status == "waiting acceptance"
				? "Confirming your order"
				: o?.status == "cancelled" || o?.status == "delivered"
				? pretty(o?.status)
				: "") as string)
}

function stepProgress({ order, width }: { order: orderType; width: string }) {
	const render =
		order.status == "order on its way" ||
		order.status == "picking up order" ||
		order.status == "finding a driver" ||
		order.status == "waiting acceptance"
	return !render
		? null
		: m(
				".app.",
				css`
					& {
						display: flex;
						width: ${width};
						justify-content: flex-end;
					}
					& ::part(indicator) {
						--indicator-color: #ffc735;
					}
					& ::part(base) {
						--height: 5px;
					}
				`,
				m(
					".app." +
						(order.status == "waiting acceptance" ? legacyCSS.loader__dot : ""),
					css`
						& {
							width: 100%;
							padding-right: 0.5em;
						}
					`,
					m(HarthProgressBar, {
						sl: {
							slot: "header",
							value: order.status == "waiting acceptance" ? 60 : 100,
						},
					})
				),
				m(
					".app." +
						(order.status == "finding a driver" ? legacyCSS.loader__dot : ""),
					css`
						& {
							width: 100%;
							padding-left: 0.5em;
							padding-right: 0.5em;
						}
					`,
					m(HarthProgressBar, {
						sl: {
							slot: "header",
							value:
								order.status == "finding a driver"
									? 60
									: order.status == "waiting acceptance"
									? 0
									: 100,
						},
					})
				),

				m(
					".app." +
						(order.status == "picking up order" ? legacyCSS.loader__dot : ""),
					css`
						& {
							width: 100%;
							padding-left: 0.5em;
							padding-right: 0.5em;
						}
					`,
					m(HarthProgressBar, {
						sl: {
							slot: "header",
							value:
								order.status == "picking up order"
									? 60
									: order.status == "finding a driver" ||
									  order.status == "waiting acceptance"
									? 0
									: 100,
						},
					})
				),
				m(
					".app." +
						(order.status == "order on its way" ? legacyCSS.loader__dot : ""),
					css`
						& {
							width: 100%;
							padding-left: 0.5em;
						}
					`,
					m(HarthProgressBar, {
						sl: {
							slot: "header",
							value:
								order.status == "order on its way" &&
								Number(order.eta.split(":")[0]) < 1
									? 60
									: order.status == "order on its way"
									? 85
									: order.status == "finding a driver" ||
									  order.status == "picking up order" ||
									  order.status == "waiting acceptance"
									? 0
									: 100,
						},
					})
				)
		  )
}

function HomeView(v: bacta.Vnode) {
	const { store, l, u } = useStore()
	const state = store.prop("state")
	const orders = store.prop("orders")
	const map = store.prop("map")
	useAddressMap(v)

	return {
		view: () =>
			m(
				".app.",

				() =>
					bacta.Stream.merge([
						store.prop("orders").get as any,
						l.get as any,
					]).map(([,]) => {
						const latestorder = orders
							.get()
							.find(
								(o) =>
									o.status == "finding a driver" ||
									o.status == "picking up order" ||
									o.status == "order on its way" ||
									o.status == "waiting acceptance"
							)

						return store.prop("state").get() == "Home"
							? m(
									".app." + legacyCSS.content,
									centeredSpinner(
										l.get() || u.get(),
										"centeredHero",
										m(
											".app." + legacyCSS.spreadRowsBigGap,

											m(
												".app." + legacyCSS.leftSmallHero,
												m(
													".app." + legacyCSS.spreadColumns,

													blinkingDots(
														latestorder ? prettystatus(latestorder) : "",
														latestorder?.status == "finding a driver" ||
															latestorder?.status == "order on its way" ||
															latestorder?.status == "picking up order" ||
															latestorder?.status == "waiting acceptance"
													)
												)
											),
											latestorder
												? m(
														".app." + legacyCSS.spreadRows,
														m(
															".app." + legacyCSS.leftTitles,
															latestorder?.status == "finding a driver" ||
																latestorder?.status == "picking up order" ||
																latestorder?.status == "order on its way" ||
																latestorder?.status == "waiting acceptance"
																? "Estimated arrival at " +
																		(latestorder.deliverytime
																			? moment(latestorder.deliverytime).format(
																					"LT"
																			  )
																			: " ... ")
																: latestorder?.status == "cancelled"
																? pretty(latestorder?.status)
																: ""
														),
														stepProgress({
															order: latestorder,
															width: "100%",
														}),
														m(
															".app." + legacyCSS.helper,
															latestorder?.status == "finding a driver" ||
																latestorder?.status == "picking up order" ||
																latestorder?.status == "order on its way" ||
																latestorder?.status == "waiting acceptance"
																? "Latest arrival by " +
																		(latestorder.eta
																			? moment(latestorder.eta).format("LT")
																			: " ... ")
																: ""
														)
												  )
												: null,

											orders.get().length
												? m(HarthMap, {
														id: "orders-map",
														map,
														style: { "z-index": 0 },
												  })
												: null,

											orders.get().length
												? null
												: m(
														".app." + legacyCSS.centeredRows,
														m(
															HarthButton,
															{
																query: state,
																sl: {
																	size: "large",
																	variant: "default",
																	outline: true,
																	value: "Search",
																	class: "blackbackground",
																},
															},
															"Search Catalogue"
														)
												  ),

											orders.get().map((o) => {
												const deliveryoption =
													Object.keys(timeindex).find(
														(k: string) =>
															(timeindex[
																k as
																	| "Now"
																	| "Within2hours"
																	| "Within4hours"
																	| "SameDay"
															] as number) == o.sherpa[0].delivery_option
													) || ""
												return m(
													".app.",
													css`
														& {
															border-bottom: 5px solid #f3f6f8;
															border-top: 5px solid #f3f6f8;
															padding-top: 1em;
														}
													`,
													m(
														".app." + legacyCSS.spreadRowsBigGap,

														m(
															".app." + legacyCSS.spreadRows,
															m(
																".app." + legacyCSS.leftHeader,
																o.status == "waiting acceptance"
																	? [
																			"Delivery",
																			"-",
																			o.sherpa[0].orderdetail.deliveryDetails
																				.item_description,
																	  ].join(" ")
																	: [
																			"Delivery",
																			o.delivery_id,
																			" - ",
																			o.description,
																	  ].join(" ")
															),
															m(
																".app.",
																css`
																	& {
																		display: flex;
																		flex-direction: column;
																	}
																`,
																stepProgress({ order: o, width: "50%" }),

																m(
																	".app." + legacyCSS.leftHeader,
																	m(
																		".app." + legacyCSS.lefTtext,
																		blinkingDots(
																			prettystatus(o),
																			o.status == "finding a driver" ||
																				o.status == "order on its way" ||
																				o.status == "picking up order" ||
																				o.status == "waiting acceptance"
																		)
																	)
																),

																m(
																	".app." + legacyCSS.leftHeader,
																	m(
																		".app." + legacyCSS.lefTtext,

																		o?.status == "finding a driver" ||
																			o?.status == "picking up order" ||
																			o?.status == "order on its way" ||
																			o?.status == "waiting acceptance"
																			? "Estimated arrival at " +
																					(o.deliverytime
																						? moment(o.deliverytime).format(
																								"LT"
																						  )
																						: " ... ")
																			: null
																	)
																)
															)
														),
														m(
															".app." + legacyCSS.spreadRowsNoGap,
															m(
																".app." + legacyCSS.leftHeader,
																m(".app." + legacyCSS.lefTtext, "Address")
															),
															m(".app." + legacyCSS.leftTitles, o.address)
														),

														m(
															".app." + legacyCSS.spreadRowsNoGap,
															m(
																".app." + legacyCSS.leftHeader,
																m(
																	".app." + legacyCSS.lefTtext,
																	"Delivery Instructions"
																)
															),
															m(
																".app." + legacyCSS.leftTitles,
																o.instructions || "None"
															)
														),

														m(
															".app." + legacyCSS.spreadRowsNoGap,
															m(
																".app." + legacyCSS.leftHeader,
																m(
																	".app." + legacyCSS.lefTtext,
																	"Delivery Service"
																)
															),
															m(
																".app." + legacyCSS.leftTitles,
																[
																	deliveryoption == "Now"
																		? "Urgent"
																		: deliveryoption == "Within2hours"
																		? "Priority"
																		: deliveryoption == "Within4hours"
																		? "Fast"
																		: "Standard",
																	deliveryoption == "Now"
																		? "ASAP"
																		: deliveryoption == "Within2hours"
																		? "( Within 2 hours )"
																		: deliveryoption == "Within4hours"
																		? "( Within 4 hours )"
																		: "( Same Day )",
																].join(" ")
															)
														)
													),
													m(
														".app.",
														css`
															& ::part(base) {
																border: none;
															}
															& ::part(content) {
																padding-left: 0;
																padding-right: 0;
																padding-top: 0;
																padding-bottom: 1em;
															}
															& ::part(header) {
																padding-left: 0;
																padding-right: 0;
																padding-top: 2em;
																padding-bottom: 1em;
															}
														`,
														m(
															HarthDetails,
															{
																sl: {
																	class: "leftHeader",
																	summary: "Order Summary",
																	open: false,
																},
															},

															m(
																".app." + legacyCSS.lefTtext,
																(o.items || []).map((f: orderType) =>
																	m(
																		".app.",
																		pretty(
																			[
																				f.order_amount,
																				"\u2022",
																				f.order_name,
																			].join(" ")
																		)
																	)
																)
															)
														)
													)
												)
											})
										)
									)
							  )
							: null
					})
			),
	}
}

function CheckoutView(v: any) {
	const { store, l, b, addressError } = useStore()

	const info = store.prop("info")
	const orderid = store.prop("orderid")
	const checkoutindex = store.prop("checkoutindex")
	const timeframe = store.prop("timeframe")
	const vehicle = store.prop("vehicle").get()
	const deliveryTo = store.prop("deliveryTo")
	const contact_name = store.prop("order_contact_name").get()
	const phone = store.prop("order_phone").get()
	const email = store.prop("order_email").get()
	const amount = store.prop("checkoutindex").get.map((c) => {
		return (
			R.sum(
				c.map((i) => {
					return (
						Number(i.total) + Number(i[timeframe.get()]) + Number(i.servicefee)
					)
				})
			) ?? 0
		)
	})

	const address = store.prop("FormalAddress").get()

	const card = (i: deliveryType) =>
		m(
			".app.",
			css`
				& ::part(base) {
					border: none;
				}
				& ::part(header) {
					padding: 0;
				}
			`,
			m(
				HarthDetails,
				{
					sl: {
						open: false,
					},
				},
				m(
					".app.",
					{ slot: "summary" },
					m(
						".app.",
						css`
							& {
								align-items: center;
								justify-content: flex-start;
								display: flex;
								grid-template-columns: max-content;
							}
						`,
						m("img", {
							src: store
								.prop("suppliers")
								.get()
								.find((s) => s.supplier_id == i.supplier_id)!
								.organization_details_logo_url,
							style: {
								"object-fit": "scale-down",
								width: "25%",
								height: "25%",
								"padding-right": "0.5em",
							},
						}),

						m(
							".app." + legacyCSS.spreadRowsNoGap,
							m(".app." + legacyCSS.leftSubHeader, i.supplier),
							m(".app." + legacyCSS.helper, [i.items.length, "items"].join(" "))
						)
					)
				),

				m(
					".app." + legacyCSS.spreadRows,
					i.items.map((d) => {
						const item = store.prop("basket").unnest().where`
							(i) =>
								i.supplier_items_id == ${d.supplier_items_id} &&
								i.supplier_locations_id == ${d.supplier_locations_id}
						`
						return m(
							".app." + legacyCSS.spreadColumnsSpaceBetween,

							m(
								HarthButton,
								{
									query: item.prop("quantity"),
									postop: updateCheckout,
									sl: {
										size: "small",
										variant: "default",
										outline: false,
										value: 0,
										class: "whitebackground",
										disabled: orderid.get() != "",
									},
								},
								[
									m(HarthIcon, {
										sl: {
											name: "x",
											slot: "prefix",
											style: { "font-size": "12px" },
										},
									}),
								]
							),

							// , image
							m("img", {
								src: item.prop("supplier_items_image").get(),
								style: {
									"object-fit": "scale-down",
									width: "15%",
									height: "15%",
								},
							}),

							// , quantity
							m(HarthInput, {
								query: item.prop("quantity"),
								postop: updateCheckout,
								type: "number",
								sl: {
									size: "small",
									placeholder: "Qty",
									class: "tightwhite",
									disabled: orderid.get() != "",
								},
							}),

							// , = total
							m(
								".app." + legacyCSS.helper,
								" = " +
									money(
										d.total
											? d.total
											: item.prop("supplier_locations_items_cost").get() *
													item.prop("quantity").get(),
										0
									)
							),

							// , info
							m(
								HarthButton,
								{
									query: info,
									sl: {
										size: "small",
										variant: "default",
										outline: false,
										value: item.get(),
										class: "whitebackground",
									},
								},
								[
									m(HarthIcon, {
										sl: {
											name: "info-circle",
											slot: "prefix",
											style: { "font-size": "18px" },
										},
									}),
								]
							)
						)
					})
				)
			)
		)

	store.prop("orderid").get()

	v.useEffect({ name: "checkout-redraw" }, function* () {
		const { o, b, s } = useStore()
		yield [o, b, s]
		m.redraw()
	})
	return {
		view: () =>
			store.prop("state").get() == "Checkout"
				? m(
						".app.",
						m(
							".app.",
							css`
								& {
									padding-left: 1em;
								}
							`,
							BackButton("", true, "greyButton", "36px"),
							m(
								".app.",
								css`
									& {
										min-height: 1.5em;
										font-weight: bold;
										text-align: left;
										font-size: 2.5em;
										align-content: end;
										border-bottom: 5px solid #f3f6f8;
										padding-bottom: 0.5em;
										padding-right: 1em;
									}
								`,
								"Checkout"
							)
						),
						m(
							".app.",
							css`
								& {
									background-color: white;
								}
							`,
							m(
								".app." + legacyCSS.spreadRowsBigGap,
								css`
									& {
										padding-top: 0.25em;
										padding-right: 1em;
										padding-left: 1em;
									}
								`,
								m(
									".app." + legacyCSS.spreadRows,
									css`
										& ::part(base) {
											border: none;
										}
										& ::part(header) {
											padding: 0;
										}
									`,
									m(
										HarthDetails,
										{
											sl: {
												open: false,
											},
										},

										m(
											".app.",
											{ slot: "summary" },
											m(
												".app.",
												css`
													& {
														align-items: center;
														justify-content: flex-start;
														display: flex;
														grid-template-columns: max-content;
													}
												`,

												m(
													HarthIcon,
													{
														sl: {
															name: "pin-map",
														},
														size: "32px",
													},
													css`
														& {
															padding-right: 0.5em;
														}
													`
												),

												m(
													".app." + legacyCSS.spreadRowsNoGap,
													m(
														".app." + legacyCSS.leftSubHeader,
														deliveryTo
															.get()
															.split(
																store.prop("FormalAddress").get()?.address
																	?.postalCode
															)[0]
													),
													m(
														".app." + legacyCSS.helper,
														[
															store.prop("FormalAddress").get()?.address?.city,
															store.prop("FormalAddress").get()?.address
																?.countryName,
														].join(" ")
													)
												)
											)
										),
										addressInputs(v)
									),

									m(
										HarthDetails,
										{
											sl: {
												open: false,
											},
										},

										m(
											".app.",
											{ slot: "summary" },
											m(
												".app.",
												css`
													& {
														align-items: center;
														justify-content: flex-start;
														display: flex;
														grid-template-columns: max-content;
													}
												`,

												m(
													".app.",
													m(
														HarthIcon,
														{
															sl: {
																name: "door-closed",
															},
															size: "32px",
														},
														css`
															& {
																padding-right: 0.5em;
															}
														`
													)
												),

												m(
													".app." + legacyCSS.spreadRowsNoGap,
													m(
														".app." + legacyCSS.leftSubHeader,
														store.prop("order_instructions").get()
													),
													m(".app." + legacyCSS.helper, "Add instructions")
												)
											)
										),

										m(HarthTextArea, {
											query: store.prop("order_instructions"),
											type: "text",
											sl: {
												placeholder: "",
												size: "small",
											},
										})
									),

									m(
										HarthDetails,
										{
											sl: {
												open: false,
											},
										},

										m(
											".app.",
											{ slot: "summary" },
											m(
												".app.",
												css`
													& {
														align-items: center;
														justify-content: flex-start;
														display: flex;
														grid-template-columns: max-content;
													}
												`,

												m(
													".app.",
													m(
														HarthIcon,
														{
															sl: {
																name: "clipboard2",
															},
															size: "32px",
														},
														css`
															& {
																padding-right: 0.5em;
															}
														`
													)
												),

												m(
													".app." + legacyCSS.spreadRowsNoGap,
													m(
														".app." + legacyCSS.leftSubHeader,
														store.prop("order_description").get()
													),
													m(".app." + legacyCSS.helper, "Add description")
												)
											)
										),

										// special descriptions
										autocomplete(
											v,
											[],
											store.prop("order_description"),
											store.prop("newDescription"),
											"",
											"input",
											"100%",
											false,
											null
										)
									)
								),

								m(
									".app." + legacyCSS.spreadRowsSmallGap,
									css`
										& {
											border-bottom: 5px solid #f3f6f8;
											padding-bottom: 2em;
										}
									`,
									m(
										".app." + legacyCSS.leftHeader,
										css`
											& {
												padding-bottom: 1em;
											}
										`,
										"Delivery options"
									),
									m(".app.", deliveryInputs(v))
								),
								m(
									".app." + legacyCSS.spreadRowsSmallGap,
									css`
										& {
											border-bottom: 5px solid #f3f6f8;
											padding-bottom: 2em;
										}
									`,
									m(
										".app." + legacyCSS.leftHeader,
										css`
											& {
												padding-bottom: 1em;
											}
										`,
										"Items"
									),

									centeredSpinner(
										l.get(),
										"centeredHero",
										m(
											".app." + legacyCSS.spreadRows,
											checkoutindex.get().map((i) => card(i))
										)
									)
								),
								m(
									".app." + legacyCSS.spreadRowsSmallGap,
									m(
										".app." + legacyCSS.spreadRows,
										// !store.prop("user").get().cards
										// || !store.prop("user").get().cards.length
										// ? m(
										// 	HarthButton,
										// 	{
										// 		query: info,
										// 		sl: {
										// 			size: "large",
										// 			variant: "default",
										// 			outline: false,
										// 			value: {},
										// 			href: ''
										// 		},
										// 	},
										// 	"Add Credit/Debit Card"
										// )
										// : m(HarthSelect, {
										// 	query: store.prop("charge"),
										// 	options:
										// 		(store.prop("user").get().cards || []).map((c: any) => c.no),
										// 		// prop("cards")..get(),
										// 	sl: {
										// 		multiple: false,
										// 		placement: "bottom",
										// 		size: "medium",
										// 		hoist: true,
										// 		helpText: "Credit Card",
										// 		clearable: false,
										// 		class: "borderBottom",
										// 	},
										// }),

										m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												"Subtotal"
											),
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												" = " +
													money(
														R.sum(checkoutindex.get().map(R.prop("total"))),
														0
													)
											),
										]),

										m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												"Delivery Fees"
											),
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												" = " +
													money(
														R.sum(
															checkoutindex.get().map(R.prop(timeframe.get()))
														),
														0
													)
											),
										]),

										m(".app." + legacyCSS.spreadColumnsSpaceBetween, [
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												"Service Fees"
											),
											m(
												".app.",
												css`
													& {
														opacity: 0.5;
													}
												`,
												" = " +
													money(
														R.sum(
															checkoutindex.get().map(R.prop("servicefee"))
														),
														0
													)
											),
										]),
										m(
											".app.",
											css`
												& {
													border-bottom: 5px solid #f3f6f8;
													padding-bottom: 1em;
												}
											`,

											m(
												".app." + legacyCSS.spreadColumnsSpaceBetween,
												m(".app.", "Total"),
												m(
													".app.",
													" = " +
														money(
															R.sum(checkoutindex.get().map(R.prop("total"))) +
																R.sum(
																	checkoutindex
																		.get()
																		.map(R.prop(timeframe.get()))
																) +
																R.sum(
																	checkoutindex.get().map(R.prop("servicefee"))
																),
															0
														)
												)
											)
										),

										() =>
											bacta.Stream.merge([
												store.prop("checkoutindex").get as any,
												store.prop("orderid").get as any,
											]).map(([,]) =>
												store.prop("orderid").get() == "processing" &&
												amount.get() > 0 &&
												store.prop("ValidatedAddress").get() != "" &&
												addressError.get() == ""
													? m(
															".app,",
															{
																style: { "min-height": "24em" },
															},
															m(HarthStripe, {
																group_payment_id: (
																	store.prop("checkoutindex").get()[0] || {}
																).group_payment_id,
																error: store.prop("PaymentError").get,
																currency: "aud",
																address: store.prop("FormalAddress").get,
																amount: Number(
																	Number(amount.get() * 10 * 10).toFixed(0)
																),
																writeToLoad,
																makeOrder,
																successfulOrder,
																state: store.prop("state"),
																options: {
																	defaultValues: {
																		billingDetails: {
																			name: contact_name,
																			email: email,
																			phone: phone,
																			address: {
																				line2: address?.address?.district,
																				line1: [
																					address?.address?.houseNumber,
																					address?.address?.street,
																				].join(" "),
																				city: address?.address?.city,
																				state: address?.address?.state,
																				country: address?.address?.countryName,
																				postal_code:
																					address?.address?.postalCode,
																			},
																		},
																	},
																},
																appearance: {
																	theme: "stripe",
																},
															})
													  )
													: null
											),

										() =>
											bacta.Stream.merge([
												store.prop("checkoutindex").get as any,
												store.prop("orderid").get as any,
											]).map(([,]) => {
												const itemcount = checkoutindex
													.get()
													.map((i) => i.items.length)
													.reduce((partialSum, a) => partialSum + a, 0)

												return m(
													".app.",
													css`
														& {
															padding-bottom: 1em;
															padding-top: 1em;
														}
													`,
													m(".app." + legacyCSS.leftHeader, "Summary"),
													m(
														".app.",
														css`
															& {
																padding-bottom: 1em;
																padding-top: 1em;
																font-style: normal;
															}
														`,
														`Delivering `,
														m(
															"i.",
															css`
																& {
																	font-style: normal;
																	font-size: 1em;
																	font-weight: 500;
																}
															`,
															itemcount
														),
														itemcount == 1 ? " item " : " items ",
														"from ",
														m(
															"i.",
															css`
																& {
																	font-style: normal;
																}
															`,
															[checkoutindex.get().map((i) => i.supplier)].join(
																","
															)
														),
														" to "
													),
													m(
														"i.",
														css`
															& {
																font-style: normal;
																font-size: 1em;
																font-weight: 500;
															}
														`,
														deliveryTo.get()
													)
												)
											}),

										() =>
											bacta.Stream.merge([
												store.prop("checkoutindex").get as any,
												store.prop("orderid").get as any,
											]).map(([,]) => {
												let submitBtn = null
												if (!store.prop("orderid").get()) {
													submitBtn = document.getElementById(
														"submit-pay"
													) as HTMLButtonElement

													submitBtn?.removeEventListener("click", () => {})
												}
												return amount.get() > 0 &&
													store.prop("ValidatedAddress").get() != "" &&
													addressError.get() == ""
													? m(
															".app.",
															css`
																& {
																	display: flex;
																	justify-content: center;
																	flex-direction: column;
																	align-items: center;
																}
															`,

															store.prop("orderid").get() != "processing" &&
																store.prop("orderid").get() !=
																	"Payment Attempted"
																? m(
																		HarthButton,
																		{
																			id: "confirm-pay",
																			query: store.prop("orderid"),
																			sl: {
																				size: "large",
																				variant: "default",
																				outline: false,
																				value: "processing",
																				class: "blackbackground",
																			},
																			disabled:
																				store.prop("ValidatedAddress").get() ==
																					"" ||
																				store.prop("orderid").get() !=
																					"processing" ||
																				b.get().every((i) => i.quantity == 0) ||
																				addressError.get() != "",
																		},
																		css`
																			& {
																				display: grid;
																			}
																		`,
																		"Confirm and Pay"
																  )
																: null,

															store.prop("orderid").get() == "processing" ||
																store.prop("orderid").get() ==
																	"Payment Attempted"
																? m(
																		HarthButton,
																		{
																			id: "submit-pay",
																			query: store.prop("paySubmit"),
																			sl: {
																				size: "large",
																				variant: "default",
																				outline: false,
																				value: "Payment Attempted",
																				class: "blackbackground",
																			},
																			disabled:
																				store.prop("ValidatedAddress").get() ==
																					"" ||
																				b.get().every((i) => i.quantity == 0) ||
																				addressError.get() != "",
																		},
																		css`
																			& {
																				display: grid;
																			}
																		`,
																		"Pay " + money(amount.get())
																  )
																: null,

															store.prop("orderid").get() == "processing" ||
																store.prop("orderid").get() ==
																	"Payment Attempted"
																? m(
																		HarthButton,
																		{
																			id: "cancel-pay",
																			query: store.prop("orderid"),
																			sl: {
																				size: "large",
																				variant: "default",
																				outline: false,
																				value: "",
																				class: "blackbackground",
																				disabled:
																					submitBtn &&
																					submitBtn.disabled &&
																					!store.prop("PaymentError").get()
																						? true
																						: false,
																			},
																		},
																		css`
																			& {
																				display: grid;
																			}
																		`,
																		"Cancel Order"
																  )
																: null
													  )
													: null
											}),

										() =>
											store
												.prop("PaymentError")
												.get.map((o) =>
													o
														? m(
																"." + legacyCSS.centeredTitles,
																{ style: { color: "red" } },
																o
														  )
														: null
												)
									),

									m(
										".app.",
										css`
											& ::part(body) {
												padding: 0;
											}
										`,
										m(
											HarthDialog,
											{
												open: info.get().supplier_items_id,
												sl: { noHeader: true },
											},
											m(".app." + legacyCSS.spreadRowsSmallGap, [
												m(
													".app.",
													css`
														& {
															padding: 1em;
														}
													`,
													m(
														HarthButton,
														{
															query: info as bacta.Store<object>,
															sl: {
																size: "small",
																variant: "default",
																outline: false,
																value: {},
																class: "greyButton",
																pill: true,
															},
														},
														m(HarthIcon, {
															sl: {
																name: "arrow-left-short",
																slot: "prefix",
															},
															size: "36px",
														})
													)
												),
												ItemView({
													backbutton: false,
													i: info,
													orderBanner: false,
													v,
												}),
											])
										)
									)
								)
							)
						)
				  )
				: null,
	}
}

async function closeSockets() {
	const { store } = useStore()
	store
		.prop("orders")
		.get()
		.forEach((o) => {
			o.markers?.forEach((m: any) => m.remove())
			o.marker?.remove()
			o.ws ? o.ws.close(1000, "Normal Closed") : null
		})
	return true
}

async function getAddresses() {
	const { d, store } = useStore()

	if (!d.get()) {
		store.prop("nearAddressOptions").update(
			() =>
				[...new Set(store.prop("addressOptions").get())].map((d) => ({
					title: d,
				})) as object[]
		)
	}

	if (
		d.get() != store.prop("ValidatedAddress").get() ||
		(!d.get() &&
			store.prop("ValidatedWebAddress").get().lat &&
			store.prop("ValidatedWebAddress").get().lng)
	) {
		if (!d.get() && store.prop("FormalAddress").get().title) {
			store.prop("ValidatedAddress").update(() => "")
		} else {
			store.prop("AddressError").update(() => "")
			const addresses = await here(
				d.get(),
				store.prop("ValidatedWebAddress").get()
			)

			if (
				!d.get() &&
				store.prop("ValidatedWebAddress").get().lat &&
				store.prop("ValidatedWebAddress").get().lng
			) {
				store
					.prop("addressOptions")
					.update(() =>
						store.prop("addressOptions").get().concat(addresses[0].title)
					)
			}

			!d.get() && addresses.length >= 1
				? store.prop("FormalAddress").update(() => addresses[0] as any)
				: null

			d.get()
				? store.prop("nearAddressOptions").update(() => addresses as object[])
				: null
		}
	}
	return Promise.resolve()
}

async function getPrices() {
	const { store, addressError } = useStore()

	addressError.update(() => "")
	const checkout = (await sherpa({
		endpoint: "quote",
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS
		delivery_address: store
			.prop("ValidatedAddress")
			.get()
			.replace(" Australia", ""),
		items: store.prop("basket").get(),
		paymentid: null,
		group_payment_id: "",
		user_id: store.prop("user").get().user_id,
		deliveryDetails: {
			vehicle: store.prop("vehicle").get(),
		},
	})) as Array<deliveryType>

	checkout.forEach((check) => {
		const timeindex = R.indexBy(
			R.prop("delivery_option"),
			check.delivery_options
		)

		check["Now"] = timeindex["5"] ? timeindex["5"].price : 0
		check["Within2hours"] = timeindex["0"] ? timeindex["0"].price : 0
		check["Within4hours"] = timeindex["1"] ? timeindex["1"].price : 0
		check["SameDay"] = timeindex["2"] ? timeindex["2"].price : 0

		store.prop("vehicle").update(() => String(check.deliveryoption))
		store.prop("vehilceOptions").update(() => check.vehicleoptions)
	})

	const autodescription = checkout.map(R.prop("description"))

	const defaultime = store
		.prop("availableTimeFrames")
		.get()
		.map((af) => af.replaceAll(" ", ""))
		.filter((af) => checkout.every((co: any) => co[af] > 0))[0] as
		| "Now"
		| "Within2hours"
		| "Within4hours"
		| "SameDay"

	const description = store.prop("order_description").get()

	store
		.prop("order_description")
		.update(() => (description ? description : autodescription.join(", ")))

	if (!store.prop("timeframe").get()) {
		store.prop("timeframe").update(() => defaultime)
	}

	store
		.prop("order_contact_name")
		.update(() => store.prop("user").get().name || "")
	store.prop("order_phone").update(() => store.prop("user").get().phone || "")
	store.prop("order_email").update(() => store.prop("user").get().email || "")
	store.prop("checkoutindex").update(() => checkout)

	if (checkout.some((e) => e.error)) {
		const error = R.uniq(checkout.map((e) => e.error))
			.filter(R.identity)
			.join(",")

		if (store.prop("ValidatedAddress").get()) {
			addressError.update(() => error)
		}
	}

	writeToLoad(false)
}

async function successfulOrder() {
	const { store, b } = useStore()
	// clear basket to stop repeat roders
	b.update(() => [])

	// reset items to wipe stored quantities and prevent automatic re-adds to the basket
	const ic = store
		.prop("itemCategories")
		.get()
		.find((ic) => ic.supplier_items_category == store.prop("category").get())

	await getItems(ic)

	await setMetaData(store, store.prop("user").get())

	// reset the order state for new orders to be able to take place
	store.prop("orderid").update(() => "")

	return true
}

async function makeOrder() {
	const { store } = useStore()
	const group_payment_id = store.prop("checkoutindex").get()[0].group_payment_id
	const order = {
		endpoint: "order",
		// NOTE WHEN YOU SEND SHERPA "AUSTRALIA" IN THE ADDRESS, THE ADDRESS VALIDATION IS AMBIGIOUS
		delivery_address: store.prop("deliveryTo").get().replace(" Australia", ""),
		full_delivery_address: store.prop("deliveryTo").get(),
		items: store.prop("basket").get(),
		user_id: store.prop("user").get().user_id,
		group_payment_id: group_payment_id,
		paymentid: null,
		bookdelivery: false,
		currentday: new Date().getDay(),
		deliveryDetails: {
			item_description: store.prop("order_description").get(),
			instructions: store.prop("order_instructions").get(),
			contact_name: store.prop("order_contact_name").get(),
			phone_number: store.prop("order_phone").get(),
			email_address: store.prop("order_email").get(),
			vehicle_id: store.prop("vehicle").get(),
			delivery_option: timeindex[store.prop("timeframe").get()],
			gps: [
				"(",
				store.prop("FormalAddress").get().position.lat,
				",",
				store.prop("FormalAddress").get().position.lng,
				")",
			].join(""),
		},
	}

	return order
}

async function getOrders(orderid?: string | null, routeHome?: boolean) {
	await closeSockets()
	const { store, organization } = useStore()
	const supplierid = organization.get() && organization.get().supplier_id
	const dates = store.prop("orderdates").get()
	const datee = store.prop("orderdatee").get()
	const status = store.prop("status").get()

	// where ((${dates}::timestamp is null or order_eta::timestamp >= ${dates}) and (${datee}::timestamp is null or order_eta <= ${datee}::timestamp))
	// and (( ${supplierid}::uuid is null and (order_status <> 'delivered' or (now() - interval '4 hours' < order_eta) ))
	// or ( ${supplierid}::uuid is not null and (order_status in ('ready for pickup' , 'confirmed',  'finding a driver') or (now() - interval '2 minutes' < order_eta) )))
	const dborders = (await sql`
		With borders as ( 
			select 
				*
				,CAST("sherpa_order" -> 'pickup_address' ->> 'longitude' AS decimal) as pickuplng
				,CAST("sherpa_order" -> 'pickup_address' ->> 'latitude' AS decimal) as pickuplat
				,(supplier_name || ' - ' || ("sherpa_order" -> 'pickup_address' ->> 'validated_address')) as pickupaddress
				,CAST("sherpa_order" -> 'delivery_address' ->> 'longitude' AS decimal) as deliverylng
				,CAST("sherpa_order" -> 'delivery_address' ->> 'latitude' AS decimal) as deliverylat	
				,("sherpa_order" -> 'delivery_address' ->> 'validated_address') as deliveryaddress
				,("sherpa_order" ->> 'deliver_for') as deliverytime				
				,extract('DOW' from CAST("sherpa_order" ->> 'deliver_for' AS timestamp)) as deliveryday
			from app.orders
			left join app.supplier_locations using(supplier_locations_id)		
		)
		,uiorders as (
			select 
				*
				,supplier_items_serial
				,(
					case when deliveryday = 0
					then supplier_locations."supplier_locations_sunday_open"
					when deliveryday = 1
					then supplier_locations."supplier_locations_monday_open"
					when deliveryday = 2
					then supplier_locations."supplier_locations_tuesday_open"
					when deliveryday = 3
					then supplier_locations."supplier_locations_wednesday_open"
					when deliveryday = 4
					then supplier_locations."supplier_locations_thursday_open"
					when deliveryday = 5
					then supplier_locations."supplier_locations_friday_open"
					else supplier_locations."supplier_locations_saturday_open"
					end
				) as earliestpickup
				,(
					case when deliveryday = 0
					then supplier_locations."supplier_locations_sunday_close"
					when deliveryday = 1
					then supplier_locations."supplier_locations_monday_close"
					when deliveryday = 2
					then supplier_locations."supplier_locations_tuesday_close"
					when deliveryday = 3
					then supplier_locations."supplier_locations_wednesday_close"
					when deliveryday = 4
					then supplier_locations."supplier_locations_thursday_close"
					when deliveryday = 5
					then supplier_locations."supplier_locations_friday_close"
					else supplier_locations."supplier_locations_saturday_close"
					end
				) as latestpickup
			from borders
			left join app.supplier_items using(supplier_items_id)
			left join app.supplier_locations using(supplier_locations_id)			
			where (${orderid}::uuid is null or borders.order_id = ${orderid})
			and (${status} = borders.order_status or ${status} = '')
		)
		select 
			delivery_id
			,pickuplng
			,pickuplat
			,deliverylng
			,pickupaddress
			,deliverylat
			,deliveryaddress
			,deliverytime
			,latestpickup
			,earliestpickup
			,order_ready_at
			,order_submission_date
			,order_address as "address"
			,order_eta as "eta"
			,uiorders.supplier_locations_id
			,string_agg( distinct order_gps::text , ', ' ) as "order_gps"
			,order_notes as "instructions"


			,sum( order_cost * order_amount ) as "itemcost"
			,sum( order_cost * order_amount * 0.2 ) as "servicefee"
			,json_agg( uiorders.* ) as "items"
			
			,array_agg( "sherpa_order" ) as "sherpa"
			,string_agg( distinct  "sherpa_order" -> 'delivery_tracking' ->> 'token', ', ' ) as "token"
			
			,sum( distinct cast( "sherpa_order" ->> 'amount' as decimal ) ) as "deliveryfee"
			,string_agg( distinct "sherpa_order" ->> 'item_description', ', ' ) as "description"
			,array_agg( distinct payment_id ) as "payments"
		from uiorders 
		group by 
			delivery_id
			, order_address
			, order_ready_at
			, order_eta
			, order_notes
			, pickuplng
			, pickuplat
			, deliverylng
			, pickupaddress
			, deliverylat
			, deliveryaddress
			, deliverytime
			, order_submission_date
			, latestpickup
			, earliestpickup
			, supplier_locations_id
		order by order_eta DESC
	`) as Array<orderType>

	dborders.forEach((dbo) => {
		dbo.status = dbo.items.every((i) => i.order_status == "unavailable")
			? "unavailable"
			: dbo.items.find((i) => i.order_status != "unavailable")?.order_status ||
			  ""
	})

	if (!orderid) {
		dborders.filter(
			(o) =>
				o.status == "picking up order" ||
				o.status == "order on its way" ||
				o.status == "finding a driver" ||
				o.status == "waiting acceptance"
		).length &&
		store.prop("state").get() != "Home" &&
		routeHome
			? store.prop("state").update(() => "Home")
			: null

		store.prop("orders").update(() => dborders)
		if (routeHome) {
			dborders.forEach((uio) => {
				if (
					organization.get().supplier_id
						? uio.status == "picking up order" ||
						  uio.status == "finding a driver"
						: uio.status == "picking up order" ||
						  uio.status == "order on its way" ||
						  uio.status == "finding a driver" ||
						  uio.status == "waiting acceptance"
				) {
					const order = store.prop("orders").unnest()
						.where`torder => torder.delivery_id == ${uio.delivery_id}`

					const ns = new WebSocket(
						"wss://rtm.sherpa.net.au/cable?token=" + uio.token
					)
					order.prop("ws").update(() => ns)

					// o.ws
					order
						.prop("ws")
						.get()
						.addEventListener("open", () => {
							if (order.prop("ws").get().readyState == 1) {
								order
									.prop("ws")
									.get()
									.send(
										JSON.stringify({
											command: "subscribe",
											identifier: '{"channel":"LocationsChannel"}',
										})
									)
							}
						})

					// o.ws
					order
						.prop("ws")
						.get()
						.addEventListener("message", async (event: any) => {
							const data = JSON.parse(event.data) as { message: gpsType }

							if (data.message) {
								if (data.message.lat && data.message.lng && data.message.eta) {
									const gps = {
										lat: Number(data.message.lat),
										lng: Number(data.message.lng),
										eta: data.message.eta,
										position: data.message.position,
										description: order.prop("description").get(),
										delivery_id: order.prop("delivery_id").get(),
									}

									// console.log("WEBSOCKET MESSAGE", data.message.eta)
									const [status] = await sql`
										select 
											order_status
										from app.orders
										where delivery_id = ${order.prop("delivery_id").get()}
										limit 1
									`

									order.prop("currentgps").update(() => gps)
									order.prop("status").update(() => status.order_status)
									order.prop("eta").update(() => data.message.eta)

									const newarray = store.prop("trackers").get().concat(gps)

									store.prop("trackers").update(() => newarray)

									const rorders = store.prop("orders").get()

									store.prop("orders").update(() => rorders)

									if (status.order_status == "delivered") {
										order.prop("ws").get().close(1000, "Normal Closed")
										order.prop("marker").get().remove()
										order.prop("pickupmarker").get().remove()
										order.prop("deliverymarker").get().remove()
									}
								}
							}
						})
				}
			})
		}
	}
	writeToLoad(false)
	return dborders[0] as any | orderType
}
const typingInterval = 800
const machineInterval = 200
const debounceUpdateSupplier = debounce(updateSupplier, typingInterval)
const debounceUpdateItem = debounce(updateItem, typingInterval)
const debounceAddress = debounce(getAddresses, typingInterval)
const debouncePrices = debounce(getPrices, typingInterval)
const debounceSearchItems = debounce(searchItems, typingInterval)
const debounceOrders = debounce(getOrders, machineInterval)

async function updateItem(
	x: string | number,
	iid: string,
	lid: string,
	item: bacta.Store<itemType>,
	store: any,
	prop: string
) {
	const icon = (
		store
			.prop("categories")
			.get()
			.find(
				(a: any) =>
					a.category_name ==
					store.prop("item").prop("supplier_items_category").get()
			) || {}
	).category_icon

	const [iscurrent] = await sql`
			select * from app.supplier_items
			where app.supplier_items.supplier_items_id = ${iid}
		`

	let newdbitem = {} as any
	let newdblocaitem = {} as any

	if (iscurrent) {
		try {
			;[newdbitem] = await sql`
				update app.supplier_items
					set 
						supplier_items_category = ${store
							.prop("item")
							.prop("supplier_items_category")
							.get()}
						,supplier_items_industry = ${store
							.prop("item")
							.prop("supplier_items_industry")
							.get()}
						,supplier_items_icon = ${icon}

						,supplier_items_serial = ${store
							.prop("item")
							.prop("supplier_items_serial")
							.get()}
						,supplier_items_description = ${store
							.prop("item")
							.prop("supplier_items_description")
							.get()}
						,supplier_items_specifications = ${store
							.prop("item")
							.prop("supplier_items_specifications")
							.get()}
						,supplier_items_weight = ${store
							.prop("item")
							.prop("supplier_items_weight")
							.get()}
						,supplier_items_height = ${store
							.prop("item")
							.prop("supplier_items_height")
							.get()}
						,supplier_items_wdith = ${store.prop("item").prop("supplier_items_wdith").get()}
						,supplier_items_length = ${store
							.prop("item")
							.prop("supplier_items_length")
							.get()}
						,supplier_items_name = ${store.prop("item").prop("supplier_items_name").get()}
						,supplier_items_uom = ${store.prop("item").prop("supplier_items_uom").get()}
						,supplier_items_features = ${store
							.prop("item")
							.prop("supplier_items_features")
							.get()}

				where supplier_items_id = ${iid}
				returning *
			`
		} catch (e) {
			console.log("item patch error")
			newdbitem = { [prop]: x }
		}

		store.prop("updateitem").update(() => "")

		try {
			;[newdblocaitem] = await sql`
				update app.supplier_locations_items
					set 
						supplier_locations_items_stock = ${store
							.prop("item")
							.prop("supplier_locations_items_stock")
							.get()}
						,supplier_locations_items_cost = ${store
							.prop("item")
							.prop("supplier_locations_items_cost")
							.get()}
						,supplier_locations_items_available = ${store
							.prop("item")
							.prop("supplier_locations_items_available")
							.get()}
						,deleted_at = ${store.prop("item").prop("deleted_at").get()}						
				where app.supplier_locations_items.supplier_items_id = ${iid}
				and app.supplier_locations_items.supplier_locations_id = ${lid}
				returning *	
			`

			store.prop("updateitem").update(() => newdblocaitem.supplier_items_id)
		} catch (e) {
			console.log("item patch error")
			newdblocaitem = { [prop]: x }
		}

		const dbitem = { ...newdbitem, ...newdblocaitem }
		store.prop("items").unnest().where`
			(i) =>
				i.supplier_items_id == ${iid} &&
				i.supplier_locations_id == ${lid}
		`.update(() => ({ ...item.get(), ...dbitem }))

		item.update(() => ({ ...item.get(), ...dbitem }))
	} else {
		try {
			;[newdbitem] = await sql`
				insert into app.supplier_items
				(
					"supplier_items_id",
					"supplier_items_name",
					"supplier_items_description",
					"supplier_items_uom",
					"supplier_items_minimum_order",
					"supplier_items_category",
					"supplier_items_industry",
					"supplier_items_icon",
					"supplier_items_image",
					"supplier_items_weight",
					"supplier_items_height",
					"supplier_items_wdith",
					"supplier_items_length",
					"supplier_items_serial",
					"supplier_items_specifications",
					"supplier_items_features",
					"supplier_id"
				)
				values (
					${item.get().supplier_items_id}
					,${item.get().supplier_items_name}
					,${item.get().supplier_items_description}
					,${item.get().supplier_items_uom}
					,${item.get().supplier_items_minimum_order}
					,${item.get().supplier_items_category}
					,${item.get().supplier_items_industry}
					,${icon}
					,${item.get().supplier_items_image}
					,${item.get().supplier_items_weight}
					,${item.get().supplier_items_height}
					,${item.get().supplier_items_wdith}
					,${item.get().supplier_items_length}
					,${item.get().supplier_items_serial}
					,${item.get().supplier_items_specifications}
					,${item.get().supplier_items_features}
					,${item.get().supplier_id}
				)
				returning *
			`
		} catch (e) {
			console.log("item post error")
			newdbitem = { [prop]: x }
		}
		try {
			store.prop("newitem").update(() => "")
			;[newdblocaitem] = await sql`
				insert into app.supplier_locations_items
				(
					"supplier_locations_items_cost",
					"supplier_locations_items_batch",
					"supplier_locations_items_batch_cost",
					"supplier_locations_items_supply_time",
					"supplier_locations_items_available",
					"supplier_locations_items_stock",
					"supplier_locations_id",
					"supplier_items_id"
				)
				values (
					${item.get().supplier_locations_items_cost},
					${item.get().supplier_locations_items_batch},
					${item.get().supplier_locations_items_batch_cost},
					${item.get().supplier_locations_items_supply_time},
					${item.get().supplier_locations_items_available},
					${item.get().supplier_locations_items_stock},
					${item.get().supplier_locations_id},
					${item.get().supplier_items_id}
				)
				returning *
			`
			store.prop("newitem").update(() => newdblocaitem.supplier_items_id)
		} catch (e) {
			console.log("item post error")
			newdblocaitem = { [prop]: x }
		}

		store.prop("items").unnest().where`
		(i) =>
			i.supplier_items_id == ${iid} &&
			i.supplier_locations_id == ${lid}
		`.update(() => ({ ...item.get(), ...newdbitem, ...newdblocaitem }))

		item.update(() => ({ ...item.get(), ...newdbitem, ...newdblocaitem }))
	}
}

async function updateStock(
	x: string | number | Date | null,
	iid: string,
	lid: string,
	item: bacta.Store<itemType>,
	store: any,
	prop: string
) {
	if (
		(item.get().supplier_items_description &&
			item.get().supplier_items_serial &&
			item.get().supplier_items_weight &&
			item.get().supplier_items_height &&
			item.get().supplier_items_wdith &&
			item.get().supplier_items_length &&
			item.get().supplier_items_name &&
			item.get().supplier_items_uom &&
			item.get().supplier_locations_items_cost) ||
		prop == "deleted_at"
	) {
		debounceUpdateItem(x, iid, lid, item, store, prop)
	} else {
		const icon = (
			store
				.prop("categories")
				.get()
				.find(
					(a: any) =>
						a.category_name ==
						store.prop("item").prop("supplier_items_category").get()
				) || {}
		).category_icon

		store.prop("items").unnest().where`
				(i) =>
					i.supplier_items_id == ${iid} &&
					i.supplier_locations_id == ${lid}
			`.update(() => ({ ...item.get(), ...{ [prop]: x, supplier_items_icon: icon } }))

		item.update(() => ({
			...item.get(),
			...{ [prop]: x, supplier_items_icon: icon },
		}))
	}

	m.redraw()
}

async function updateSupplier(
	x: string | number | Date | null,
	sid: string,
	slid: string | null,
	s: bacta.Store<supplierType>,
	l: bacta.Store<any> | null,
	store: any,
	prop: string
) {
	let newdbsupplier = {} as any
	let newdblocation = {} as any

	const locationUpdate = {
		[prop]: x,
		supplier_locations_address: store.prop("deliveryTo").get(),
		supplier_locations_gps: store.prop("FormalAddress").get().position
			? [
					"(",
					store.prop("FormalAddress").get().position.lat,
					",",
					store.prop("FormalAddress").get().position.lng,
					")",
			  ].join("")
			: l && l.get().supplier_locations_gps,
	}
	if (
		l &&
		(prop == "supplier_locations_address" ||
			prop == "supplier_locations_monday_open" ||
			prop == "supplier_locations_tuesday_open" ||
			prop == "supplier_locations_wednesday_open" ||
			prop == "supplier_locations_thursday_open" ||
			prop == "supplier_locations_friday_open" ||
			prop == "supplier_locations_saturday_open" ||
			prop == "supplier_locations_sunday_open" ||
			prop == "supplier_locations_public_holiday_open" ||
			prop == "supplier_locations_monday_close" ||
			prop == "supplier_locations_tuesday_close" ||
			prop == "supplier_locations_wednesday_close" ||
			prop == "supplier_locations_thursday_close" ||
			prop == "supplier_locations_friday_close" ||
			prop == "supplier_locations_saturday_close" ||
			prop == "supplier_locations_sunday_close" ||
			prop == "supplier_locations_public_holiday_close" ||
			prop == "supplier_locations_email" ||
			prop == "supplier_locations_phone" ||
			prop == "supplier_locations_instructions")
	) {
		const [iscurrentlocation] = await sql`
			select * from app.supplier_locations
			where app.supplier_locations.supplier_locations_id = ${slid}
		`

		if (iscurrentlocation) {
			// store.prop("updateitem").update(() => "")
			try {
				;[newdblocation] = await sql`
				update app.supplier_locations
					set 
						supplier_locations_monday_open = ${l
							.prop("supplier_locations_monday_open")
							.get()}
						,supplier_locations_tuesday_open = ${l
							.prop("supplier_locations_tuesday_open")
							.get()}
						,supplier_locations_wednesday_open = ${l
							.prop("supplier_locations_wednesday_open")
							.get()}
						,supplier_locations_thursday_open = ${l
							.prop("supplier_locations_thursday_open")
							.get()}
						,supplier_locations_friday_open = ${l
							.prop("supplier_locations_friday_open")
							.get()}
						,supplier_locations_saturday_open = ${l
							.prop("supplier_locations_saturday_open")
							.get()}
						,supplier_locations_sunday_open = ${l
							.prop("supplier_locations_sunday_open")
							.get()}
						,supplier_locations_public_holiday_open = ${l
							.prop("supplier_locations_public_holiday_open")
							.get()}
						,supplier_locations_monday_close = ${l
							.prop("supplier_locations_monday_close")
							.get()}
						,supplier_locations_tuesday_close = ${l
							.prop("supplier_locations_tuesday_close")
							.get()}
						,supplier_locations_wednesday_close = ${l
							.prop("supplier_locations_wednesday_close")
							.get()}
						,supplier_locations_thursday_close = ${l
							.prop("supplier_locations_thursday_close")
							.get()}
						,supplier_locations_friday_close = ${l
							.prop("supplier_locations_friday_close")
							.get()}
						,supplier_locations_saturday_close = ${l
							.prop("supplier_locations_saturday_close")
							.get()}
						,supplier_locations_sunday_close = ${l
							.prop("supplier_locations_sunday_close")
							.get()}
						,supplier_locations_public_holiday_close = ${l
							.prop("supplier_locations_public_holiday_close")
							.get()}
						,supplier_locations_email = ${l.prop("supplier_locations_email").get()}
						,supplier_locations_phone = ${l.prop("supplier_locations_phone").get()}
						,supplier_locations_gps = ${locationUpdate.supplier_locations_gps}
						,supplier_locations_address = ${locationUpdate.supplier_locations_address}
						,supplier_locations_instructions = ${l
							.prop("supplier_locations_instructions")
							.get()}							
				where supplier_locations_id = ${slid}
				returning *
			`
				// store
				// 	.prop("updateitem")
				// 	.update(() => newdblocation.supplier_locations_id)
			} catch (e) {
				console.log("location post error")
				newdblocation = { [prop]: x }
			}
		} else {
			try {
				;[newdblocation] = await sql`
				insert into app.supplier_locations
				(
					"supplier_locations_id"	
					,"supplier_locations_monday_open"
					,"supplier_locations_tuesday_open"
					,"supplier_locations_wednesday_open"
					,"supplier_locations_thursday_open"
					,"supplier_locations_friday_open"
					,"supplier_locations_saturday_open"
					,"supplier_locations_sunday_open"
					,"supplier_locations_public_holiday_open"
					,"supplier_locations_monday_close"
					,"supplier_locations_tuesday_close"
					,"supplier_locations_wednesday_close"
					,"supplier_locations_thursday_close"
					,"supplier_locations_friday_close"
					,"supplier_locations_saturday_close"
					,"supplier_locations_sunday_close"
					,"supplier_locations_public_holiday_close"
					,"supplier_locations_email"
					,"supplier_locations_phone"
					,"supplier_locations_gps"
					,"supplier_locations_address"
					,"supplier_locations_instructions"
					,"supplier_locations_open"
					,"supplier_id"
				)
				values (
					${l.get().supplier_locations_id}	
					,${l.get().supplier_locations_monday_open}
					,${l.get().supplier_locations_tuesday_open}
					,${l.get().supplier_locations_wednesday_open}
					,${l.get().supplier_locations_thursday_open}
					,${l.get().supplier_locations_friday_open}
					,${l.get().supplier_locations_saturday_open}
					,${l.get().supplier_locations_sunday_open}
					,${l.get().supplier_locations_public_holiday_open}
					,${l.get().supplier_locations_monday_close}
					,${l.get().supplier_locations_tuesday_close}
					,${l.get().supplier_locations_wednesday_close}
					,${l.get().supplier_locations_thursday_close}
					,${l.get().supplier_locations_friday_close}
					,${l.get().supplier_locations_saturday_close}
					,${l.get().supplier_locations_sunday_close}
					,${l.get().supplier_locations_public_holiday_close}
					,${l.get().supplier_locations_email}
					,${l.get().supplier_locations_phone}
					,${locationUpdate.supplier_locations_gps}
					,${l.get().supplier_locations_address}
					,${l.get().supplier_locations_instructions}
					,${l.get().supplier_locations_open}
					,${l.get().supplier_id}					
				)
				returning *
			`
			} catch (e) {
				console.log("location post error")
				newdblocation = { [prop]: x }
			}

			// SELECT ALL ITESM FROM SUPPLIER AND PATCH NEW ITEMS ?
			// try {
			// } catch (e) {
			// 	console.log("new locations item post error")
			// }
		}
		l ? l.update(() => ({ ...l.get(), ...newdblocation })) : null
	}

	const supplierUpdate = {
		[prop]: x,
		organization_details_name: s.prop("supplier_name").get(),
		organization_details_description: s.prop("supplier_description").get(),
		organization_details_abn: s.prop("organization_details_abn").get(),
		organization_details_email:
			s.prop("organization_details_email").get() ||
			l?.prop("supplier_locations_email").get(),
		organization_details_phone:
			s.prop("organization_details_phone").get() ||
			l?.prop("supplier_locations_phone").get(),
		organization_details_address:
			s.prop("organization_details_address").get() ||
			l?.prop("supplier_locations_address").get(),
		organization_details_id: s.prop("organization_details_id").get(),
	}

	if (prop == "supplier_name" || prop == "supplier_description") {
		store.prop("updateitem").update(() => "")

		try {
			;[newdbsupplier] = await sql`
			update app.suppliers
				set 
					supplier_name = ${s.prop("supplier_name").get()}
					,supplier_description = ${s.prop("supplier_description").get()}
			where supplier_id = ${sid}
			returning *
		`
			store.prop("updateitem").update(() => newdbsupplier.supplier_id)
		} catch (e) {
			console.log("supplier patch error")
			newdbsupplier = { [prop]: x }
		}

		s ? s.update(() => ({ ...s.get(), ...newdbsupplier })) : null
	}

	try {
		const [od] = await sql`
			update app.organization_details
				set 
					organization_details_name = ${supplierUpdate.organization_details_name}
					,organization_details_description = ${supplierUpdate.organization_details_description}
					,organization_details_abn = ${supplierUpdate.organization_details_abn}
					,organization_details_email = ${supplierUpdate.organization_details_email}
					,organization_details_phone = ${supplierUpdate.organization_details_phone}
					,organization_details_address = ${supplierUpdate.organization_details_address}
			where organization_details_id = ${supplierUpdate.organization_details_id}
			returning *
		`
		// store.prop("updateitem").update(() => newdbsupplier.supplier_id)
	} catch (e) {
		console.log("organization patch error")
		// newdbsupplier = { [prop]: x }
	}
}

async function updateSupplierLocation(
	x: string | number | Date | null,
	sid: string,
	slid: string | null,
	store: any,
	prop: string
) {
	const s = store.prop("suppliers").unnest().where`
		(i) => i.supplier_id == ${sid}
	`
	const l = s.prop("supplier_locations").unnest().where`
	(ii) => ii.supplier_locations_id == ${slid}`

	if (s.get().supplier_name) {
		debounceUpdateSupplier(x, sid, slid, s, l, store, prop)
	} else {
		const locationUpdate = {
			[prop]: x,
			supplier_locations_address: store.prop("deliveryTo").get(),
			supplier_locations_gps: store.prop("FormalAddress").get().position
				? [
						"(",
						store.prop("FormalAddress").get().position.lat,
						",",
						store.prop("FormalAddress").get().position.lng,
						")",
				  ].join("")
				: l && l.get().supplier_locations_gps,
		}

		const supplierUpdate = {
			[prop]: x,
			organization_details_name: s.prop("supplier_name").get(),
			organization_details_description: s.prop("supplier_description").get(),
			organization_details_abn: s.prop("organization_details_abn").get(),
			organization_details_email:
				s.prop("organization_details_email").get() ||
				l?.prop("supplier_locations_email").get(),
			organization_details_phone:
				s.prop("organization_details_phone").get() ||
				l?.prop("supplier_locations_phone").get(),
			organization_details_address:
				s.prop("organization_details_address").get() ||
				l?.prop("supplier_locations_address").get(),
			organization_details_id: s.prop("organization_details_id").get(),
		}

		l.update(() => ({ ...l.get(), ...locationUpdate }))
		s.update(() => ({ ...s.get(), ...supplierUpdate }))
	}

	m.redraw()
}

function writeToLoad(x: boolean) {
	const { l } = useStore()
	l.get() != x ? l.update(() => x) : null
}
function useScroll(v: bacta.Vnode) {
	v.useEffect({ name: "scrollToTop" }, function* () {
		const { s, o, store, organization } = useStore()
		yield [s, o]
		let ival
		if (s.get() == "Home") {
			getOrders(null, false)
			ival = setInterval(async function () {
				if (organization.get() && organization.get().supplier_id) {
					getOrders(null, false)
				} else {
					const checkOrders = await sql`
							select * from app.orders
							where order_status = 'finding a driver'
						`

					checkOrders
						.filter((o) => o.order_eta)
						.forEach(async (co) => {
							const so = store.prop("orders").unnest()
								.where`torder => torder.delivery_id == ${co.delivery_id}`
							const o = so.get()
							const deliveryframe =
								new Date(o.eta).getTime() -
								new Date(o.order_submission_date).getTime()
							const storeclosed = new Date(
								new Date(
									new Date(
										new Date(
											new Date(o.deliverytime).setHours(
												Number(o.latestpickup.split(":")[0])
											)
										).setMinutes(0)
									).setSeconds(0)
								).setMilliseconds(0)
							).getTime()

							const timelapse =
								new Date().getTime() -
								new Date(o.order_submission_date).getTime()
							const cancel =
								timelapse > deliveryframe / 4 ||
								new Date().getTime() > storeclosed
							// CANCEL SHERPA IF TOO MUCH TIME HAS ELAPSED OR STORE IS CLOSED
							// MOVE TO PGBOSS
							if (cancel) {
								// CANCEL Sherpa
								await sherpa({
									endpoint: "cancel",
									bookingid: o.delivery_id,
								})
								o.payments.map(async (p) =>
									p
										? await stripeCharge({
												delivery_id: o.delivery_id,
												id: p,
												cancel: true,
										  })
												.then((r: any) => r)
												.catch((e: any) => console.log(e))
										: null
								)
							}
						})
				}
			}, 10000)
		} else {
			clearInterval(ival)
		}

		const rendero = {
			// "confirm-pay": false,
			// "submit-pay": false,
			"cancel-pay": false,
		}

		setTimeout(() => {
			Object.keys(rendero).map((k) => {
				type renders =
					// | "confirm-pay"
					// | "submit-pay"
					"cancel-pay"
				const element = document.getElementById(k || "")
				if (
					element &&
					// && !rendero[k as renders]
					o.get()
				) {
					element?.scrollIntoView({
						behavior: "smooth",
						block: "start",
					})
					rendero[k as renders] = true
				}
			})
		}, 300)

		if (s.get()) {
			window.scrollTo(0, 0)
		}
	})
}

function useAddress(v: bacta.Vnode) {
	v.useEffect({ name: "useAddress" }, function* () {
		const { d, ValidatedWebAddress } = useStore()
		yield [d, ValidatedWebAddress]

		d.get() || (ValidatedWebAddress.get().lat && ValidatedWebAddress.get().lng)
			? debounceAddress()
			: []
	})
}

function setAddress(v: bacta.Vnode) {
	v.useEffect({ name: "setaddress" }, function* () {
		const { ValidatedAddress, d, FormalAddress, store } = useStore()
		yield FormalAddress

		if (FormalAddress.get().title) {
			d.update(() => FormalAddress.get().title)
			if (
				FormalAddress.get().resultType == "houseNumber" ||
				FormalAddress.get().resultType == "place"
			) {
				ValidatedAddress.update(() => FormalAddress.get().title)
				store.prop("nearAddressOptions").update(() => [])
				store
					.prop("addressOptions")
					.update(() =>
						[FormalAddress.get().title].concat(
							store.prop("addressOptions").get()
						)
					)
			}
		}
	})
}

function useSearchItems(v: bacta.Vnode) {
	v.useEffect({ name: useSearchItems.name }, function* () {
		const {
			store,
			categorySearch: s,
			category: c,
			name: n,
			items_name: iname,
			items_description: description,
			items_weight_min: weight_min,
			items_height_min: height_min,
			items_wdith_min: wdith_min,
			items_length_min: length_min,
			items_weight_max: weight_max,
			items_height_max: height_max,
			items_wdith_max: wdith_max,
			items_length_max: length_max,
			items_category: category,
			items_industry: industry,
			items_specifications: specifications,
			items_features: features,
			locations_items_cost_min: items_cost_min,
			locations_items_cost_max: items_cost_max,
			locations_items_available: items_available,
			locations_address: address,
			ValidatedWebAddress: vadd,
			organization,
		} = useStore()

		yield [
			n,
			s,
			c,
			iname,
			description,
			weight_min,
			height_min,
			wdith_min,
			length_min,
			weight_max,
			height_max,
			wdith_max,
			length_max,
			category,
			industry,
			specifications,
			features,
			items_cost_min,
			items_cost_max,
			items_available,
			address,
			vadd,
			organization,
		]

		writeToLoad(true)
		debounceSearchItems(store, yield s)
	})
}

// function useMap(v: bacta.Vnode) {
// 	v.useEffect({ name: useMap.name }, function* () {
// 		const { map, store, organization } = useStore()

// 		const mapp = yield map
// 		const intital = (store.prop("orders").get() || []).filter((uio) =>
// 			organization.get().supplier_id
// 				? uio.status == "picking up order" || uio.status == "finding a driver"
// 				: uio.status == "picking up order" ||
// 				  uio.status == "order on its way" ||
// 				  uio.status == "finding a driver" ||
// 				  uio.status == "waiting acceptance"
// 		)

// 		if (!intital.length && mapp.fitBounds) {
// 			mapp.locate({
// 				setView: true,
// 				maxZoom: 18,
// 			})
// 		} else if (store.prop("state").get() == "Home") {
// 			const items = intital
// 				.map((uio) => {
// 					const deliverygps = uio.deliverylat
// 						? [Number(uio.deliverylat), Number(uio.deliverylng)]
// 						: (uio.order_gps
// 								.replaceAll("(", "")
// 								.replaceAll(")", "")
// 								.split(",")
// 								.map((p) => Number(p)) as any)
// 					const pickupgps = uio.pickuplat
// 						? [Number(uio.pickuplat), Number(uio.pickuplng)]
// 						: (uio?.items[0]?.supplier_locations_gps
// 								.replaceAll("(", "")
// 								.replaceAll(")", "")
// 								.split(",")
// 								.map((p) => Number(p)) as any)
// 					if (mapp.fitBounds) {
// 						const deliverymarker = organization.get().supplier_id
// 							? null
// 							: L.marker(deliverygps, {
// 									// icon: craneMarker,
// 									icon: LocationMarker,
// 							  })
// 									.addTo(mapp)
// 									.bindPopup(uio.deliveryaddress)

// 						const pickupmarker = L.marker(pickupgps, {
// 							icon: dotMarker,
// 							// icon: toolsMarker,
// 						})
// 							.addTo(mapp)
// 							.bindPopup(uio.pickupaddress)

// 						const order = store.prop("orders").unnest()
// 							.where`torder => torder.delivery_id == ${uio.delivery_id}`

// 						order.prop("pickupmarker").update(() => pickupmarker)
// 						order.prop("deliverymarker").update(() => deliverymarker)

// 						mapp.setView(pickupgps, 17, {
// 							animate: true,
// 							maxZoom: 17,
// 						})

// 						// const samepoint =
// 						// 	uio.deliverylat == uio.pickuplat &&
// 						// 	uio.deliverylng == uio.pickuplng
// 						// if (!samepoint) {
// 						// 	mapp.fitBounds([deliverygps, pickupgps], {
// 						// 		animate: true,
// 						// 		padding: [25, 25],
// 						// 		maxZoom: 17,
// 						// 	})
// 						// }
// 					}
// 					return [deliverygps, pickupgps]
// 				})
// 				.flat(1)
// 			console.log("PRINTING MARKER")
// 			if (
// 				!(
// 					organization.get() &&
// 					organization.get().supplier_id &&
// 					store.prop("detailsOpen").get()
// 				) &&
// 				items.length >= 2 &&
// 				items.every(
// 					(a) => a.length >= 2 && a[0] && a[1] && !isNaN(a[0]) && !isNaN(a[1])
// 				)
// 			) {
// 				console.log("FLYING", R.uniq(items))
// 				mapp.flyToBounds(R.uniq(items), {
// 					animate: true,
// 					padding: [32, 32],
// 					maxZoom: 17,
// 					duration: 2,
// 				})
// 			}
// 		}
// 	})
// }

function useAddressMap(v: bacta.Vnode) {
	v.useEffect({ name: "map-update" }, function* () {
		const { store, l, s, organization } = useStore()
		const map$ = store.prop("map")
		const [map, FormalAddress, loading] = (yield [
			map$,
			store.prop("FormalAddress"),
			l,
			s,
		]) as [Map, FormalAddress, any]
		const icon = dotMarker
		store
			.prop("deliveryToMarker")
			.get()
			?.forEach((marker: any) => marker.remove())
		// L.icon({
		// 	iconUrl: "/marker-icon.png",
		// 	shadowUrl: "/marker-shadow.png",
		// })

		if (
			FormalAddress &&
			!loading &&
			(s.get() == "Checkout" ||
				store.prop("detailsOpen").get() ||
				(s.get() == "Home" &&
					store.prop("orders").get().length > 0 &&
					store
						.prop("orders")
						.get()
						.some((o) =>
							organization.get().supplier_id
								? o.status == "picking up order" ||
								  o.status == "finding a driver" ||
								  o.status == "waiting acceptance"
								: o.status == "picking up order" ||
								  o.status == "order on its way" ||
								  o.status == "finding a driver" ||
								  o.status == "waiting acceptance"
						)))
			// .every((o) => o.status == "waiting acceptance")
		) {
			let destinationpoints = [] as any
			let itempoints = [] as any
			map.invalidateSize && map.invalidateSize()
			if (map.setView) {
				const items = [
					...new Set(
						(s.get() == "Checkout"
							? store.prop("basket").get()
							: s.get() == "Home" && !store.prop("detailsOpen").get()
							? store.prop("orders").get()
							: []
						).filter(
							(a: any) =>
								// a?.supplier_locations_gps ||
								a?.items?.[0]?.supplier_locations_gps
						)
					),
				]

				const current = store
					.prop("orders")
					.get()
					.filter((o) => o?.currentgps?.lat)
					.map((o) => [Number(o?.currentgps?.lat), Number(o?.currentgps?.lng)])

				const destinations = (
					s.get() == "Home" && !organization.get().supplier_id
						? store.prop("orders").get()
						: []
				)
					.filter((a) => (a.deliverylat && a.deliverylng) || a.order_gps)
					.concat(
						FormalAddress.position &&
							FormalAddress.position.lat &&
							FormalAddress.position.lng &&
							((!organization.get().supplier_id && s.get() == "Checkout") ||
								store.prop("detailsOpen").get())
							? [
									{
										deliverylat: FormalAddress.position.lat,
										deliverylng: FormalAddress.position.lng,
									} as any,
							  ]
							: []
					)

				const mapmarkers = items
					.map((dp: any) => {
						const order = store.prop("orders").unnest()
							.where`torder => torder.delivery_id == ${dp.delivery_id}`

						const point = dp.pickuplat
							? [Number(dp.pickuplat), Number(dp.pickuplng)]
							: ((
									dp?.supplier_locations_gps ||
									dp?.items[0]?.supplier_locations_gps
							  )

									.replaceAll("(", "")
									.replaceAll(")", "")
									.split(",")
									.map((p: any) => Number(p)) as any)

						itempoints.push(point)
						const marker = L.marker(point, {
							icon: organization.get().supplier_id ? LocationMarker : icon,
						})
							.addTo(map)
							.bindPopup(dp.pickupaddress)

						order.prop("pickupmarker").update(() => marker)
						return marker
					})
					.concat(
						destinations.map((dp) => {
							const order = store.prop("orders").unnest()
								.where`torder => torder.delivery_id == ${dp.delivery_id}`

							const point = dp.deliverylat
								? [Number(dp.deliverylat), Number(dp.deliverylng)]
								: (dp.order_gps
										.replaceAll("(", "")
										.replaceAll(")", "")
										.split(",")
										.map((p) => Number(p)) as any)

							destinationpoints.push(point)
							const marker = L.marker(point, {
								icon: LocationMarker,
							})
								.addTo(map)
								.bindPopup(dp.deliveryaddress)
							order?.prop("deliverymarker").update(() => marker)

							return marker
						})
					)

				store.prop("deliveryToMarker").update(() => mapmarkers)
				const mappoints = itempoints.concat(
					destinationpoints,
					store.prop("detailsOpen").get() ? [] : current
				)

				if (
					FormalAddress.position &&
					FormalAddress.position.lat &&
					FormalAddress.position.lng
				) {
					map.setView(
						[
							Number(FormalAddress.position.lat),
							Number(FormalAddress.position.lng),
						],
						16,
						{
							animate: true,
						}
					)
				} else if (destinations.length) {
					map.setView(destinationpoints[0] as any, 16, {
						animate: true,
					})
				} else if (items.length) {
					map.setView(itempoints[0] as any, 16, {
						animate: true,
					})
				}

				if (
					// !(
					// 	organization.get() &&
					// 	organization.get().supplier_id &&
					// 	store.prop("detailsOpen").get()
					// ) &&
					mappoints.length >= 2 &&
					mappoints.every(
						(a: any) =>
							a.length >= 2 && a[0] && a[1] && !isNaN(a[0]) && !isNaN(a[1])
					)
				) {
					try {
						map.flyToBounds(mappoints as [[number, number]], {
							animate: true,
							padding: [32, 32],
							maxZoom: 17,
							duration: 2,
						})
					} catch (e) {
						console.log(e)
					}
				}
			}
			m.redraw()
		}
	})
}

function useMapMarkers(v: bacta.Vnode) {
	v.useEffect({ name: useMapMarkers.name }, function* () {
		const { store, t, organization, s } = useStore()
		;(yield t) as State["trackers"]
		const map = store.prop("map").get()
		if (s.get() == "Home") {
			;(store.prop("orders").get() || []).forEach((uio) => {
				const order = store.prop("orders").unnest()
					.where`torder => torder.delivery_id == ${uio.delivery_id}`

				if (uio.prevgps) {
					uio.marker.remove()

					const marker = L.marker([uio.prevgps.lat, uio.prevgps.lng], {
						icon: dotMarker,
					})
						.addTo(map)
						.bindPopup(
							uio.status == "Order on its way"
								? uio.description + " on the way"
								: "Driver picking up " + uio.description
						)

					order
						.prop("markers")
						.update(() => (order.prop("markers").get() || []).concat(marker))
				}
				if (uio.currentgps) {
					const marker = L.marker([uio.currentgps.lat, uio.currentgps.lng], {
						icon: outlineddotMarker,
					})
						.addTo(map)
						.bindPopup("Driver picking up " + uio.description)

					order.prop("marker").update(() => marker)
					order.prop("prevgps").update(() => uio.currentgps)

					if (
						!(
							organization.get() &&
							organization.get().supplier_id &&
							store.prop("detailsOpen").get()
						)
					) {
						map.fitBounds(
							[
								[Number(uio.pickuplat), Number(uio.pickuplng)],
								[Number(uio.deliverylat), Number(uio.deliverylng)],
								[Number(uio.currentgps.lat), Number(uio.currentgps.lng)],
							],
							{ padding: [25, 25] }
						)
					}
				}
			})
		}
	})
}

function usePricingAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: usePricingAutoTab.name }, function* () {
		const { s, b, d, ValidatedAddress, store } = useStore()

		const [state, basket, deliveryto, validatedto, timeframe, vehicle] =
			(yield [
				s,
				b,
				d,
				ValidatedAddress,
				store.prop("timeframe"),
				store.prop("vehicle"),
			]) as [
				State["state"],
				State["basket"],
				State["deliveryTo"],
				State["ValidatedAddress"],
				State["timeframe"],
				State["vehicle"]
			]

		const tab = document.querySelector("sl-tab-group")
		if (
			tab &&
			state == "Checkout"
			// && d.get() == ValidatedAddress.get()
		) {
			tab.show("Checkout")
			if (d.get()) {
				writeToLoad(true)
				debouncePrices()
			}
		} else if (tab && state == "Search") {
			tab.show("Search")
		}
	})
}

function useOrdersAutoTab(v: bacta.Vnode) {
	v.useEffect({ name: useOrdersAutoTab.name }, function* () {
		const { s, o } = useStore()
		const state = (yield s) as ReturnType<typeof s.get>
		const order = (yield o) as ReturnType<typeof o.get>
		const tab = document.querySelector("sl-tab-group")
		if (tab && state == "Home" && order == "") {
			tab.show("Home")
			writeToLoad(true)
			debounceOrders()
		}
	})
}

function showloading(v: bacta.Vnode) {
	v.useEffect({ name: "loading" }, function* () {
		const { l } = useStore()

		yield l
		m.redraw()
	})
}
function StockView(v: bacta.Vnode) {
	const { store } = useStore()
	const item = store.prop("item")
	const stock = store.prop("item").prop("supplier_locations_items_stock")

	return m(
		".app." + legacyCSS.spreadColumnsSpaceAround,
		m(
			".app.",
			{ style: { "align-items": "flex-end", "font-size": "22px" } },
			"Stock"
		),
		m(
			".app.",
			css`
				& {
					display: flex;
					grid-template-columns: max-content;
					justify-content: center;
					align-items: center;
					padding-left: 0em;
					padding-top: 0em;
					padding-bottom: 0em;
					max-width: 60%;
					justify-content: flex-end;
				}
			`,
			m(
				".app.",
				css`
					& ::part(base) {
						border-radius: 5em 0em 0em 5em;
						background-color: #e9ecef;
						min-height: 3em;
					}
				`,
				m(
					HarthButton,
					{
						postop: () =>
							updateStock(
								(stock.get() || 0) - 1,
								item.get().supplier_items_id,
								item.get().supplier_locations_id,
								item,
								store,
								"supplier_locations_items_stock"
							),
						sl: {
							size: "large",
							variant: "default",
							outline: true,
							value: stock.get() - 1,
							class: "greyButton",
							disabled: stock.get() == 0,
						},
					},
					[
						m(HarthIcon, {
							sl: {
								name: "dash-lg",
								slot: "prefix",
								style: { "font-size": "18px" },
							},
						}),
					]
				)
			),
			m(
				".app.",
				css`
					& {
						background-color: #f3f6f8;
						height: 3em;
						max-width: 60%;
						padding: 0em;
					}
				`,
				m(HarthInput, {
					query: stock as any,
					type: "number",
					postop: () =>
						updateStock(
							stock.get() || 0,
							item.get().supplier_items_id,
							item.get().supplier_locations_id,
							item,
							store,
							"supplier_locations_items_stock"
						),
					sl: {
						type: "number",
						inputmode: "numeric",
						noSpinButtons: false,
						min: 0,
						// step: 1,
						clearable: false,
						placeholder: "",
						class: "greyButton",
					},
				})
			),
			m(
				".app.",
				css`
					& ::part(base) {
						border-radius: 0em 5em 5em 0em;
						background-color: #e9ecef;
						min-height: 3em;
					}
				`,
				m(
					HarthButton,
					{
						postop: () =>
							updateStock(
								(stock.get() || 0) + 1,
								item.get().supplier_items_id,
								item.get().supplier_locations_id,
								item,
								store,
								"supplier_locations_items_stock"
							),
						sl: {
							size: "large",
							variant: "default",
							outline: false,
							value: stock.get() + 1,
							class: "greyButton",
						},
					},
					[
						m(HarthIcon, {
							sl: {
								name: "plus-lg",
								slot: "suffix",
								style: { "font-size": "18px" },
							},
						}),
					]
				)
			)
		)
	)
}

function OrderView(i: bacta.Store<itemType | any>) {
	const { store } = useStore()
	return m(
		".app.",
		css`
			& {
				margin: 1em;
			}
		`,
		m(
			".app." + legacyCSS.spreadColumnsSpaceBetween,
			m(".app." + legacyCSS.leftHeader, "Quantity"),

			m(
				".app.",
				css`
					& {
						display: flex;
						grid-template-columns: max-content;
						justify-content: center;
						align-items: center;
						padding: 0em;
						max-width: 60%;
						justify-content: flex-end;
					}
				`,

				m(
					".app.",
					css`
						& ::part(base) {
							border-radius: 5em 0em 0em 5em;
							background-color: #f3f6f8;
							min-height: 3em;
						}
					`,
					m(
						HarthButton,
						{
							query: i.prop("qtyinput"),
							// postop: updateCheckout,
							sl: {
								size: "large",
								variant: "default",
								outline: true,
								value: i.prop("qtyinput").get() - 1,
								class: "greyButton",
								disabled: i.prop("qtyinput").get() == 0,
							},
						},
						[
							m(HarthIcon, {
								sl: {
									name: "dash-lg",
									slot: "prefix",
									style: { "font-size": "24px" },
								},
							}),
						]
					)
				),
				m(
					".app.",
					css`
						& {
							background-color: #f3f6f8;
							height: 3em;
							max-width: 60%;
							padding: 0em;
						}
					`,
					m(HarthInput, {
						query: i.prop("qtyinput"),
						type: "number",
						sl: {
							type: "number",
							inputmode: "numeric",
							noSpinButtons: false,
							min: 0,
							step: 1,
							clearable: false,
							placeholder: "",
							class: "greyButton",
						},
					})
				),
				m(
					".app.",
					css`
						& ::part(base) {
							border-radius: 0em 5em 5em 0em;
							background-color: #f3f6f8;
							min-height: 3em;
						}
					`,
					m(
						HarthButton,
						{
							query: i.prop("qtyinput"),
							sl: {
								size: "large",
								variant: "default",
								outline: false,
								value: i.prop("qtyinput").get() + 1,
								class: "greyButton",
								disabled:
									i.prop("qtyinput").get() ==
									i.prop("supplier_locations_items_stock").get(),
							},
						},
						[
							m(HarthIcon, {
								sl: {
									name: "plus-lg",
									slot: "suffix",
									style: { "font-size": "24px" },
								},
							}),
						]
					)
				)
			)
		),
		i.get().storestatus == "Closed" ||
			!i.prop("supplier_locations_items_available").get() ||
			i.prop("supplier_locations_items_stock").get() == 0 ||
			(i.prop("qtyinput").get() &&
				i.prop("quantity").get() &&
				i.prop("qtyinput").get() == i.prop("quantity").get())
			? m(
					".app." + legacyCSS.centeredTitles,
					css`
						& {
							padding-top: 2em;
						}
					`,
					i.get().storestatus == "Closed"
						? "This store is currently closed"
						: !i.prop("supplier_locations_items_available").get() ||
						  i.prop("supplier_locations_items_stock").get() == 0
						? "This item is currently unavailable from this store"
						: i.prop("qtyinput").get() == i.prop("quantity").get()
						? "Items already added to cart"
						: ""
			  )
			: null,
		m(
			".app.",
			css`
				& {
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					margin-top: 3em;
				}
			`,

			m(
				HarthButton,
				{
					query: i.prop("quantity"),
					postop: updateCheckout,
					sl: {
						size: "large",
						variant: "default",
						outline: true,
						value: i.prop("qtyinput").get(),
						class: "blackbackground",
						disabled:
							i.prop("qtyinput").get() < 0 ||
							i.prop("qtyinput").get() == i.prop("quantity").get() ||
							i.get().storestatus == "Closed" ||
							!i.prop("supplier_locations_items_available").get(),
						pill: false,
					},
				},
				[
					!i.prop("quantity").get() ? "Add to Cart" : "Update Cart",
					"\u2022",
					money(i.get().supplier_locations_items_cost, 0),
					"\u00D7",
					i.prop("qtyinput").get(),
				].join("  ")
			),

			!store.prop("basket").get().length
				? null
				: m(
						HarthButton,
						{
							query: store.prop("state"),
							sl: {
								size: "large",
								variant: "default",
								outline: true,
								value: "Checkout",
								class: "blackbackground",
								disabled: i.prop("quantity").get() <= 0,
								pill: false,
							},
						},
						["Checkout"].join("  ")
				  )
		)
	)
}

function filterButton({
	notext = false,
	slot = "suffix",
	query,
	value,
	buttonclass = "",
}: {
	notext: boolean
	slot: string
	query: any
	value: boolean
	buttonclass: string
}) {
	return m(
		".app.",
		{
			slot,
		},
		m(
			HarthButton,
			{
				query: query,
				sl: {
					size: "medium",
					class: buttonclass,
					variant: "default",
					outline: false,
					value: value,
				},
			},
			[
				m(HarthIcon, {
					sl: {
						name: "sliders",
						slot: "prefix",
						style: {
							"font-size": "24px",
							color: "black",
						},
					},
				}),
			]
		)
	)
}

function BackButton(
	title: string,
	pill = false,
	cl = "whitebackground",
	size = "18px"
) {
	const { store, s, ps } = useStore()
	const item = store.prop("item")
	const category = store.prop("category")
	const supplier = store.prop("supplier_name")
	const item_id = store.prop("item").get().supplier_items_id
	return !item.get().supplier_items_id && !category.get() && !supplier.get()
		? null
		: m(
				HarthButton,
				{
					query:
						s.get() == "Checkout"
							? s
							: item.get().supplier_items_id
							? (item as bacta.Store<object>)
							: category.get()
							? (category as bacta.Store<string>)
							: (supplier as bacta.Store<string>),
					postop: () => {
						store.prop("items").update(() =>
							store
								.prop("items")
								.get()
								.filter((i) => !i.deleted_at)
						)
					},
					sl: {
						size: title ? "large" : "small",
						variant: "default",
						outline: false,
						value:
							s.get() == "Checkout"
								? "Search"
								: item.get().supplier_items_id
								? {}
								: "",
						class: cl,
						pill,
					},
				},
				[
					title,
					m(HarthIcon, {
						sl: {
							name: title ? "x-lg" : "arrow-left-short",
							slot: title ? "suffix" : "prefix",
						},
						style: {
							[title ? "color" : ""]: "black",
						},
						size,
					}),
				]
		  )
}

async function newItem() {
	const { store, itemSearch } = useStore()

	writeToLoad(true)

	const supplier = store.prop("suppliers").get()[0]
	const location = supplier.supplier_locations[0]
	const item = {
		supplier_locations_gps: location?.supplier_locations_gps,
		supplier_locations_address: location?.supplier_locations_address,
		supplier_locations_instructions: location?.supplier_locations_instructions,

		supplier_items_id: crypto.randomUUID(),
		supplier_locations_id: location?.supplier_locations_id,
		supplier_id: supplier.supplier_id,
		supplier_name: supplier.supplier_name,

		supplier_items_name: null,
		supplier_items_description: null,
		supplier_items_uom: null,

		// ENABLE EDITS LATER
		supplier_items_minimum_order: null,
		supplier_locations_items_batch: null,
		supplier_locations_items_batch_cost: null,
		supplier_locations_items_supply_time: null,
		// ENABLE EDITS LATER

		supplier_locations_items_available: true,
		supplier_items_category: null,
		supplier_items_industry: null,
		supplier_items_icon: null,

		suppliesr_items_image: null,
		supplier_items_weight: null,
		supplier_items_height: null,
		supplier_items_wdith: null,
		supplier_items_length: null,
		supplier_items_serial: null,
		supplier_items_specifications: null,
		supplier_items_features: [],

		supplier_locations_items_cost: null,
		supplier_locations_items_stock: 0,
	}

	store.prop("items").update(() =>
		store
			.prop("items")
			.get()
			.concat(item as unknown as itemType)
	)
	store.prop("item").update(() => item)

	writeToLoad(false)
}

async function getItems(ic?: {
	supplier_items_category?: any
	supplier_items_industry?: string
	supplier_items_icon?: any
	supplier_name?: string
	supplier_id?: string
	organization_details_logo_url?: string
}) {
	const { store, itemSearch } = useStore()
	writeToLoad(true)

	const category = store.prop("category")
	const headericon = store.prop("headerIcon")

	if (ic && ic.supplier_items_category) {
		category.update(() => ic.supplier_items_category)
		headericon.update(() => ic.supplier_items_icon)
	}

	if (ic && ic.supplier_name) {
		store.prop("supplier_name").update(() => ic.supplier_name as string)
	}

	searchItems(store, itemSearch.get())
}

function finditem() {
	const { store } = useStore()
	const b = store.prop("item").get()
	const a = store.prop("items").unnest().where`
		(i) =>
			i.supplier_items_id == ${b.supplier_items_id} &&
			i.supplier_locations_id == ${b.supplier_locations_id}
	`
	return a
}

function ItemView({
	backbutton = true,
	i = null as bacta.Store<itemType> | null,
	orderBanner = true,
	v = null as any,
}) {
	const { l, organization, store } = useStore()
	const item = i || finditem()
	const editable = organization.get().supplier_id
	store.prop("uppy").set(
		m(HarthUppy, {
			getFields: () => ({
				organization_id: organization.get().organization_id,
				supplier_items_id: store.prop("item").get().supplier_items_id,
				user_id: store.prop("user").get().user_id,
				email: store.prop("user").get().email,
				store: "supplier_items_image",
			}),
			query: store.prop("files"),
			store,
			v,
		})
	)

	v.useEffect({ name: "photo-counts" }, function* () {
		yield [store.prop("files")]

		m.redraw()
	})

	const photoDraw = m(
		".app.",
		m(HarthDrawer, {
			query: store.prop("photoOpen"),
			sl: {
				placement: "start",
				contained: true,
				noHeader: true,
			},
			children: [
				m(
					".app." + legacyCSS.spreadRowsBigGap,

					m(
						".app.",
						m(
							HarthButton,
							{
								query: store.prop("photoOpen"),
								sl: {
									size: "small",
									variant: "default",
									outline: false,
									value: false,
									class: "greyButton",
									pill: true,
								},
							},
							[
								m(HarthIcon, {
									sl: {
										name: "arrow-left-short",
										slot: "prefix",
									},
									size: "36px",
								}),
							]
						)
					),

					centeredSpinner(
						l.get(),
						"centeredHero",
						m(
							".app." + legacyCSS.spreadRowsBigGap,
							m(".app." + legacyCSS.centeredHeader, "Upload Photos"),
							store.prop("uppy").get(),
							store.prop("user").get().email == "emmanuel@harth.io"
								? m(
										".app.",
										m(
											HarthButton,
											{
												postop: async () => {
													await m
														.request<any[]>("/api/ai/item-search", {
															method: "POST",
															body: {
																url: store.prop("item").get()
																	.supplier_items_image,
																code: store.prop("item").get().serial,
															},
														})
														.then(
															(res: any) => {
																store.prop("aiproduct").update(() => res)
																return res
															},
															(e: any) => {
																console.log(e.response)
																return e.response.error == "Unauthorized call"
															}
														)
												},
												sl: {
													size: "small",
													variant: "default",
													outline: false,
													value: false,
													class: "greyButton",
													pill: true,
												},
											},
											[
												m(HarthIcon, {
													sl: {
														name: "search",
														slot: "prefix",
													},
													size: "36px",
												}),
												"Find product",
											]
										),
										m(
											HarthButton,
											{
												postop: async () => {
													await m
														.request<any[]>("/api/ai/catalogue", {
															method: "POST",
															body: {
																items: [store.prop("aiproduct").get()],
																email: store.prop("user").get().email,
															},
														})
														.then(
															(res: any) => {
																// GET NEW CATEGORIES AND THEN

																const i = store.prop("aiproduct").get()
																const current = store.prop("item").get()
																store
																	.prop("item")
																	.prop("supplier_items_name")
																	.update(
																		() =>
																			current.supplier_items_name ||
																			i.productName
																	)

																store
																	.prop("item")
																	.prop("supplier_items_description")
																	.update(
																		() =>
																			current.supplier_items_description ||
																			i.description
																	)
																store
																	.prop("item")
																	.prop("supplier_items_uom")
																	.update(
																		() =>
																			current.supplier_items_uom ||
																			i.unitOfMeasure
																	)
																store
																	.prop("item")
																	.prop("supplier_items_minimum_order")
																	.update(
																		() =>
																			current.supplier_items_minimum_order || 1
																	)
																store
																	.prop("item")
																	.prop("supplier_id")
																	.update(() => null)
																store
																	.prop("item")
																	.prop("supplier_items_image")
																	.update(
																		() =>
																			current.supplier_items_image ||
																			i.items_image
																	)
																store
																	.prop("item")
																	.prop("supplier_items_weight")
																	.update(
																		() =>
																			current.supplier_items_weight ||
																			i.weight ||
																			i.dimensions?.weight ||
																			i["Approximated Weight"] ||
																			i["ApproximatedWeight"] ||
																			i["Approximated_Weight"]
																	)
																store
																	.prop("item")
																	.prop("supplier_items_height")
																	.update(
																		() =>
																			current.supplier_items_height ||
																			i.height ||
																			i.dimensions?.height
																	)
																store
																	.prop("item")
																	.prop("supplier_items_wdith")
																	.update(
																		() =>
																			current.supplier_items_wdith ||
																			i.width ||
																			i.dimensions?.width
																	)
																store
																	.prop("item")
																	.prop("supplier_items_length")
																	.update(
																		() =>
																			current.supplier_items_length ||
																			i.length ||
																			i.dimensions?.length
																	)
																store
																	.prop("item")
																	.prop("supplier_items_serial")
																	.update(() =>
																		[i.productCod, i.ean]
																			.filter((s) => s)
																			.join("-")
																	)
																store
																	.prop("item")
																	.prop("supplier_items_specifications")
																	.update(
																		() =>
																			current.supplier_items_specifications ||
																			i.specifications.join("\n")
																	)
																store
																	.prop("item")
																	.prop("supplier_items_features")
																	.update(
																		() =>
																			current.supplier_items_features ||
																			i.features
																	)

																store
																	.prop("item")
																	.prop("supplier_items_cost")
																	.update(() => i.price)
																store
																	.prop("item")
																	.prop("supplier_items_batch")
																	.update(
																		() => current.supplier_items_batch || 0
																	)
																store
																	.prop("item")
																	.prop("supplier_items_batch_cost")
																	.update(
																		() => current.supplier_items_batch_cost || 0
																	)
																store
																	.prop("item")
																	.prop("supplier_items_supply_time")
																	.update(
																		() =>
																			current.supplier_items_supply_time || null
																	)
																store
																	.prop("item")
																	.prop("supplier_items_available")
																	.update(
																		() =>
																			current.supplier_items_available || true
																	)
																store
																	.prop("item")
																	.prop("supplier_items_stock")
																	.update(() => current.supplier_items_stock)

																store
																	.prop("item")
																	.prop("supplier_items_category")
																	.update(
																		() =>
																			current.supplier_items_category ||
																			i.category ||
																			i.default_category
																	)
																store
																	.prop("item")
																	.prop("supplier_items_industry")
																	.update(
																		() =>
																			current.supplier_items_industry ||
																			i.industry
																	)

																return res
															},
															(e: any) => {
																console.log(e.response)
																return e.response.error == "Unauthorized call"
															}
														)
												},
												sl: {
													size: "small",
													variant: "default",
													outline: false,
													value: false,
													class: "greyButton",
													pill: true,
												},
											},
											"Confirm"
											// [
											// 	m(HarthIcon, {
											// 		sl: {
											// 			name: "tick",
											// 			slot: "prefix",
											// 		},
											// 		size: "36px",
											// 	}),
											// 	"Confirm",
											// ]
										)
								  )
								: null
						)
					)
				),
			],
		})
	)

	return centeredSpinner(
		l.get() || !item.get(),
		"centeredHero",
		store.prop("photoOpen").get() && editable
			? photoDraw
			: m(
					".app." + legacyCSS.spreadRows,
					m(
						".app." + legacyCSS.centeredColumns,
						css`
							& {
								position: absolute;
								top: 2%;
							}
						`,
						backbutton ? BackButton("", true, "greyButton", "36px") : null
					),

					() =>
						bacta.Stream.merge([store.prop("newitem").get]).map(([n]) =>
							n
								? m(
										".app." + legacyCSS.fade,
										css`
											& {
												position: fixed;
												bottom: 1.18em;
												width: 100%;
												z-index: 100;
												display: flex;
												justify-content: center;
												justify-items: center;
												flex-direction: column;
												align-items: center;
												font-size: 0.875rem;
											}
										`,
										m("img", {
											src: dologopinyellow,
											style: {
												"object-fit": "scale-down",
												width: "6%",
												height: "6%",
											},
										}),
										m(".app.", "Created")
								  )
								: null
						),

					() =>
						bacta.Stream.merge([store.prop("updateitem").get]).map(([n]) =>
							n ? updateNotification : null
						),

					editable
						? m(
								".app.",
								css`
									& {
										position: absolute;
										top: 2%;
										right: 2%;
									}
								`,
								m(
									HarthButton,
									{
										query: store.prop("photoOpen"),
										sl: {
											size: "small",
											variant: "default",
											outline: false,
											value: true,
											class: "greyButton",
											pill: true,
										},
									},
									m(HarthIcon, {
										sl: {
											name: "images",
											slot: "prefix",
										},
										size: "36px",
									})
								)
						  )
						: null,

					m(
						".app." + legacyCSS.centeredColumns,
						{
							style: {
								"padding-bottom": "1em",
								"padding-top": "3em",
							},
						},
						m("img", {
							src: store.prop("item").prop("supplier_items_image").get(),
							style: {
								"object-fit": "scale-down",
								width: "100%",
								height: "100%",
							},
						})
					),
					m(
						".app.",
						css`
							& {
								background-color: #e9ecef;
							}
						`,
						m(
							".app." + legacyCSS.spreadRowsSmallGap,
							css`
								& {
									margin: 0.5em;
								}
							`,
							editable
								? m(
										".app.",
										css`
											& {
												display: flex;
												grid-template-columns: max-content;
												gap: 0.5em;
												align-items: center;

												width: 100%;
												margin-right: 0.5em;
											}
										`,
										m(HarthIcon, {
											size: "24px",
											sl: {
												name: "tag",
											},
										}),
										m(
											".app.",
											css`
												& {
													width: 100%;
												}
											`,
											m(HarthInput, {
												query: store
													.prop("item")
													.prop("supplier_items_name") as bacta.Store<string>,
												type: "text",
												postop: () =>
													updateStock(
														store
															.prop("item")
															.prop("supplier_items_name")
															.get(),
														item.get().supplier_items_id,
														item.get().supplier_locations_id,
														item,
														store,
														"supplier_items_name"
													),
												sl: {
													size: "medium",
													clearable: false,
													required: true,
													helpText: store
														.prop("item")
														.prop("supplier_items_name")
														.get()
														? ""
														: " Required",
													placeholder: "Name",
												},
											})
										)
								  )
								: m(
										".app." + legacyCSS.leftHeader,
										css`
											& {
												width: 90%;
												margin-left: 0.6em;
											}
										`,
										item.get()?.supplier_items_name
								  ),

							!editable
								? m(
										".app.",
										css`
											& {
												width: 90%;
												margin-left: 0.75em;
											}
										`,
										[
											store.prop("item").prop("supplier_items_category").get(),
											store.prop("item").prop("supplier_items_industry").get(),
										].join(" - ")
								  )
								: null,

							m(
								".app." + legacyCSS.spreadRowsSmallGap,
								m(
									".app.",
									css`
										& {
											display: flex;
											grid-template-columns: max-content;
											gap: 0.5em;
											align-items: ${editable ? "baseline" : "flex-end"};

											width: 100%;
											margin-right: 0.5em;
											padding-top: 0.5em;
											padding-bottom: 0.5em;
										}
									`,

									m(
										".app.",
										css`
											& {
												display: flex;
												align-items: center;
											}
										`,
										m(HarthIcon, {
											size: "24px",
											sl: {
												name: "currency-dollar",
											},
										})
									),

									editable
										? m(
												".app.",
												css`
													& {
														width: 100%;
													}
												`,
												m(HarthInput, {
													query: store
														.prop("item")
														.prop("supplier_locations_items_cost"),
													type: "number",
													postop: () =>
														updateStock(
															store
																.prop("item")
																.prop("supplier_locations_items_cost")
																.get(),
															item.get().supplier_items_id,
															item.get().supplier_locations_id,
															item,
															store,
															"supplier_locations_items_cost"
														),
													sl: {
														type: "number",
														inputmode: "numeric",
														noSpinButtons: false,
														min: 0,
														clearable: false,
														placeholder: "Price",
														helpText: store
															.prop("item")
															.prop("supplier_locations_items_cost")
															.get()
															? ""
															: " Required",
														required: true,
													},
												})
										  )
										: m(
												".app." + legacyCSS.leftHeader,
												item.get()?.supplier_locations_items_cost
										  ),

									!editable
										? m(
												".app." + legacyCSS.leftHeader,
												m(
													".app.",
													css`
														& {
															font-weight: normal;
														}
													`,
													item.get()?.supplier_items_uom?.toLowerCase()
												)
										  )
										: null
								),

								editable
									? m(
											".app.",
											css`
												& {
													width: 100%;
													margin-right: 0.5em;
													align-items: baseline;
													display: flex;
													grid-template-columns: max-content;
													gap: 0.5em;
												}
											`,
											m(HarthIcon, {
												size: "24px",
												sl: {
													name: "collection",
												},
											}),
											m(
												".app.",
												css`
													& {
														width: 100%;
													}
												`,
												m(HarthInput, {
													query: store.prop("item").prop("supplier_items_uom"),
													type: "text",
													postop: () =>
														updateStock(
															store
																.prop("item")
																.prop("supplier_items_uom")
																.get(),
															item.get().supplier_items_id,
															item.get().supplier_locations_id,
															item,
															store,
															"supplier_items_uom"
														),
													sl: {
														type: "text",
														placeholder:
															"Unit of Product (Metres, Packet, Each)",
														class: "",
														helpText: store
															.prop("item")
															.prop("supplier_items_uom")
															.get()
															? ""
															: " Required",
														required: true,
													},
												})
											)
									  )
									: null,

								m(
									".app.",
									css`
										& {
											align-items: center;
											display: flex;
											grid-template-columns: max-content;
											margin-left: 0.025em;
											gap: 0.5em;
										}
									`,
									m(
										".app." + legacyCSS.spreadColumnsCenter,
										editable
											? null
											: m(HarthIcon, {
													sl: {
														src: item.get()?.supplier_locations_items_available
															? availableIcon
															: unavailableIcon,
														slot: "prefix",
														style: {
															color: item.get()
																?.supplier_locations_items_available
																? "#12A670"
																: "red",
														},
													},
													size: "24px",
											  }),

										m(
											".app.",
											css`
												& {
													align-content: center;
												}

												& ::part(base) {
													background-color: black;
													color: white;
													align-items: center;
													border-radius: 0.5em;
													width: 8em;
												}

												& ::part(label) {
													color: white;
												}
											`,
											editable
												? m(
														HarthButton,
														{
															query: store
																.prop("item")
																.prop("supplier_locations_items_available"),
															postop: async () => {
																updateStock(
																	store
																		.prop("item")
																		.prop("supplier_locations_items_available")
																		.get(),
																	item.get().supplier_items_id,
																	item.get().supplier_locations_id,
																	item,
																	store,
																	"supplier_locations_items_available"
																)
															},
															sl: {
																size: "medium",
																variant: "default",
																outline: true,
																value: !store
																	.prop("item")
																	.prop("supplier_locations_items_available")
																	.get(),
																pill: false,
															},
														},
														item.get()?.supplier_locations_items_available
															? "Unavailable"
															: "Available"
												  )
												: item.get()?.supplier_locations_items_available
												? "Available"
												: "Unavailable"
										)
									),

									m(
										".app.",
										css`
											& {
												display: flex;
												grid-template-columns: max-content;
												align-items: center;
												gap: 0.5em;
												width: 100%;
											}
										`,
										m(HarthIcon, {
											sl: {
												src: serialIcon,
												slot: "prefix",
												style: { "font-size": "28px", color: "#6C757D" },
											},
										}),

										m(
											".app.",
											css`
												& {
													width: 100%;
												}
												& ::part(input) {
													width: 100%;
												}
												& ::part(base) {
													width: 100%;
												}
											`,
											editable
												? m(HarthInput, {
														query: store
															.prop("item")
															.prop(
																"supplier_items_serial"
															) as bacta.Store<string>,
														type: "text",
														postop: () =>
															updateStock(
																store
																	.prop("item")
																	.prop("supplier_items_serial")
																	.get(),
																item.get().supplier_items_id,
																item.get().supplier_locations_id,
																item,
																store,
																"supplier_items_serial"
															),
														sl: {
															size: "small",
															clearable: false,
															required: true,
															helpText: store
																.prop("item")
																.prop("supplier_items_serial")
																.get()
																? ""
																: " Required",
															placeholder: "Serial Number",
														},
												  })
												: item.get()?.supplier_items_serial
										)
									)
								),

								m(
									".app.",
									css`
										& {
											background-color: white;
											margin: 0.5em;
											border-radius: 10px;
										}
									`,
									m(
										".app." + legacyCSS.spreadColumns,
										m("img", {
											src: dologopinyellow,
											style: {
												"object-fit": "scale-down",
												width: "15%",
												height: "15%",
											},
										}),
										m(
											".app." + legacyCSS.spreadRowsNoGap,
											m(
												".app." + legacyCSS.leftTitles,
												css`
													& {
														padding-bottom: 0.5em;
														padding-top: 0.5em;
													}
												`,
												item.get()?.supplier_name
											),
											m(
												".app." + legacyCSS.helper,
												item.get()?.supplier_locations_address
											)
										)
									)
								)
							)
						)
					),

					m(
						".app." + legacyCSS.spreadRowsNoGap,
						css`
							& ::part(base) {
								border-left: none;
								border-right: none;
							}
						`,
						m(
							HarthDetails,
							{
								sl: {
									class: "leftHeader",
									summary: "Description",
									open: true,
								},
							},

							editable
								? m(
										".app.",
										css`
											& ::part(textarea) {
												padding: 0;
											}
										`,
										m(HarthTextArea, {
											query: store
												.prop("item")
												.prop(
													"supplier_items_description"
												) as bacta.Store<string>,
											type: "text",
											postop: () =>
												updateStock(
													store
														.prop("item")
														.prop("supplier_items_description")
														.get(),
													item.get().supplier_items_id,
													item.get().supplier_locations_id,
													item,
													store,
													"supplier_items_description"
												),
											sl: {
												size: "small",
												type: "text",
												class: "borderNone",
												required: true,
												helpText: store
													.prop("item")
													.prop("supplier_items_description")
													.get()
													? ""
													: " Required",
												placeholder: "Description",
											},
										})
								  )
								: m(
										".app.",
										css`
											& {
												text-align: left;
												font-weight: 350;
												font-size: 0.75em;
											}
										`,
										item.get()?.supplier_items_description
								  )
						),

						m(
							".app.",
							css`
								& ::part(content) {
									padding-top: 0;
								}
							`,
							m(
								HarthDetails,
								{
									sl: {
										class: "leftHeader",
										summary: "Features",
										open: true,
									},
								},

								editable
									? m(
											".app.",
											css`
												& ::part(base) {
													padding: 0;
												}
											`,

											m(
												".app.",
												css`
													& {
														padding-top: 0.5em;
														padding-bottom: 1em;
													}
												`,

												m(HarthIconButton, {
													sl: {
														onclick: async () =>
															store
																.prop("item")
																.prop("supplier_items_features")
																.update(() =>
																	(
																		store
																			.prop("item")
																			.prop("supplier_items_features")
																			.get() || []
																	).concat("")
																),
														name: "plus-circle",
														slot: "prefix",
														style: {
															"font-size": "24px",
														},
													},
												})
											),

											(
												store
													.prop("item")
													.prop("supplier_items_features")
													.get() || []
											).map((f: string, findex: number) =>
												m(
													".app.",
													css`
														& {
															display: flex;
															justify-content: space-between;
															align-items: center;
														}
													`,
													m(
														".app.",
														css`
															& {
																width: 100%;
															}
														`,
														m(
															".app." + legacyCSS.lefTtext,
															m(HarthInput, {
																type: "text",
																value: f,
																postop: (v: Array<string>) => {
																	store
																		.prop("item")
																		.prop("supplier_items_features")
																		.get()[findex] = v

																	updateStock(
																		store
																			.prop("item")
																			.prop("supplier_items_features")
																			.get(),
																		item.get().supplier_items_id,
																		item.get().supplier_locations_id,
																		item,
																		store,
																		"supplier_items_features"
																	)
																},
																sl: {
																	size: "small",
																	type: "text",
																	class: "borderBottom",
																},
															})
														)
													),
													m(HarthIconButton, {
														sl: {
															onclick: async () => {
																store
																	.prop("item")
																	.prop("supplier_items_features")
																	.update(() =>
																		store
																			.prop("item")
																			.prop("supplier_items_features")
																			.get()
																			.filter(
																				(x: string, index: number) =>
																					!(x == f && index == findex)
																			)
																	)

																updateStock(
																	store
																		.prop("item")
																		.prop("supplier_items_features")
																		.get(),
																	item.get().supplier_items_id,
																	item.get().supplier_locations_id,
																	item,
																	store,
																	"supplier_items_features"
																)
															},
															name: "x",
															slot: "prefix",
															style: {
																"font-size": "24px",
															},
														},
													})
												)
											)
									  )
									: m(
											".app.",
											(item.get()?.supplier_items_features || []).map(
												(f: string) =>
													m(
														".app." + legacyCSS.dotpoint,
														css`
															& {
																text-align: left;
																font-weight: 350;
																font-size: 0.75em;
																padding-bottom: 0.5em;
															}
														`,
														f
													)
											)
									  )
							)
						),

						m(
							HarthDetails,
							{
								sl: {
									class: "leftHeader",
									summary: "Specifications",
									open: true,
								},
							},

							m(
								".app.",
								css`
									& {
										text-align: left;
										font-weight: 350;
										font-size: 0.75em;
										padding-bottom: 1em;
										display: flex;
										flex-direction: column;
										gap: 0.5em;
									}
								`,
								editable
									? m(
											".app.",
											css`
												& ::part(textarea) {
													padding: 0;
												}
											`,
											m(HarthTextArea, {
												query: store
													.prop("item")
													.prop(
														"supplier_items_specifications"
													) as bacta.Store<string>,
												type: "text",
												postop: () =>
													updateStock(
														store
															.prop("item")
															.prop("supplier_items_specifications")
															.get(),
														item.get().supplier_items_id,
														item.get().supplier_locations_id,
														item,
														store,
														"supplier_items_specifications"
													),
												sl: {
													size: "small",
													type: "text",
													class: "borderNone",
													placeholder: "Any other specifications",
												},
											})
									  )
									: (item.get()?.supplier_items_specifications || "")
											.split("\n")
											.filter((a) => a)
											.map((s) => m(".app." + legacyCSS.dotpoint, s))
							),

							m(
								".app." + (!editable ? legacyCSS.spreadRows : ""),
								!editable
									? css`
											& {
												padding-top: 0.5em;
											}
									  `
									: null,
								(
									[
										"supplier_items_weight",
										"supplier_items_height",
										"supplier_items_wdith",
										"supplier_items_length",
									] as (keyof itemType)[]
								).map((d) =>
									m(
										".app." + legacyCSS.spreadColumnsStartBaseline,
										m(
											".app.",
											css`
												& {
													width: 18%;
													font-weight: 350;
													font-size: 0.75em;
												}
											`,
											pretty(d.replace("supplier_items_", ""))
										),
										m(
											".app.",
											css`
												& {
													width: ${editable ? "40%" : "15%"};
													font-weight: 350;
													font-size: 0.75em;
												}
												& ::part(input) {
													text-align: center;
												}
											`,
											editable
												? m(HarthInput, {
														query: store
															.prop("item")
															.prop(d) as bacta.Store<number>,
														type: "number",
														postop: () =>
															updateStock(
																store.prop("item").prop(d).get(),
																item.get().supplier_items_id,
																item.get().supplier_locations_id,
																item,
																store,
																d
															),
														sl: {
															type: "number",
															inputmode: "numeric",
															noSpinButtons: false,
															min: 0,
															clearable: false,
															placeholder: "",
															class: "borderBottom",
															required: true,
															helpText: store.prop("item").prop(d).get()
																? ""
																: " Required",
														},
												  })
												: store.prop("item").prop(d).get()
										),
										m(
											".app.",
											css`
												& {
													width: 32%";
													text-align: left;
													font-weight: 350;
													font-size: 0.75em;
													margin-right: 1.25em;													
												}
											`,
											item.get()
												? d == "supplier_items_weight"
													? " Kilos "
													: " Millimetres "
												: ""
										)
									)
								)
							)
						),

						m(
							".",
							css`
								& {
									display: flex;
									flex-direction: column;
									align-items: center;
									gap: 1em;
									margin-left: 1em;
									margin-right: 1em;
									padding-bottom: 1em;
									padding-top: 1em;
								}
							`,
							editable
								? m(HarthSelect, {
										query: store.prop("item").prop("supplier_items_category"),
										postop: () =>
											updateStock(
												store
													.prop("item")
													.prop("supplier_items_category")
													.get(),
												item.get().supplier_items_id,
												item.get().supplier_locations_id,
												item,
												store,
												"supplier_items_category"
											),
										options: store
											.prop("categories")
											.get()
											.map((a) => a.category_name),
										sl: {
											multiple: false,
											placement: "bottom",
											size: "medium",
											hoist: true,
											helpText: store
												.prop("item")
												.prop("supplier_items_category")
												.get()
												? ""
												: "Category - Required",
											clearable: false,
											class: "borderBottom",
											required: true,
										},
								  })
								: null,

							editable
								? m(HarthSelect, {
										query: store.prop("item").prop("supplier_items_industry"),
										postop: () =>
											updateStock(
												store
													.prop("item")
													.prop("supplier_items_industry")
													.get(),
												item.get().supplier_items_id,
												item.get().supplier_locations_id,
												item,
												store,
												"supplier_items_industry"
											),
										options: store.prop("industries").get(),
										sl: {
											multiple: false,
											placement: "bottom",
											size: "medium",
											hoist: true,
											helpText: "Industry",
											clearable: false,
											class: "borderBottom",
										},
								  })
								: null
						)
					),

					editable ? StockView(v) : orderBanner ? OrderView(item) : null,

					editable
						? m(
								".app.",
								css`
									& {
										padding: 1em;
										display: flex;
										align-items: center;
										justify-content: flex-end;
										justify-items: center;
									}

									& ::part(base) {
										background-color: ${item.get()?.deleted_at
											? "#e9ecef"
											: "black"};
										color: ${item.get()?.deleted_at ? "black" : "white"};
										align-items: center;
										border-radius: 0.5em;
									}

									& ::part(label) {
										color: ${item.get()?.deleted_at ? "black" : "white"};
									}
								`,
								m(
									HarthButton,
									{
										query: store
											.prop("item")
											.prop("deleted_at") as bacta.Store<string>,
										postop: async () => {
											updateStock(
												store.prop("item").prop("deleted_at").get()
													? null
													: new Date(),
												item.get().supplier_items_id,
												item.get().supplier_locations_id,
												item,
												store,
												"deleted_at"
											)
										},
										sl: {
											size: "large",
											variant: "default",
											outline: true,
											pill: true,
											value: store.prop("item").prop("deleted_at").get()
												? undefined
												: new Date(),
										},
									},
									[
										item.get()?.deleted_at ? "Undo" : "Delete",
										m(HarthIcon, {
											sl: {
												name: item.get()?.deleted_at
													? "arrow-counterclockwise"
													: "trash3",
												slot: "prefix",
											},
											size: "24px",
										}),
									]
								)
						  )
						: null
			  )
	)
}

function SearchBar() {
	const { store } = useStore()
	return m(
		".app.",
		m(".app." + legacyCSS.spreadRows, [
			m(".app." + legacyCSS.stretchColumns, [
				m(
					HarthInput,
					{
						query: store.prop("search"),
						type: "text",
						sl: {
							clearable: true,
							size: "medium",
							pill: true,
							placeholder: "Search for Items",
							class: "boxShadows",
						},
					},
					[
						m(HarthIcon, { sl: { name: "search", slot: "prefix" } }),
						filterButton({
							notext: true,
							slot: "suffix",
							query: store.prop("drawOpen"),
							value: true,
							buttonclass: "greybackground",
						}),
					]
				),
			]),
		])
	)
}

function ItemSearchView(v: bacta.Vnode<{}>) {
	const { store, l, organization } = useStore()
	const item = store.prop("item")
	const items = store.prop("items")
	const itemid = store.prop("itemid")
	const locationid = store.prop("locationid")
	const headericon = store.prop("headerIcon")
	const category = store.prop("category")
	const supplier = store.prop("supplier_name")

	return m(
		".app." + legacyCSS.spreadRows,
		m(
			".app." + legacyCSS.centeredColumnsNoGap,
			BackButton(category.get()?.toUpperCase() || supplier.get()?.toUpperCase())
		),
		centeredSpinner(
			l.get(),
			"centeredHero",
			m(".app." + legacyCSS.flexBoxes, () =>
				store.prop("items").get.map(() =>
					items.get().map((i) => {
						const opacity =
							i.storestatus == "Closed" ||
							!i.supplier_locations_items_available ||
							i.supplier_locations_items_stock == 0
						return m(
							"app." + legacyCSS.maxContent,
							css`
								& {
									opacity: ${opacity ? "50%" : "100%"};
								}
							`,
							m(
								HarthCard,
								{
									sl: {
										class: "boxShadows",
									},
									onclick: async () => {
										writeToLoad(true)

										itemid.update(() => i.supplier_items_id)
										locationid.update(() => i.supplier_locations_id)
										headericon.update(() => i.supplier_items_image)
										const dbitem = await sql`
												select
													*
													,0 as quantity
												from app.supplier_locations_items
												inner join app.supplier_items using (supplier_items_id)
												inner join app.supplier_locations using(supplier_locations_id)
												inner join app.suppliers on 
														app.supplier_locations.supplier_id = app.suppliers.supplier_id
												where 
													supplier_items_id = ${itemid.get()}
													and supplier_locations_id = ${locationid.get()} 
											`

										item.update(() => dbitem[0])

										writeToLoad(false)
									},
								},

								m(
									".app." + legacyCSS.spreadRowsSmallGap,
									css`
										& {
											height: 350px;
										}
									`,
									m(
										".app.",
										{
											style: {
												width: "100%",
												height: "10em",
												display: "block",
												"margin-left": "auto",
												"margin-right": "auto",
												"align-content": "center",
											},
										},
										m("img", {
											src: i.supplier_items_image,
											style: {
												"object-fit": "scale-down",
												width: "100%",
												height: "100%",
											},
										})
									),

									!organization.get().supplier_id
										? m(
												".app.",
												css`
													& {
														display: flex;
														gap: 0.5em;
														flex-direction: column;
														align-items: center;
													}
												`,
												m(
													".app." + legacyCSS.cutcenteredTitles,
													i.supplier_items_name
												),
												m(".app." + legacyCSS.helper, i.supplier_name),
												m(
													".app." + legacyCSS.helper,
													i.supplier_locations_address
														.split(",")[1]
														.split(" ")
														.map((i, ii, iarray) =>
															ii < iarray.length - 2 ? i : ""
														)
														.join(" ")
												),
												m(
													".app.",
													css`
														& {
															display: flex;
															grid-template-columns: max-content;
															gap: 0.5em;
															align-items: center;
															justify-content: center;
														}
													`,
													m(HarthIcon, {
														sl: {
															src:
																i.supplier_locations_items_available &&
																i.supplier_locations_items_stock > 0 &&
																i.storestatus == "Open"
																	? availableIcon
																	: unavailableIcon,
															slot: "prefix",
															style: {
																"font-size": "28px",
																color:
																	i.supplier_locations_items_available &&
																	i.supplier_locations_items_stock > 0
																		? "#12A670"
																		: "red",
															},
														},
													}),
													m(
														".app.",
														i.supplier_locations_items_available &&
															i.supplier_locations_items_stock > 0 &&
															i.storestatus == "Open"
															? "Available"
															: "Unavailable"
													),
													m(
														"i.",
														css`
															& {
																font-style: normal;
																font-size: 0.75em;
																font-weight: 500;
															}
														`,
														[
															Number(i.calculate_distance).toFixed(1),
															"Km",
														].join(" ")
													)
												)
										  )
										: m(
												".app." + legacyCSS.centeredtext,
												m(
													".app.",
													css`
														& {
															display: flex;
															gap: 0.5em;
															flex-direction: column;
															align-items: center;
														}
													`,
													m(
														".app." + legacyCSS.cutcenteredTitles,
														i.supplier_items_name
													),
													m(
														".app." + legacyCSS.helper,
														i.supplier_locations_address
															.split(",")[1]
															.split(" ")
															.map((i, ii, iarray) =>
																ii < iarray.length - 2 ? i : ""
															)
															.join(" ")
													),
													m(
														".app.",
														[
															"Price ",
															money(i.supplier_locations_items_cost, 0),
														].join(" ")
													)
												)
										  )
								),

								m(
									".app." + legacyCSS.yellowbackground,
									{ slot: "footer" },
									m(".app." + legacyCSS.spreadRowsNoGap, [
										m(
											".app." + legacyCSS.centeredHeader,
											organization.get().supplier_id
												? [
														i.supplier_locations_items_stock,
														"in",
														"Stock",
												  ].join(" ")
												: [money(i.supplier_locations_items_cost, 0)].join(" ")
										),
										// m(".app." + legacyCSS.orderFixed, StockView(v, i)),
									])
								)
							)
						)
					})
				)
			)
		)
	)
}

function CategorySearchView(v: bacta.Vnode<{}>) {
	const { store, l, organization, categorySearch } = useStore()
	const itemCategories = store.prop("itemCategories")
	return m(
		".app." + legacyCSS.spreadRowsSpaceBetween,
		centeredSpinner(
			l.get(),
			"",
			m(".app." + legacyCSS.spreadRowsSmallGap, [
				itemCategories.get().length &&
				organization.get() &&
				!organization.get().supplier_id &&
				store.prop("recent").get().length
					? m(".app." + legacyCSS.spreadRowsNoGap, [
							m(
								".app." + legacyCSS.leftTitles,
								css`
									& {
										padding-bottom: 0.5em;
										padding-top: 0.5em;
									}
								`,
								"Recently Visited"
							),
							m(
								".app." + legacyCSS.spreadColumns,
								store
									.prop("recent")
									.get()
									.slice(0, 4)
									.map((ic: itemType) =>
										m(
											".app." + legacyCSS.boxShadows,
											m(".app." + legacyCSS.spreadRowsNoGap, [
												m(HarthIconButton, {
													sl: {
														onclick: async () => await getItems(ic),
														src: ic,
														slot: "prefix",
														style: {
															"font-size": "72px",
															filter: "brightness(0)",
														},
													},
												}),
											])
										)
									)
							),
					  ])
					: null,

				!itemCategories.get().length ||
				(organization.get() && organization.get().supplier_id) ||
				!store
					.prop("suppliers")
					.get()
					.filter((s) => s.organization_details_logo_url).length
					? null
					: m(
							".app." + legacyCSS.spreadRows,
							css`
								& {
									padding-top: 1em;
								}
							`,
							[
								m(".app." + legacyCSS.leftSubHeader, "Search Stores"),
								m(
									".app." + legacyCSS.flexBoxesNowrap,
									store
										.prop("suppliers")
										.get()
										.filter((s) => s.organization_details_logo_url)
										.map((ic) =>
											m(
												"app.",
												css`
													& {
														max-width: 30%;
													}
												`,
												m(
													"app." + legacyCSS.spreadRowsNoGap,
													m(
														HarthCard,
														{
															sl: {
																class: "boxShadows",
															},
															onclick: async () => await getItems(ic),
														},

														m(
															".app.",
															{
																style: {
																	width: "100%",
																	height: "80%",
																	display: "block",
																	"margin-left": "auto",
																	"margin-right": "auto",
																	"min-height": "4em",
																	"min-width": "4em",
																	"align-content": "center",
																},
															},
															m("img", {
																src: ic.organization_details_logo_url,
																style: {
																	"object-fit": "scale-down",
																	width: "100%",
																	height: "100%",
																},
															})
														)
													),
													m(
														".app." + legacyCSS.spreadRowsNoGap,
														{ style: { "font-size": "16px" } },
														[
															m(
																".app." + legacyCSS.cutcenteredTitles,
																ic.organization_details_name
															),
														]
													)
												)
											)
										)
								),
							]
					  ),

				!itemCategories.get().length &&
				organization.get() &&
				!organization.get().supplier_id
					? m(
							".app." + legacyCSS.lefTtext,
							css`
								& {
									padding: 1em;
								}
							`,
							store.prop("category").get() || categorySearch.get()
								? "We don't have this stock"
								: "Store is empty"
					  )
					: m(".app." + legacyCSS.spreadRows, [
							organization.get() && organization.get().supplier_id
								? m(
										".app." + legacyCSS.spreadColumnsCenter,
										css`
											& {
												padding-top: 1em;
											}
										`,
										store.prop("suppliers").get().length == 1 &&
											store.prop("suppliers").get()[0].supplier_locations
												.length == 1
											? m(HarthIconButton, {
													sl: {
														onclick: async () => await newItem(),
														name: "plus-circle",
														slot: "prefix",
														style: {
															"font-size": "24px",
															filter: "brightness(0)",
														},
													},
											  })
											: null,
										m(".app." + legacyCSS.leftSubHeader, "Products")
								  )
								: m(".app." + legacyCSS.leftSubHeader, "Search Products"),
							m(
								".app." + legacyCSS.flexBoxes,
								itemCategories.get().map((ic) =>
									m(
										"app." + legacyCSS.maxContent,
										m(
											HarthCard,
											{
												sl: {
													class: "boxShadows",
													style: {
														width: "100%",
													},
												},
												onclick: async () => await getItems(ic),
											},

											m(
												"app." + legacyCSS.spreadRowsNoGap,

												css`
													& {
														height: 175px;
													}
												`,
												m(
													".app.",
													{
														style: {
															width: "80%",
															height: "100%",
															"min-height": "75px",
															display: "block",
															"margin-left": "auto",
															"margin-right": "auto",
															"padding-top": "1em",
															"padding-bottom": "1em",
														},
													},
													m("img", {
														src: ic.supplier_items_icon,
														style: {
															"object-fit": "scale-down",
															width: "100%",
															height: "100%",
														},
													})
												),
												m(
													".app." + legacyCSS.spreadRowsNoGap,
													{
														style: {
															"font-size": "18px",
															height: "100%",
														},
													},
													[
														// m(
														// 	".app." + legacyCSS.centeredHeader,
														// 	["(", ic.count, ")"].join(" ")
														// ),
														m(
															".app." + legacyCSS.cutcenteredTitles,
															ic.supplier_items_category
														),
													]
												)
											)
										)
									)
								)
							),
					  ]),
			])
		)
	)
}

const updateNotification = m(
	".app." + legacyCSS.fade,
	css`
		& {
			position: fixed;
			bottom: 1.18em;
			width: 100%;
			z-index: 100;
			display: flex;
			justify-content: center;
			justify-items: center;
			flex-direction: column;
			align-items: center;
			font-size: 0.875rem;
		}
	`,
	m("img", {
		src: dologopinyellow,
		style: {
			"object-fit": "scale-down",
			width: "6%",
			height: "6%",
		},
	}),
	m(".app.", "Updated")
)

function SupplierView(v: any) {
	const { store, l, u, organization } = useStore()
	const orderdates = store.prop("orderdates")
	const orderdatee = store.prop("orderdatee")
	const orders = store.prop("orders")
	const map = store.prop("map")
	useAddressMap(v)

	v.useEffect({ name: "get-supplier-orders" }, function* () {
		yield [store.prop("status"), store.prop("datefilter")]
		writeToLoad(true)
		debounceOrders()
		writeToLoad(false)
	})

	store.prop("uppy").set(
		m(HarthUppy, {
			getFields: () => ({
				organization_id: organization.get().organization_id,
				store: "organization_details_logo_url",
				user_id: store.prop("user").get().user_id,
				email: store.prop("user").get().email,
				organization_details_id: store.prop("suppliers").get()[0]
					?.organization_details_id,
			}),
			query: store.prop("files"),
			store,
			v,
		})
	)

	v.useEffect({ name: "photo-counts-home" }, function* () {
		yield [store.prop("files")]

		m.redraw()
	})

	const detailsDraw = () => {
		const orgowner =
			store.prop("user").get().user_id ==
			store.prop("organization").get().user_id

		const s = store.prop("suppliers").unnest().where`
			(i) => i.supplier_id == ${store.prop("supplier").get().supplier_id}
		`
		const l = s.prop("supplier_locations").unnest().where`
		(ii) => ii.supplier_locations_id == ${
			store.prop("supplier").get().supplier_locations_id
		}`
		return m(
			".app.",
			m(HarthDrawer, {
				query: store.prop("detailsOpen"),
				sl: {
					placement: "start",
					contained: true,
					noHeader: true,
				},
				children: [
					m(
						".app." + legacyCSS.spreadRowsBigGap,

						m(
							".app.",
							m(
								HarthButton,
								{
									query: store.prop("detailsOpen"),
									sl: {
										size: "small",
										variant: "default",
										outline: false,
										value: false,
										class: "greyButton",
										pill: true,
									},
								},
								[
									m(HarthIcon, {
										sl: {
											name: "arrow-left-short",
											slot: "prefix",
										},
										size: "36px",
									}),
								]
							)
						),

						centeredSpinner(
							false,
							"centeredHero",
							m(
								".app." + legacyCSS.spreadRowsBigGap,
								m(".app." + legacyCSS.centeredHeader, "Update your Details"),

								// name
								!orgowner
									? null
									: m(
											".app.",
											css`
												& {
													width: 100%;
												}
											`,
											m(HarthInput, {
												query: s.prop("supplier_name") as bacta.Store<string>,
												type: "text",
												postop: () =>
													updateSupplierLocation(
														s.prop("supplier_name").get(),
														s.get().supplier_id,
														null,
														store,
														"supplier_name"
													),
												sl: {
													size: "medium",
													clearable: false,
													required: true,
													helpText: "Name of your store",
													class: "borderBottom",
												},
											})
									  ),

								// description
								!orgowner
									? null
									: m(
											".app.",
											css`
												& {
													width: 100%;
												}
											`,
											m(HarthInput, {
												query: s.prop(
													"supplier_description"
												) as bacta.Store<string>,
												type: "text",
												postop: () =>
													updateSupplierLocation(
														s.prop("supplier_description").get(),
														s.get().supplier_id,
														null,
														store,
														"supplier_description"
													),
												sl: {
													size: "medium",
													clearable: false,
													required: true,
													helpText: "Description of your store",
													class: "borderBottom",
												},
											})
									  ),

								// Instructions
								m(
									".app.",
									css`
										& {
											width: 100%;
										}
									`,
									m(HarthInput, {
										query: l.prop(
											"supplier_locations_instructions"
										) as bacta.Store<string>,
										type: "text",
										postop: () =>
											updateSupplierLocation(
												l.prop("supplier_locations_instructions").get(),
												s.get().supplier_id,
												l.get().supplier_locations_id,
												store,
												"supplier_locations_instructions"
											),
										sl: {
											size: "medium",
											clearable: false,
											required: true,
											class: "borderBottom",
											helpText: "Pickup instructions",
										},
									})
								),

								// Email
								m(
									".app.",
									css`
										& {
											width: 100%;
										}
									`,
									m(HarthInput, {
										query: l.prop(
											"supplier_locations_email"
										) as bacta.Store<string>,
										type: "text",
										postop: () =>
											updateSupplierLocation(
												l.prop("supplier_locations_email").get(),
												s.get().supplier_id,
												l.get().supplier_locations_id,
												store,
												"supplier_locations_email"
											),
										sl: {
											size: "medium",
											clearable: false,
											required: true,
											class: "borderBottom",
											helpText: "Email",
										},
									})
								),

								// Phone
								m(
									".app.",
									css`
										& {
											width: 100%;
										}
									`,
									m(HarthInput, {
										query: l.prop(
											"supplier_locations_phone"
										) as bacta.Store<string>,
										type: "text",
										postop: () =>
											updateSupplierLocation(
												l.prop("supplier_locations_phone").get(),
												s.get().supplier_id,
												l.get().supplier_locations_id,
												store,
												"supplier_locations_phone"
											),
										sl: {
											size: "medium",
											clearable: false,
											required: true,
											class: "borderBottom",
											helpText: "Phone",
										},
									})
								),

								// Address
								addressInputs(v),

								m(
									".app.",
									m(
										".app." + legacyCSS.leftHeader,
										css`
											& {
												padding-bottom: 1em;
											}
										`,
										"Trading Hours"
									),

									// Oening Hours
									[
										"monday",
										"tuesday",
										"wednesday",
										"thursday",
										"friday",
										"saturday",
										"sunday",
										"public_holiday",
									].map((a) =>
										m(
											".app.",
											css`
												& {
													margin-bottom: 1em;
												}
											`,
											m(
												".app." + legacyCSS.spreadColumnsCenter,
												m(
													".app.",
													css`
														& {
															width: 30%;
														}
													`,
													pretty(a)
												),
												m(
													".app.",
													css`
														& {
															width: 35%;
															margin-bottom: 1em;
														}
													`,
													m(HarthInput, {
														query: l.prop(
															"supplier_locations_" + a + "_open"
														) as bacta.Store<string>,
														type: "time",
														postop: () =>
															updateSupplierLocation(
																l
																	.prop("supplier_locations_" + a + "_open")
																	.get(),
																s.get().supplier_id,
																l.get().supplier_locations_id,
																store,
																"supplier_locations_" + a + "_open"
															),
														sl: {
															size: "medium",
															clearable: false,
															required: true,
															class: "borderBottom",
															helpText: "Opening Time",
														},
													})
												),
												m(
													".app.",
													css`
														& {
															width: 35%;
															margin-bottom: 1em;
														}
													`,
													m(HarthInput, {
														query: l.prop(
															"supplier_locations_" + a + "_close"
														) as bacta.Store<string>,
														type: "time",
														postop: () =>
															updateSupplierLocation(
																l
																	.prop("supplier_locations_" + a + "_close")
																	.get(),
																s.get().supplier_id,
																l.get().supplier_locations_id,
																store,
																"supplier_locations_" + a + "_close"
															),
														sl: {
															size: "medium",
															clearable: false,
															required: true,
															class: "borderBottom",
															helpText: "Closing Time",
														},
													})
												)
											)
										)
									)
								)
							)
						)
					),
				],
			})
		)
	}

	const photoDraw = m(
		".app.",
		m(HarthDrawer, {
			query: store.prop("photoOpen"),
			sl: {
				placement: "start",
				contained: true,
				noHeader: true,
			},
			children: [
				m(
					".app." + legacyCSS.spreadRowsBigGap,

					m(
						".app.",
						m(
							HarthButton,
							{
								query: store.prop("photoOpen"),
								sl: {
									size: "small",
									variant: "default",
									outline: false,
									value: false,
									class: "greyButton",
									pill: true,
								},
							},
							[
								m(HarthIcon, {
									sl: {
										name: "arrow-left-short",
										slot: "prefix",
									},
									size: "36px",
								}),
							]
						)
					),
					m(
						".app." + legacyCSS.spreadRowsBigGap,
						m(".app." + legacyCSS.centeredHeader, "Upload Logo"),
						store.prop("uppy").get()
					)
				),
			],
		})
	)

	const addPhotoButton = ({
		tag,
		getFields,
		size = "small",
		cssClass = "greyButton",
		pill = true,
		slot = "prefix",
	}: {
		tag?: string
		getFields: Function
		size: "small" | "medium" | "large"
		cssClass?: string
		pill?: boolean
		slot?: string
	}) =>
		m(
			HarthButton,
			{
				query: store.prop("photoOpen"),
				postop: () => {
					store.prop("uppy").update(() =>
						m(HarthUppy, {
							getFields,
							query: store.prop("files"),
							store,
							v,
						})
					)
				},
				sl: {
					size: size,
					variant: "default",
					outline: false,
					value: true,
					class: cssClass,
					pill: pill,
				},
			},
			m(HarthIcon, {
				sl: {
					name: "images",
					slot,
				},
				size: "24px",
			}),
			tag
				? m(
						".app.",
						css`
							& {
								padding: 0;
							}
						`,
						tag
				  )
				: null
		)

	return {
		view: () =>
			store.prop("state").get() == "Home"
				? m(
						".app." + legacyCSS.content,
						centeredSpinner(
							l.get() || u.get(),
							"centeredHero",

							store.prop("detailsOpen").get()
								? detailsDraw()
								: store.prop("photoOpen").get()
								? photoDraw
								: m(
										".app." + legacyCSS.spreadRowsSmallGap,

										store
											.prop("suppliers")
											.get()
											.map((s: any) => {
												const supplier = store.prop("suppliers").unnest().where`
													(i) => i.supplier_id == ${s.supplier_id}
												`

												return m(
													".app.",

													orders.get().length
														? m(HarthMap, {
																id: "orders-map",
																map,
																style: { "z-index": 0 },
														  })
														: null,

													s.supplier_locations.map((sl: any) => {
														const location = supplier
															.prop("supplier_locations")
															.unnest().where`
														(ii) => ii.supplier_locations_id == ${sl.supplier_locations_id}`

														v.useEffect(
															{ name: "update-supplier" },
															function* () {
																yield [store.prop("FormalAddress")]

																store.prop("detailsOpen").get() &&
																store.prop("deliveryTo").get() !=
																	store.prop("supplier").get()
																		.supplier_locations_address
																	? updateSupplierLocation(
																			store.prop("deliveryTo").get(),
																			store.prop("supplier").get().supplier_id,
																			store.prop("supplier").get()
																				?.supplier_locations_id || null,
																			store,
																			"supplier_locations_address"
																	  )
																	: null
															}
														)

														return m(
															".app.",
															css`
																& ::part(header) {
																	padding-left: 0;
																	padding-right: 0;
																	padding-top: 1em;
																}
																& ::part(content) {
																	padding-left: 0;
																	padding-right: 0;
																	padding-top: 0;
																	padding-bottom: 1em;
																}
															`,

															m(
																HarthDetails,
																{
																	sl: {
																		class: "borderBottom",
																		open: true,
																	},
																},

																m(
																	".app." + legacyCSS.spreadColumns,
																	{ slot: "summary" },

																	s.organization_details_logo_url
																		? m("img", {
																				src: s.organization_details_logo_url,
																				style: {
																					"object-fit": "scale-down",
																					width: "50%",
																					height: "50%",
																				},
																		  })
																		: addPhotoButton({
																				tag: "Upload your logo",
																				getFields: () => ({
																					organization_id:
																						organization.get().organization_id,
																					store:
																						"organization_details_logo_url",
																					user_id: store.prop("user").get()
																						.user_id,
																					email: store.prop("user").get().email,
																					organization_details_id:
																						s.organization_details_id,
																				}),
																				size: "medium",
																				cssClass: "borderNone",
																				pill: false,
																				slot: "",
																		  }),
																	m(
																		".app." + legacyCSS.leftHeader,

																		s.supplier_name,
																		m(
																			".app." + legacyCSS.helper,
																			sl.supplier_locations_address
																		),

																		m(
																			".app.",
																			css`
																				& {
																					display: flex;
																					margin-top: 0.5em;
																					align-items: center;
																				}
																			`,
																			!s.organization_details_logo_url
																				? null
																				: addPhotoButton({
																						getFields: () => ({
																							organization_id:
																								organization.get()
																									.organization_id,
																							store:
																								"organization_details_logo_url",
																							user_id: store.prop("user").get()
																								.user_id,
																							email: store.prop("user").get()
																								.email,
																							organization_details_id:
																								s.organization_details_id,
																						}),
																						size: "small",
																				  }),

																			m(
																				HarthButton,
																				{
																					query: store.prop("detailsOpen"),
																					postop: async () => {
																						store
																							.prop("supplier")
																							.update(() => location.get())

																						store
																							.prop("deliveryTo")
																							.update(
																								() =>
																									location.get()
																										.supplier_locations_address
																							)

																						await getAddresses()

																						store
																							.prop("FormalAddress")
																							.update(
																								() =>
																									(store
																										.prop("nearAddressOptions")
																										.get()[0] as any) || {}
																							)
																					},
																					sl: {
																						size: "small",
																						variant: "default",
																						outline: false,
																						value: true,
																						class: "greyButton",
																						pill: true,
																					},
																				},
																				m(HarthIcon, {
																					sl: {
																						name: "pencil",
																						slot: "prefix",
																					},
																					size: "24px",
																				})
																			)
																		)
																	)
																),

																m(
																	".app." + legacyCSS.spreadRowsBigGap,

																	m(
																		".app.",
																		css`
																			& {
																				align-content: center;
																			}

																			& ::part(base) {
																				background-color: black;
																				color: white;
																				align-items: center;
																				border-radius: 0.5em;
																			}

																			& ::part(label) {
																				color: white;
																			}
																		`,
																		m(
																			".app." + legacyCSS.spreadColumns,
																			m(
																				HarthButton,
																				{
																					postop: async () => {
																						writeToLoad(true)

																						await sql`
																						update app.supplier_locations
																							set supplier_locations_open = true
																						where supplier_locations_id = ${sl.supplier_locations_id}
																					`
																							.then(
																								() =>
																									(sl.supplier_locations_open =
																										true)
																							)
																							.catch(() =>
																								console.log(
																									"open supplier error"
																								)
																							)

																						await getItems()

																						writeToLoad(false)
																					},
																					sl: {
																						size: "large",
																						variant: "default",
																						outline: true,
																						value: true,
																						pill: false,
																						disabled:
																							sl.supplier_locations_open,
																					},
																				},
																				"Open Store"
																			),
																			m(
																				HarthButton,
																				{
																					postop: async () => {
																						writeToLoad(true)

																						await sql`
																						update app.supplier_locations
																							set supplier_locations_open = false
																						where supplier_locations_id = ${sl.supplier_locations_id}
																					`
																							.then(
																								() =>
																									(sl.supplier_locations_open =
																										false)
																							)
																							.catch(() =>
																								console.log(
																									"close supplier error"
																								)
																							)

																						await getItems()

																						writeToLoad(false)
																					},
																					sl: {
																						size: "large",
																						variant: "default",
																						outline: true,
																						value: false,
																						pill: false,
																						disabled:
																							!sl.supplier_locations_open,
																					},
																				},
																				"Close Store"
																			)
																		)
																	),

																	m(
																		HarthDetails,
																		{
																			sl: {
																				open: false,
																				class: "borderNone",
																			},
																		},

																		m(
																			".app.",
																			{ slot: "summary" },
																			m(
																				".app.",
																				css`
																					& {
																						align-items: center;
																						justify-content: flex-start;
																						display: flex;
																						grid-template-columns: max-content;
																					}
																				`,

																				m(
																					".app.",
																					m(
																						HarthIcon,
																						{
																							sl: {
																								name: "clipboard2",
																							},
																							size: "32px",
																						},
																						css`
																							& {
																								padding-right: 0.5em;
																							}
																						`
																					)
																				),

																				m(
																					".app." + legacyCSS.spreadRowsNoGap,
																					m(
																						".app." + legacyCSS.leftSubHeader,
																						location
																							.prop(
																								"supplier_locations_instructions"
																							)
																							.get()
																					),
																					m(
																						".app." + legacyCSS.helper,
																						"Add pickup instructions"
																					)
																				)
																			)
																		),

																		m(
																			".app.",
																			css`
																				& {
																					width: 100%;
																				}
																			`,
																			m(HarthInput, {
																				query: location.prop(
																					"supplier_locations_instructions"
																				) as bacta.Store<string>,
																				type: "text",
																				postop: () =>
																					updateSupplierLocation(
																						location
																							.prop(
																								"supplier_locations_instructions"
																							)
																							.get(),
																						supplier.get().supplier_id,
																						location.get()
																							.supplier_locations_id,
																						store,
																						"supplier_locations_instructions"
																					),
																				sl: {
																					size: "medium",
																					clearable: false,
																					required: true,
																					class: "borderBottom",
																				},
																			})
																		)
																	),

																	m(
																		".app.",

																		!sl.supplier_locations_open
																			? m(
																					".app." + legacyCSS.centeredHeader,
																					"Store Closed"
																			  )
																			: null,

																		!orders.get().length &&
																			!store.prop("status").get() &&
																			!orderdatee.get() &&
																			!orderdates.get() &&
																			sl.supplier_locations_open
																			? m(
																					".app." + legacyCSS.leftSubHeader,
																					blinkingDots(
																						"Waiting for orders",
																						true
																					)
																			  )
																			: null,

																		!sl.supplier_locations_open ||
																			(!orders.get().length &&
																				!store.prop("status").get() &&
																				!orderdatee.get() &&
																				!orderdates.get() &&
																				sl.supplier_locations_open)
																			? null
																			: m(
																					".app." +
																						legacyCSS.spreadColumnsSpaceBetween,
																					m(
																						".app.",
																						css`
																							& ::part(base) {
																								padding-inline-start: 0;
																							}
																						`,
																						filterButton({
																							notext: true,
																							slot: "suffix",
																							query: store.prop("orderFilters"),
																							value: !store
																								.prop("orderFilters")
																								.get(),
																							buttonclass: "borderNone",
																						})
																					),
																					!store.prop("orderFilters").get()
																						? null
																						: m(
																								HarthButton,
																								{
																									query:
																										store.prop("datefilter"),
																									postop: async () => {
																										new Date(
																											new Date().getTime() -
																												1000 * 60 * 60 * 24 * 7
																										).toISOString()
																										orderdatee.update(() =>
																											new Date().toISOString()
																										)
																									},
																									sl: {
																										size: "small",
																										variant: "default",
																										outline: true,
																										value: true,
																										class: "",
																										disabled: store
																											.prop("datefilter")
																											.get(),
																									},
																								},
																								css`
																									& {
																										min-width: 20%;
																									}
																								`,
																								"Previous"
																						  ),
																					!store.prop("orderFilters").get()
																						? null
																						: m(
																								HarthButton,
																								{
																									query: store.prop("status"),
																									sl: {
																										size: "small",
																										variant: "default",
																										outline: true,
																										value: "accepted",
																										class: "",
																										disabled:
																											store
																												.prop("status")
																												.get() == "accepted",
																									},
																								},
																								css`
																									& {
																										min-width: 20%;
																									}
																								`,
																								"Accepted"
																						  ),
																					!store.prop("orderFilters").get()
																						? null
																						: m(
																								HarthButton,
																								{
																									query: store.prop("status"),
																									sl: {
																										size: "small",
																										variant: "default",
																										outline: true,
																										value: "ready for pickup",
																										class: "",
																										disabled:
																											store
																												.prop("status")
																												.get() ==
																											"ready for pickup",
																									},
																								},
																								css`
																									& {
																										min-width: 20%;
																									}
																								`,
																								"Ready"
																						  ),
																					!store.prop("orderFilters").get()
																						? null
																						: m(
																								HarthButton,
																								{
																									query: store.prop("status"),
																									sl: {
																										size: "small",
																										variant: "default",
																										outline: true,
																										value: "order on its way",
																										class: "",
																										disabled:
																											store
																												.prop("status")
																												.get() ==
																											"order on its way",
																									},
																								},
																								css`
																									& {
																										min-width: 20%;
																									}
																								`,
																								"Collected"
																						  ),
																					!store.prop("orderFilters").get()
																						? null
																						: store.prop("status").get() ||
																						  store.prop("datefilter").get()
																						? m(
																								HarthButton,
																								{
																									query: store.prop("status"),
																									postop: () => {
																										orderdates.update(
																											() => null
																										)
																										orderdatee.update(
																											() => null
																										)
																										store
																											.prop("datefilter")
																											.update(
																												() =>
																													null as unknown as boolean
																											)
																									},
																									sl: {
																										size: "small",
																										variant: "default",
																										outline: true,
																										value: "",
																										class: "",
																										disabled: false,
																									},
																								},
																								m(HarthIcon, {
																									sl: {
																										name: "x-lg",
																										slot: "prefix",
																										style: {
																											"font-size": "18px",
																										},
																									},
																								})
																						  )
																						: null
																			  ),

																		!sl.supplier_locations_open
																			? null
																			: m(
																					".app." + legacyCSS.spreadRowsBigGap,
																					orders
																						.get()
																						.filter(
																							(od) =>
																								od.supplier_locations_id ==
																								sl.supplier_locations_id
																						)
																						.map((i) =>
																							m(
																								HarthDetails,
																								{
																									sl: {
																										class: "borderTop",
																										open: true,
																									},
																								},
																								m(
																									"app." + legacyCSS.leftHeader,
																									{
																										slot: "summary",
																										style: {
																											"min-width": "100%",
																										},
																									},
																									m(
																										"app." +
																											legacyCSS.spreadRows,
																										m(
																											"app." +
																												legacyCSS.spreadColumns,

																											i.delivery_id,
																											m(
																												"app." +
																													legacyCSS.helper,
																												[
																													"\u00D7",
																													i.items.length,
																													"items",
																													"-",
																													prettystatus(
																														i,
																														"supplier"
																													),
																												].join(" ")
																											)
																										),
																										m(
																											"app." + legacyCSS.helper,
																											i.address
																										)
																									)
																								),
																								m(
																									".app.",
																									css`
																										& {
																											padding-top: 1em;
																										}
																									`,

																									m(
																										"app." +
																											legacyCSS.spreadRowsBigGap,
																										m(
																											".app." +
																												legacyCSS.spreadRowsNoGap,
																											i.items.map((d) =>
																												m(
																													".app." +
																														legacyCSS.spreadColumnsSpaceBetweenStart,

																													m(
																														".app." +
																															legacyCSS.leftHeader,
																														css`
																															& {
																																text-decoration: ${d.order_status ==
																																"unavailable"
																																	? "line-through"
																																	: ""};
																															}
																														`,
																														pretty(
																															[
																																d.order_amount,
																																"\u2022",
																																d.order_name,
																															].join(" ")
																														),
																														m(
																															".app." +
																																legacyCSS.helper,
																															m(
																																".app." +
																																	legacyCSS.spreadColumnsCenter,
																																m(HarthIcon, {
																																	sl: {
																																		src: serialIcon,
																																		slot: "prefix",
																																		style: {
																																			"font-size":
																																				"28px",
																																			color:
																																				"#6C757D",
																																		},
																																	},
																																}),
																																m(
																																	".app.",
																																	d.supplier_items_serial
																																)
																															)
																														)
																													),
																													m(
																														HarthButton,
																														{
																															postop:
																																async () => {
																																	writeToLoad(
																																		true
																																	)

																																	await sql`
																														update app.supplier_locations_items
																														set supplier_locations_items_stock = 0
																														where app.supplier_locations_items.supplier_items_id = ${d.supplier_items_id}
																														and app.supplier_locations_items.supplier_locations_id = ${d.supplier_locations_id}
																													`

																																	await sql` 
																														update app.supplier_locations_items
																														set supplier_locations_items_available = false
																														where app.supplier_locations_items.supplier_items_id = ${d.supplier_items_id}
																														and app.supplier_locations_items.supplier_locations_id = ${d.supplier_locations_id}
																													`

																																	await sql`
																														update app.orders
																														set order_status = 'unavailable'
																														where app.orders.delivery_id = ${i.delivery_id}
																														and app.orders.order_id =  ${d.order_id}
																														returning 
																															order_id as id
																													`

																																	await getOrders()

																																	writeToLoad(
																																		false
																																	)
																																},
																															sl: {
																																size: "small",
																																variant:
																																	"default",
																																outline: true,
																																value: "",
																																class: "",
																																disabled:
																																	d.order_status ==
																																		"unavailable" ||
																																	(i.order_ready_at
																																		? true
																																		: false),
																															},
																														},
																														"Out of Stock"
																													)
																												)
																											)
																										),
																										m(
																											".app.",
																											css`
																												& {
																													align-content: center;
																													padding-top: 2em;
																												}
																											`,

																											m(
																												".app." +
																													legacyCSS.spreadColumnsSpaceBetween,
																												// ACCEPTED BUTTON
																												m(
																													".app." +
																														(i.status ==
																														"waiting acceptance"
																															? legacyCSS.loader__dot
																															: ""),
																													css`
																														& {
																															max-width: 24%;
																														}
																													`,
																													m(
																														HarthButton,
																														{
																															postop:
																																async () => {
																																	writeToLoad(
																																		true
																																	)

																																	await sql`
																																	update app.orders set
																																		order_status = 'accepted'
																																		,order_accepted_at = now()
																																	where app.orders.delivery_id = ${i.delivery_id}
																																	and app.orders.order_status != 'unavailable'
																																`

																																	// BOOK SHERPA
																																	await sherpa({
																																		endpoint:
																																			"order",
																																		bookingid:
																																			i.delivery_id,
																																	})

																																	await getOrders()

																																	writeToLoad(
																																		false
																																	)
																																},
																															sl: {
																																size: "medium",
																																variant:
																																	"default",
																																outline: true,
																																value: "",
																																class:
																																	i.status ==
																																	"finding a driver"
																																		? // ? "regblackbackground"
																																		  ""
																																		: "",

																																disabled:
																																	i.status !=
																																	"waiting acceptance",
																															},
																														},
																														css`
																												& {
																													max-width: 100%;
																												}
																												& ::part(label) {
																													padding-inline-start: 0:
																												}
																											`,
																														[
																															m(
																																".app.",
																																"ACCEPT"
																															),
																															m(HarthIcon, {
																																sl: {
																																	name: "check-lg",
																																	slot: "prefix",
																																	style: {
																																		"font-size":
																																			"18px",
																																	},
																																},
																															}),
																														]
																													)
																												),

																												// READY BUTTON
																												m(
																													".app." +
																														(i.status ==
																															"finding a driver" &&
																														i.order_ready_at ==
																															null
																															? legacyCSS.loader__dot
																															: ""),

																													css`
																														& {
																															max-width: 24%;
																														}
																													`,
																													m(
																														HarthButton,
																														{
																															postop:
																																async () => {
																																	writeToLoad(
																																		true
																																	)
																																	await sql`
																														update app.orders
																														set order_ready_at = now()
																														where app.orders.delivery_id = ${i.delivery_id}
																														returning *
																													`

																																	// const [
																																	// 	{
																																	// 		order_fee,
																																	// 		ids: [id],
																																	// 		dfee: [
																																	// 			delivery_fee,
																																	// 		],
																																	// 	},
																																	// ] = await sql`
																																	// 	select
																																	// 		array_agg( O.payment_id ) as ids
																																	// 		,array_agg( O.sherpa_order -> 'amount' ) as dfee
																																	// 		,sum(
																																	// 			( O.order_cost * O.order_amount )
																																	// 		) as order_fee
																																	// 	from app.orders O
																																	// 	where delivery_id = ${i.delivery_id}
																																	// 	and order_status != 'unavailable'
																																	// `

																																	// const total =
																																	// 	(order_fee +
																																	// 		order_fee * 0.2 +
																																	// 		Number(
																																	// 			delivery_fee
																																	// 		)) *
																																	// 	100

																																	// CHARGE CUSTOMER
																																	// await stripeCharge({
																																	// 	delivery_id:
																																	// 		i.delivery_id,
																																	// 	id,
																																	// 	total:
																																	// 		Number(
																																	// 			total
																																	// 		).toFixed(0),
																																	// 	cancel: false,
																																	// })

																																	await getOrders()
																																	writeToLoad(
																																		false
																																	)
																																},
																															sl: {
																																size: "medium",
																																variant:
																																	"default",
																																outline: true,
																																value: "",
																																class:
																																	i.order_ready_at !=
																																	null
																																		? // ? "regblackbackground"
																																		  ""
																																		: "",
																																disabled:
																																	i.order_ready_at !=
																																		null ||
																																	i.status ==
																																		"unavailable",
																															},
																														},
																														css`
																												& {
																													max-width: 100%;
																												}
																												& ::part(label) {
																													padding-inline-start: 0:
																												}																												
																											`,
																														[
																															m(
																																".app.",
																																"READY"
																															),
																															m(HarthIcon, {
																																sl: {
																																	name: "box-seam",
																																	slot: "prefix",
																																	style: {
																																		"font-size":
																																			"18px",
																																	},
																																},
																															}),
																														]
																													)
																												),

																												// PICKED UP BUTTON
																												// m(
																												// 	".app." +
																												// 		(i.status ==
																												// 		"ready for pickup"
																												// 			? legacyCSS.loader__dot
																												// 			: ""),

																												// 	css`
																												// 		& {
																												// 			max-width: 24%;
																												// 		}
																												// 	`,
																												// 	m(
																												// 		HarthButton,
																												// 		{
																												// 			postop: async () => {
																												// 				writeToLoad(true)

																												// 				await sql`
																												// 					update app.orders
																												// 					set order_status = 'collected'
																												// 					where app.orders.delivery_id = ${i.delivery_id}
																												// 					returning *
																												// 				`

																												// 				await getOrders()

																												// 				writeToLoad(false)
																												// 			},
																												// 			sl: {
																												// 				size: "medium",
																												// 				variant: "default",
																												// 				outline: true,
																												// 				value: "",
																												// 				class:
																												// 					i.status ==
																												// 					"collected"
																												// 						? ""
																												// 						: "",
																												// 				disabled:
																												// 					i.status ==
																												// 					"collected",
																												// 			},
																												// 		},
																												// 		css`
																												// 			& {
																												// 				max-width: 100%;
																												// 			}
																												// & ::part(label) {
																												// 	padding-inline-start: 0:
																												// }
																												// 		`,
																												// 		[
																												// 			m(".app.", "SENT"),
																												// 			m(HarthIcon, {
																												// 				sl: {
																												// 					name: "truck-flatbed",
																												// 					slot: "prefix",
																												// 					style: {
																												// 						"font-size":
																												// 							"18px",
																												// 					},
																												// 				},
																												// 			}),
																												// 		]
																												// 	)
																												// ),
																												// DENY BUTTON
																												m(
																													".app.",

																													css`
																											& {
																												max-width: 24%;
																											}
																											& ::part(label) {
																												padding-inline-start: 0:
																											}																											
																										`,
																													m(
																														HarthButton,
																														{
																															postop:
																																async () => {
																																	writeToLoad(
																																		true
																																	)

																																	await sql`
																														update app.orders
																														set order_status = 'unavailable'
																														where app.orders.delivery_id = ${i.delivery_id}
																														returning 
																															order_id as id
																													`

																																	// CANCEL HOLD
																																	const [
																																		{
																																			ids: [id],
																																		},
																																	] = await sql`
																														select
																															array_agg( O.payment_id ) as ids
																														from app.orders O
																														where delivery_id = ${i.delivery_id}
																													`

																																	await stripeCharge(
																																		{
																																			delivery_id:
																																				i.delivery_id,
																																			cancel:
																																				true,
																																			id,
																																		}
																																	)

																																	// CANCEL SHERPA
																																	await sherpa({
																																		endpoint:
																																			"cancel",
																																		bookingid:
																																			i.delivery_id,
																																	})

																																	await getOrders()

																																	writeToLoad(
																																		false
																																	)
																																},
																															sl: {
																																size: "medium",
																																variant:
																																	"default",
																																outline: true,
																																value: "",
																																class:
																																	i.status ==
																																	"unavailable"
																																		? // ? "regblackbackground"
																																		  ""
																																		: "",
																																disabled:
																																	i.status !=
																																	"waiting acceptance",
																															},
																														},
																														css`
																															& {
																																max-width: 100%;
																															}
																														`,
																														[
																															m(
																																".app.",
																																"REJECT"
																															),
																															m(HarthIcon, {
																																sl: {
																																	name: "x-lg",
																																	slot: "prefix",
																																	style: {
																																		"font-size":
																																			"18px",
																																	},
																																},
																															}),
																														]
																													)
																												)
																											)
																										)
																									)
																								)
																							)
																						)
																			  )
																	)
																)
															)
														)
													})
												)
											})
								  )
						)
				  )
				: null,
	}
}

function SearchView(v: any) {
	const { store, organization, l, u, s } = useStore()
	const category = store.prop("category")
	const item = store.prop("item")
	let searching =
		store.prop("search").get() ||
		store.prop("category").get() ||
		store.prop("supplier_name").get() ||
		store.prop("supplier_items_name").get() ||
		store.prop("supplier_items_description").get() ||
		store.prop("supplier_items_weight_min").get() ||
		store.prop("supplier_items_height_min").get() ||
		store.prop("supplier_items_wdith_min").get() ||
		store.prop("supplier_items_length_min").get() ||
		store.prop("supplier_items_weight_max").get() ||
		store.prop("supplier_items_height_max").get() ||
		store.prop("supplier_items_wdith_max").get() ||
		store.prop("supplier_items_length_max").get() ||
		store.prop("supplier_items_category").get() ||
		store.prop("supplier_items_industry").get().length ||
		store.prop("supplier_items_specifications").get() ||
		store.prop("supplier_items_features").get() ||
		store.prop("supplier_locations_items_cost_min").get() ||
		store.prop("supplier_locations_items_cost_max").get() ||
		store.prop("supplier_locations_address").get()
			? true
			: false

	v.useEffect({ name: "searching" }, function* () {
		yield [store.prop("items"), store.prop("category")]
		searching =
			store.prop("search").get() ||
			store.prop("category").get() ||
			store.prop("supplier_name").get() ||
			store.prop("supplier_items_name").get() ||
			store.prop("supplier_items_description").get() ||
			store.prop("supplier_items_weight_min").get() ||
			store.prop("supplier_items_height_min").get() ||
			store.prop("supplier_items_wdith_min").get() ||
			store.prop("supplier_items_length_min").get() ||
			store.prop("supplier_items_weight_max").get() ||
			store.prop("supplier_items_height_max").get() ||
			store.prop("supplier_items_wdith_max").get() ||
			store.prop("supplier_items_length_max").get() ||
			store.prop("supplier_items_category").get() ||
			store.prop("supplier_items_industry").get().length ||
			store.prop("supplier_items_specifications").get() ||
			store.prop("supplier_items_features").get() ||
			store.prop("supplier_locations_items_cost_min").get() ||
			store.prop("supplier_locations_items_cost_max").get() ||
			store.prop("supplier_locations_address").get()
				? true
				: false
	})

	return {
		view: () => {
			return m(
				".app." + legacyCSS.spreadRowsSmallGap,
				store.prop("state").get() != "Search"
					? null
					: m(
							".app." + legacyCSS.spreadRowsSmallGap,
							item.get().supplier_items_id ||
								(organization.get() && organization.get().supplier_id)
								? null
								: m(".app." + legacyCSS.content, newNearAddress(v, false)),

							item.get().supplier_items_id
								? null
								: m(".app." + legacyCSS.content, SearchBar()),

							store.prop("state").get() != "Search"
								? null
								: l.get() || (u.get() && !item.get().supplier_items_id)
								? centeredSpinner(true, "centeredHero")
								: !item.get().supplier_items_id && searching
								? m(".app." + legacyCSS.content, ItemSearchView(v))
								: !item.get().supplier_items_id && !category.get()
								? m(".app." + legacyCSS.content, CategorySearchView(v))
								: item.get().supplier_items_id
								? ItemView({
										backbutton: true,
										i: null,
										orderBanner: true,
										v,
								  })
								: null
					  )
			)
		},
	}
}

function centeredSpinner(check: any, spinnercss: string, div?: any) {
	return check
		? m(
				".app." + legacyCSS[spinnercss],
				css`
					& ::part(base) {
						--indicator-color: #ffc735;
						--speed: 4s;
					}
				`,
				m(HarthSpinner, { size: "6rem" })
		  )
		: div
}

function website(v: bacta.Vnode<{}, {}>) {
	return window.location.href == "https://dropoff.au/privacy_policy" ||
		window.location.href == "http://localhost:8080/privacy_policy"
		? pp
		: window.location.host == "dropoff.au"
		? m("img", {
				src:
					screen.orientation.type.indexOf("portrait") >= 0
						? websiteHomePortrait
						: websiteHome,
				style: {
					"object-fit": "scale-down",
					width: "100%",
					height: "100%",
				},
		  })
		: null
}

function Landing(v: bacta.Vnode<{}, {}>) {
	const { store, u } = useStore()
	const user = store.prop("user").get()

	return m(
		".app." + legacyCSS.landingCenter,

		m(".app." + legacyCSS.spreadRowsSpaceBetween, [
			m("img", {
				src: applanding,
				style: {
					// "object-fit": "scale-down",
					width: "100%",
					height: "100%",
				},
			}),

			m(
				".app.",
				css`
					& {
						display: flex;
						position: fixed;
						top: 20%;
						align-items: center;
						flex-direction: column;
					}
				`,
				m("img", {
					src: dologoyellow,
					style: {
						"object-fit": "scale-down",
						width: "60%",
						height: "60%",
					},
				}),
				m(
					".app." + legacyCSS.centeredSmallHero,
					css`
						& {
							color: white;
							font-size: 1.7em;
						}
					`,
					"Fast Tools. Faster Jobs"
				)
			),
		]),

		m(
			"",
			css`
				& {
					border-radius: 10px 10px 0px 0px;
					position: fixed;
					background-color: white;
					top: 55%;
					width: 100%;
				}
			`,
			window.location.href == "http://localhost:8080/contactus" ||
				window.location.href == "https://app.dropoff.au/contactus"
				? m(
						".app." + legacyCSS.spreadRowsSpaceBetween,
						css`
							& {
								padding-top: 0.5em;
							}
						`,
						m(
							".app." + legacyCSS.centeredSmallHero,
							css`
								& {
									color: #ffc735;
								}
							`,

							"Contact Us"
						),
						m(
							".app." + legacyCSS.centeredTitles,
							"1800 377 633 ( 1800 drp off ) "
						),
						m(".app." + legacyCSS.centeredTitles, "support@dropoff.au"),
						m(
							".app." + legacyCSS.centeredTitles,
							css`
								& {
									margin-left: 2em;
									margin-right: 2em;
									margin-bottom: 2em;
								}
							`,
							"We're hear to help you with construction needs. As a supplier or a customer we'll help get what you need fast"
						)
				  )
				: m(
						".app." + legacyCSS.spreadRowsSpaceBetween,
						css`
							& {
								padding-top: 0.5em;
							}
						`,
						m(".app." + legacyCSS.centeredSmallHero, "Login to get started"),
						m(
							HarthButton,
							{
								sl: {
									size: "large",
									variant: "default",
									outline: true,
									value: "",
									href: "api/auth/authorize",
									class: "blackbackground",
								},
							},

							"Next"
						),
						m(
							".app." + legacyCSS.centeredTitles,
							"Don't have an account ?",
							m(
								HarthButton,
								{
									sl: {
										size: "large",
										variant: "default",
										outline: true,
										value: "",
										href: "api/auth/authorize",
										class: "borderBottom",
									},
								},
								"Sign up"
							)
						)
				  )
		)
	)
}

const App: bacta.BactaComponent = (v: bacta.Vnode) => {
	const { store, s, l } = useStore(v)
	const item = store.prop("item")

	useSearchItems(v)
	// useMap(v)
	useAddressMap(v)
	useMapMarkers(v)
	usePricingAutoTab(v)
	useOrdersAutoTab(v)
	useAddress(v)
	setAddress(v)
	showloading(v)
	useScroll(v)

	v.useEffect({ name: "initialize" }, function* () {
		l.update(() => true)
		yield initialize(store)
		l.update(() => false)
	})

	// store
	return () =>
		m(
			".app.",
			s.get() == "Checkout"
				? m("img", {
						src: dologopinyellow,
						style: {
							"object-fit": "scale-down",
							width: "40%",
							"max-width": "100%",
							height: "40%",
							position: "fixed",
							transform: "rotate(-30deg)",
							top: "-13%",
							right: "-11%",
						},
				  })
				: null,

			window.location.host == "dropoff.au" ||
				// 	window.location.href == "http://localhost:8080/contactus" ||
				window.location.href == "http://localhost:8080/privacy_policy"
				? website(v)
				: !s.get() ||
				  window.location.href == "http://localhost:8080/contactus" ||
				  window.location.href == "https://app.dropoff.au/contactus"
				? Landing(v)
				: m(
						".app." + legacyCSS.app,
						{ style: { position: "relative" } },
						s.get() == "Checkout" || item.get().supplier_items_id
							? null
							: AppHeader(),

						m(AppTray, { v }),
						() =>
							bacta.Stream.merge([store.prop("updateitem").get]).map(([n]) =>
								n ? updateNotification : null
							)
				  )
		)
}

m.mount(document.body, App)
