import m, * as bacta from "bacta"
import {
	Tab,
	TabAttrs,
	TabPanel,
	TabPanelAttrs,
	TabGroup,
	TabGroupAttrs,
} from "../sl/tab.js"

export type Attrs = {
	tabs: Array<{
		view: bacta.Children
		icon: bacta.Children
		name: string
		active: boolean
		disabled: boolean
	}>
	query: bacta.Store<string>
	color?: string
	width?: string
}

export type BaseAttrs = {
	sl?: Partial<TabAttrs & TabPanelAttrs & TabGroupAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthTabGroup: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl, ...attrs } }) => {
		return m(
			TabGroup,
			{
				...sl,
				["onsl-tab-show"](e: any) {
					const value: string = (e as any as CustomEvent)?.detail?.name
					attrs.query.update(() => value)
				},
			},
			attrs.tabs
				.map((t) => [
					m(
						Tab,
						{
							slot: "nav",
							panel: t.name,
							active: t.active,
							disabled: t.disabled,
							width: attrs.width,
						},
						t.icon
					),
					m(TabPanel, { name: t.name, active: true }, t.view),
				])
				.flat()
		)
	},
})
